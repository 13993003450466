import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import analytics from '../../analytics';

// Amplify
import { API } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';

// Components
import AccountForm from './AccountForm';
import AddressForm from './AddressForm';
import Copyright from '../Copyright';
import LoaderButton from '../../UI/Buttons/LoaderButton';
import Loader from '../../UI/Loader/Loader';
import PaymentForm from './PaymentForm';
import { TitleComponent } from '../Title';

// Form validation
import { useFormContext } from 'react-hook-form';

// Hooks
import { useUser } from '../../libs/contextLib';
import MuiAlert from '@material-ui/lab/Alert';

// Material ui
import {
  Box,
  Button,
  CssBaseline,
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Hidden,
  Snackbar,
} from '@material-ui/core/';

// Material ui icons
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// Material ui styles
import { makeStyles } from '@material-ui/core/styles';

// Translation
import { useTranslation } from 'react-i18next';

// UUID
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  cardField: {
    marginBottom: '15px',
    backgroundColor: 'white',
    padding: '11px 16px',
    borderRadius: '6px',
    border: '1px solid #CCC',
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    lineHeight: '1.3333333',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: '10px 10px 40px -30px rgba(0,0,0,0.64)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#E8CB47',
  },
  loginLink: {
    margin: theme.spacing(4, 0, 2),
  },
  back: {
    margin: theme.spacing(3, 0, 2),
  },
  forms: {
    backgroundColor: '#F9FAFB',
    padding: theme.spacing(2),
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  leftGrid: {
    backgroundColor: theme.palette.background.light,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  loader: {
    paddingBottom: '40px',
  },
  stepper: {
    backgroundColor: theme.palette.background.light,
  },
  leftArrow: {
    transform: 'rotate(180deg)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#E8CB47',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#E8CB47',
    zIndex: 1,
    fontSize: 18,
  },
});

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();

  // Active step
  const [activeStep, setActiveStep] = React.useState(0);

  // Billing address
  const [billingAddressStatus, setBillingAddressStatus] = React.useState(true);
  const [paperInvoiceStatus, setPaperInvoiceStatus] = React.useState(false);

  // Form
  const [compiledForm, setCompiledForm] = useState({});

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  //TODO: Remove this or keep it for later?
  // Payment type
  const [paymentType, setPaymentType] = useState('invoice');

  // Plan
  const [plan, setPlan] = useState(1);

  // Signup
  const { signUp } = useUser();

  // Stepper
  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        <div className={classes.circle} />
      </div>
    );
  }

  // Steps
  const steps = getSteps();

  function getSteps() {
    return [1, 2, 3];
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [loginError, setLoginError] = useState('');

  // Translation
  const { t } = useTranslation();

  // Validation
  const { control, watch, handleSubmit, errors } = useFormContext();
  const form = watch();

  const methods = useFormContext();
  const { reset } = methods;

  useEffect(() => {
    reset(
      {
        ...compiledForm.account,
        ...compiledForm.address,
        ...compiledForm.payment,
      },
      { errors: true }
    );
  }, [compiledForm]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AccountForm control={control} />;
      case 1:
        return <AddressForm compiledForm={compiledForm} />;
      case 2:
        return (
          <PaymentForm
            paperInvoiceStatus={paperInvoiceStatus}
            setPaperInvoiceStatus={() =>
              setPaperInvoiceStatus(!paperInvoiceStatus)
            }
            billingAddressStatus={billingAddressStatus}
            setBillingAddressStatus={() =>
              setBillingAddressStatus(!billingAddressStatus)
            }
            paymentType={paymentType}
            plan={plan}
            setPaymentType={setPaymentType}
            setPlan={setPlan}
            compiledForm={compiledForm}
          ></PaymentForm>
        );
      case 3:
        return (
          <div className={classes.loader}>
            <Loader />
          </div>
        );
      default:
        return 'Unknown step';
    }
  }

  function getStepButton(step) {
    switch (step) {
      case 0:
        return t('signup.continue.buttonText');
      case 1:
        return t('signup.continue.buttonText');
      case 2:
        return t('signup.submit.buttonText');
      default:
        return t('signup.continue.buttonText');
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);

      switch (activeStep) {
        case 0:
          setCompiledForm({ ...compiledForm, account: form });
          break;
        case 1:
          setCompiledForm({ ...compiledForm, address: form });
          break;
        case 2:
          setCompiledForm({ ...compiledForm, payment: form });
          break;
        default:
          return 'not a valid step';
      }
    }
  };

  // Sign up a user
  const handleSignup = async () => {
    // Create account id
    let accountId = uuidv4();

    // Get session id
    try {
      let sessionId;

      // Sign up user
      try {
        let userMetaData = {
          address: compiledForm.address.address,
          city: compiledForm.address.city,
          companyName: compiledForm.address.companyName,
          zipcode: compiledForm.address.zipcode,
          country: compiledForm.address.country.label,
          state:
            'state' in compiledForm.address
              ? compiledForm.address.state.label
              : '',
          plan: String(plan),
          paymentType: String(paymentType),
          billingEmail:
            watch().alternateInvoiceEmail || compiledForm.account.email,
          paperBilling: String(paperInvoiceStatus),
        };

        // Add billing data if payment type is invoice
        if (paymentType === 'invoice' && !billingAddressStatus) {
          userMetaData['billingAddressStatus'] = String(billingAddressStatus);
          userMetaData['billingAddress'] = watch().billingAddress;
          userMetaData['billingCity'] = watch().billingCity;
          userMetaData['billingZipcode'] = watch().billingZipcode;
        }

        analytics.track('signUp', {
          ...userMetaData,
        });

        await signUp(
          compiledForm.account.email,
          compiledForm.account.password,
          accountId,
          userMetaData
        );
      } catch (err) {
        if (err.code === 'TooManyRequestsException') {
          setLoginError(t('signup.error.tooManyRequests'));
        } else if (err.code === 'UsernameExistsException') {
          setLoginError(t('signup.error.usernameExists'));
        } else if (err.code === 'PasswordPolicyException') {
          setLoginError(t('signup.error.pattern'));
        } else {
          setLoginError(err.message);
        }
        /* Set step to payment step */
        setActiveStep(2);
        setOpen(true);
        setIsLoading(false);
        return;
      }

      //TODO: tabort där de är if card/invoice?
      if (paymentType === 'invoice') {
        history.push('/');
      }
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const errorHandling = (data) => {
    console.log('Error handling: ', data);
  };

  const handleNext = (data, onClick) => {
    if (!onClick) {
      return;
    }

    // Check if error exists
    // if (isErrorExists(watch()) || !watch().cookiePolicy || !watch().privacyPolicy) {
    if (isErrorExists(watch())) {
      return;
    }

    // Check if any step keys are false
    if (isKeyFalse(watch())) {
      return;
    }

    let canContinue = true;

    switch (activeStep) {
      case 0:
        setCompiledForm({ ...compiledForm, account: form });
        canContinue = true;
        break;
      case 1:
        setCompiledForm({ ...compiledForm, address: form });
        canContinue = true;
        break;
      case 2:
        setCompiledForm({ ...compiledForm, payment: form });
        canContinue = true;
        handleSignup();
        break;
      case 3:
        // getToken()
        break;
      default:
        return 'not a valid step';
    }
    if (canContinue) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function isErrorExists(stepKeys) {
    for (var errorKey in errors) {
      if (errorKey in stepKeys) {
        return true;
      }
    }

    return false;
  }

  function isKeyFalse(stepKeys) {
    for (var watchKey in stepKeys) {
      if (
        (watchKey === 'privacyPolicy' || watchKey === 'privacyPolicy') &&
        stepKeys[watchKey] === false
      ) {
        return true;
      }
    }

    return false;
  }

  async function getSessionId(entry) {
    return API.post('user', '/create-billing-session', {
      body: entry,
    });
  }

  return (
    <Fragment>
      <TitleComponent title={t('signup.title')} />
      <Container maxWidth='lg'>
        <CssBaseline />
        <Paper elevation={0} className={classes.paper}>
          <form onSubmit={handleSubmit(errorHandling)}>
            <Grid container>{getStepContent(activeStep)}</Grid>
            <Grid container>
              <Hidden only={['xs']}>
                <Grid item xs={6}></Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                md={6}
                className={activeStep !== 3 ? classes.leftGrid : null}
              >
                <Grid container>
                  <Grid item xs={6}>
                    {activeStep > 0 && activeStep !== 3 && (
                      <Button
                        className={classes.back}
                        disabled={activeStep === 0}
                        onClick={() => handleBack()}
                        startIcon={
                          <ArrowRightAltIcon className={classes.leftArrow} />
                        }
                      >
                        {t('signup.back.buttonText')}
                      </Button>
                    )}
                    {activeStep === 0 && (
                      <Grid container className={classes.loginLink}>
                        <Grid item xs>
                          <Link to='/login' variant='body2'>
                            {t('login.loginLink')}
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {activeStep !== 3 && (
                      <LoaderButton
                        id='next'
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        isLoading={isLoading}
                        endIcon={<ArrowRightAltIcon />}
                        onClick={() => {
                          handleNext({}, true);
                        }}
                      >
                        {getStepButton(activeStep)}
                      </LoaderButton>
                    )}
                  </Grid>
                </Grid>
                <Grid container justify='center'>
                  <Grid item xs={12} md={4}>
                    {activeStep !== 3 && (
                      <Stepper
                        className={classes.stepper}
                        alternativeLabel
                        activeStep={activeStep}
                        connector={null}
                      >
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel
                              StepIconComponent={QontoStepIcon}
                            ></StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='error'>
            {loginError}
          </Alert>
        </Snackbar>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </Fragment>
  );
}
