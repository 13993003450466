import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// Hooks
import { useUser } from '../../libs/contextLib';

// Components
import CustomTextField from '../../UI/TextField/CustomTextField';
import Loader from '../../UI/Loader/Loader';
import ItemView from '../../UI/Views/ItemView';
import LoaderButton from '../../UI/Buttons/LoaderButton';
import analytics from '../../analytics'
import { TitleComponent } from '../Title';

import {
    Button,
    Divider,
    Grid,
    Snackbar,
    Typography,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@material-ui/core';

// Material ui icons
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';

// Material ui lab
import MuiAlert from '@material-ui/lab/Alert';

import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

// Mutations
import { EDIT_USER, EDIT_ACCOUNT } from '../../graphql/mutations'

// Phone input
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

// Queries
import { GET_PORTAL_BILLING_SESSION, GET_USER_SETTINGS } from '../../graphql/queries'

// Translate
import { useTranslation } from 'react-i18next';

// Validation
import { useForm, Controller } from "react-hook-form";
import { Fragment } from 'react';


const useStyles = makeStyles((theme) => ({
    addButton: {
        marginBottom: '20px'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    divider: {
        margin: '20px 0px 20px 0px',
        width: '100%',
    },
    fixedHeight: {
        height: 240,
    },
    iconMargin: {
        margin: theme.spacing(1),
    },
    subHeadline: {
        fontSize: '1.0rem',
        fontWeight: '400',
        color: 'grey'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    phoneContainer: {
        height: '59px',
    },
    phoneInput: {
        height: '59px',
        width: '100%',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    submit: {
        marginTop: '15px'
    },
    textfield: {
        marginBottom: '15px'
    },
    title: {
        flexGrow: 1,
    },
    typography: {
        display: 'block',
        marginBottom: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

// Snackbar
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Settings(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { user } = useUser();

    // Error
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState()
    // Loading
    const [isLoadingChange, setIsLoadingChange] = useState(false);

    // Validation
    const { register, handleSubmit, errors, control } = useForm();

    // Push
    const history = useHistory();

    // Snackbar
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onSubmit = async (data) => {
        setIsLoadingChange(true)

        // Update user data
        try {
            const userEntry = {
                "userId": user['username'],
                "firstName": data.firstName,
                "lastName": data.lastName,
            }

            if (data.phone) {
                userEntry['phone'] = data.phone
            }

            analytics.track('editSettings', {
                "userId": user['username'],
                "isAdmin": userForm.userGroup
            }
            )

            // Update user data in table
            await editUser({
                variables: userEntry
            });

            // Update account data in table if made by admin
            if (userForm.userGroup === 'admin') {
                await editAccount({
                    variables: {
                        "userId": user['username'],
                        "address": data.address,
                        "city": data.city,
                        "name": data.name,
                        "zipcode": data.zipcode,
                    }
                });
            }

            setIsLoadingChange(false)
            setSeverity("success")
            setMessage(t("settings.success"))
            setOpen(true);


        } catch (err) {
            setIsLoadingChange(false);
            setSeverity("error")
            setMessage(err.message);
            setOpen(true);
        }

    };


    const { data: userData, loading } = useQuery(GET_USER_SETTINGS, {
        variables: { "userId": user.username },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            analytics.identify(user.username, {
                email: userData.user.email,
                firstName: userData.user.firstName,
                lastName: userData.user.lastName,
                city: userData.user.account.city,
                zipcode: userData.user.account.zipcode,
                subscription_type: userData.user.account.subscriptionType,
                payment_type: userData.user.account.paymentType,
                paper_billing: userData.user.account.paperBilling,
                account_created_date: userData.user.account.createdDate,
                user_created_date: userData.user.createdDate
            })
        }
    });


    const [editUser] = useMutation(EDIT_USER, {});

    const [editAccount] = useMutation(EDIT_ACCOUNT);
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng || ""

    const [language, setLanguage] = useState(getLanguage());

    // Update the form
    const [userForm, setUserForm] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        city: "",
        name: "",
        zipcode: "",
        userGroup: ""
    });

    const formChange = (event) => {
        setUserForm({
            ...userForm,
            [event.target.id]: event.target.value
        });
    }

    useEffect(() => {
        if (userData && userData.user !== null && userData.account !== null) {
            // Set the user form
            setUserForm({
                email: userData.user.email ? userData.user.email : "",
                firstName: userData.user.firstName ? userData.user.firstName : "",
                lastName: userData.user.lastName ? userData.user.lastName : "",
                phone: userData.user.phone ? userData.user.phone : "",
                userGroup: userData.user.userGroup ? userData.user.userGroup : "",
                address: userData.user.account.address,
                city: userData.user.account.city,
                language: userData.user.language,
                name: userData.user.account.name,
                zipcode: userData.user.account.zipcode,
            })

            setLanguage(userData.user.language ? userData.user.language : getLanguage())
        }

    }, [userData])

    const hasError = inputName => !!(errors && errors[inputName])

    // Handle the billing portal
    const handlePayment = async () => {
        getPortalSession({ variables: { "userId": user.username } });
    }

    const [getPortalSession] = useLazyQuery(GET_PORTAL_BILLING_SESSION, {
        onCompleted: (url) => (window.location.href = url.user.billingPortalSession)
    });

    const updateLanguage = async (lang) => {
        i18n.changeLanguage(lang)
        setLanguage(lang)
        try {
            await editUser({
                variables: {
                    "userId": user['username'],
                    "language": lang
                }
            });
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Fragment>
            <TitleComponent title={t("settings.title")} />
            {loading ? <Loader /> :

                <ItemView width={'md'} loading={false} buttonText={t("settings.backButton.text")}>

                    <Grid item sm={12}>
                        <Typography className={classes.subHeadline}>{t("settings.user.label")}</Typography>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <CustomTextField
                                disabled
                                variant="outlined"
                                fullWidth
                                id="email"
                                label={t('settings.email.label')}
                                name="email"
                                autoFocus
                                value={userForm.email}
                                onChange={formChange}
                                type="text"

                            />
                            <Grid container spacing={2}>

                                <Grid item md={6}>
                                    <CustomTextField
                                        inputRef={
                                            register({
                                                required: t("settings.firstName.error.required"),
                                            })
                                        }
                                        error={hasError("firstName")}
                                        helperText={hasError("firstName") && errors["firstName"].message}
                                        variant="outlined"
                                        fullWidth
                                        id="firstName"
                                        label={t('settings.firstName.label')}
                                        name="firstName"
                                        autoFocus
                                        value={userForm.firstName}
                                        onChange={formChange}
                                        type="text"

                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <CustomTextField
                                        inputRef={
                                            register({
                                                required: t("settings.lastName.error.required")
                                            })
                                        }
                                        error={hasError("lastName")}
                                        helperText={hasError("lastName") && errors["lastName"].message}
                                        variant="outlined"
                                        fullWidth
                                        id="lastName"
                                        label={t('settings.lastName.label')}
                                        name="lastName"
                                        value={userForm.lastName}
                                        onChange={formChange}
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <Typography variant="overline">
                                    {t('settings.phone.label')}
                                </Typography>
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{ required: false }}
                                    defaultValue={userForm.phone}
                                    render={({ onChange, value, name }) => (
                                        <PhoneInput
                                            autocompleteSearch={true}
                                            country="se"
                                            value={userForm.phone}
                                            inputProps={{ name, }}
                                            onChange={(tel, data) => {
                                                onChange(tel)
                                            }}
                                            specialLabel={t('settings.phone.label')}
                                            containerClass={classes.phoneContainer}
                                            inputStyle={errors.phone ? { color: 'red', borderColor: 'red', width: '100%' } : { color: 'black', width: '100%' }}
                                        />
                                    )}
                                />
                            </div>

                            <Divider variant="fullWidth" className={classes.divider} />
                            <Typography className={classes.subHeadline}>{t("settings.account.label")}</Typography>
                            <CustomTextField

                                disabled={userForm.userGroup !== 'admin'}
                                inputRef={
                                    register({
                                        required: t("settings.name.error.required")
                                    })
                                }
                                error={hasError("name")}
                                helperText={hasError("name") && errors["name"].message}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label={t('settings.name.label')}
                                name="name"
                                value={userForm.name}
                                // defaultValue={userForm.account.name}
                                onChange={formChange}
                                type="text"
                            />
                            <CustomTextField
                                disabled={userForm.userGroup !== 'admin'}
                                inputRef={
                                    register({
                                        required: t("settings.address.error.required")
                                    })
                                }
                                error={hasError("address")}
                                helperText={hasError("address") && errors["address"].message}
                                variant="outlined"
                                fullWidth
                                id="address"
                                label={t('settings.address.label')}
                                name="address"
                                value={userForm.address}
                                onChange={formChange}
                                type="text"
                            />

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={userForm.userGroup !== 'admin'}
                                        inputRef={
                                            register({
                                                required: t("settings.zipcode.error.required")
                                            })
                                        }
                                        error={hasError("zipcode")}
                                        helperText={hasError("zipcode") && errors["zipcode"].message}
                                        variant="outlined"
                                        fullWidth
                                        id="zipcode"
                                        label={t('settings.zipcode.label')}
                                        name="zipcode"
                                        value={userForm.zipcode}
                                        onChange={formChange}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={userForm.userGroup !== 'admin'}
                                        inputRef={
                                            register({
                                                required: t("settings.city.error.required")
                                            })
                                        }
                                        error={hasError("city")}
                                        helperText={hasError("city") && errors["city"].message}
                                        variant="outlined"
                                        fullWidth
                                        id="city"
                                        label={t('settings.city.label')}
                                        name="city"
                                        value={userForm.city}
                                        onChange={formChange}
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                            <LoaderButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={isLoadingChange}
                                isLoading={isLoadingChange}
                            >
                                {t('settings.submit.buttonText')}
                            </LoaderButton>

                            <Divider variant="fullWidth" className={classes.divider} />
                            <Typography className={classes.subHeadline}>{t("settings.other.label")}</Typography>

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="select-language">
                                    {t("settings.language")}
                                </InputLabel>
                                <Select
                                    autoWidth={true}
                                    labelId="select-language"
                                    id="select-language"
                                    value={language}
                                    onChange={(e, data) => {
                                        updateLanguage(data.props.value)
                                    }
                                    }
                                    label={t("settings.language")}
                                >
                                    <MenuItem value={'sv-SE'}>Svenska</MenuItem>
                                    <MenuItem value={'en-US'}>English</MenuItem>
                                    <MenuItem value={'pl-PL'}>Polskie</MenuItem>
                                    <MenuItem value={'ru-RU'}>Русский</MenuItem>
                                </Select>
                            </FormControl>

                            <Divider variant="fullWidth" className={classes.divider} />

                            <Grid container>
                                <Grid item sm={12}>

                                    <Button
                                        className={classes.submit}
                                        disableElevation
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => history.push('/settings/change-password')}
                                        startIcon={<LockIcon />}
                                    >
                                        {t('settings.changePassword.buttonTextChange')}
                                    </Button>
                                    <Button
                                        className={classes.submit}
                                        disableElevation
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => history.push('/settings/change-email')}
                                        startIcon={<EmailIcon />}
                                    >
                                        {t('settings.changeEmail.buttonTextChange')}
                                    </Button>
                                    <Button
                                        className={classes.submit}
                                        disableElevation
                                        fullWidth
                                        onClick={() => handlePayment()}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CreditCardIcon />}
                                    >
                                        {t('settings.billingPortal.buttonTextChange')}
                                    </Button>

                                </Grid>
                            </Grid>

                        </form>

                    </Grid>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar>

                </ItemView >
            }

        </Fragment >

    );
}