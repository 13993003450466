import React, { Fragment, memo } from 'react';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';

import classes from './FileDrop.module.css'


// Material ui
import {

    Button,

}
    from '@material-ui/core/';

// Material ui icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const FileDropButton = memo(props => {
    const { customValidator, text, onDrop, multiple, accept } = props

    const { getRootProps, getInputProps, fileRejections, open } = useDropzone(
        { onDrop, multiple: multiple, accept: accept, validator: customValidator })

    const { ref, ...rootProps } = getRootProps()

    return (
        <RootRef rootRef={ref}>
            <Fragment>
                <Button

                    {...rootProps} className={classes.Button2} style={{ height: '56px' }}
                    fullWidth startIcon={<CloudUploadIcon />} variant="contained" color="primary" disableElevation
                    onClick={() => {
                        if (props.fileName && props.fileType) {
                            open()
                        } else if (!props.fileName && !props.fileType) {
                            props.errorType()
                            props.errorName()
                        } else if (!props.fileName) {
                            props.errorName()
                        } else if (!props.fileType) {
                            props.errorType()
                        }
                    }}
                >
                    <input {...getInputProps()} />
                    {text}

                </Button>
                {fileRejections.length > 0 && <p>{fileRejections[0]['errors'][0]['message']}</p>}
            </Fragment>
        </RootRef>
    )
})

export default FileDropButton