import { Storage } from "aws-amplify";

export async function s3Upload(file, accountId, setProgress) {
    const fileName = `${Date.now()}-${file.name}`
    const filePath = `${accountId}/${fileName}`;

    await Storage.put(filePath, file, {
        contentType: file.type,
        progressCallback(progress) {

            if (setProgress) {
                let currentProgress = (progress.loaded / progress.total) * 100
                setProgress(currentProgress)
            }


        }
    });

    return fileName;
}