import React from "react";
import { makeStyles } from '@material-ui/core/styles';

// Material ui
import { Button, CircularProgress } from '@material-ui/core';

// margin-right: 10px !important;
// animation: spin 1s infinite linear;
// color: #ffffff;

const useStyles = makeStyles((theme) => ({
    LoaderButton: {
        borderRadius: "10px",
        animation: "spin 1s infinite linear",
        color: "#ffffff",
        height: "55px"
    },
  }));

export default function LoaderButton({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) {
    const classes = useStyles();

    return (
        <Button
            disableElevation
            className={[classes.LoaderButton, className].join(' ')}
            disabled={disabled || isLoading}
            {...props}
        >
            {isLoading && <CircularProgress size={24} className={classes.LoaderButton} />}
            {props.children}
        </Button>
    );
}