import React, {
  useContext,
  useEffect,
  useMemo,
  createContext,
  useState,
} from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import analytics from "../analytics";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { EDIT_USER } from "../graphql/mutations";
import { GET_ACCOUNT } from "../graphql/queries";

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const history = useHistory();

  const client = useApolloClient();

  const [updateLastLogin] = useMutation(EDIT_USER, {});
  const [getAccountType] = useLazyQuery(GET_ACCOUNT, {
    onCompleted: (data) => {
      setUser({ ...user, accountType: data.account.accountType });
    },
  });

  useEffect(() => {
    // Check if user is already logged in, else set user to null
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Sentry.setUser({ username: user.username });

        getAccountType();

        // Add account type
        setUser(user);
        setAuthenticated(true);
        setUserGroups(user.signInUserSession.idToken.payload["cognito:groups"]);
        analytics.identify(user.attributes.sub, {
          accountId: user.attributes["custom:accountId"],
          userGroup: user.signInUserSession.idToken.payload["cognito:groups"],
        });
        updateLastLogin({
          variables: { userId: user.username, lastLogin: new Date() },
        });
      })
      .catch(() => {
        setUser(null);
        setAuthenticated(false);
      });
  }, []);

  const login = async (email, password) => {
    try {
      const cognitoUser = await Auth.signIn(email, password);
      if (
        "challengeName" in cognitoUser &&
        cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        setUser(cognitoUser);
        history.push("/force-change-password");
      } else {
        getAccountType();
        analytics.track("login");
        Sentry.setUser({ username: cognitoUser.username });
        setUser(cognitoUser);
        setAuthenticated(true);
        setUserGroups(
          cognitoUser.signInUserSession.idToken.payload["cognito:groups"]
        );
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const logout = async () => {
    try {
      const data = await Auth.signOut();
      await client.clearStore();
      setAuthenticated(false);
      setUser(null);
      return data;
    } catch (err) {
      console.log("log out issue");
    }
  };

  const signUp = async (email, password, accountId, clientMetadata) => {
    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "custom:accountId": accountId,
        },
        clientMetadata: clientMetadata,
      });
    } catch (err) {
      throw err;
    }
    return;
  };

  const getJWTToken = () => {
    if (user) {
      return user.signInUserSession.accessToken.jwtToken;
    }
  };

  const changePassword = async (currentUser, oldPassword, password) => {
    try {
      await Auth.changePassword(currentUser, oldPassword, password);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const completeNewPassword = async (password) => {
    try {
      const newUser = await Auth.completeNewPassword(user, password);
      /* accountId attribute is in different place compared to login user object */
      newUser["attributes"] = {
        "custom:accountId":
          newUser.challengeParam.userAttributes["custom:accountId"],
      };
      setUser(newUser);
      setAuthenticated(true);
      setUserGroups(
        newUser.signInUserSession.idToken.payload["cognito:groups"]
      );
      history.push("/");
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const forgotPassword = async (email) => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      console.log(error);
    }
  };

  const forgotPasswordConfirm = async (email, code, password) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const values = useMemo(
    () => ({
      userGroups,
      isAuthenticated,
      user,
      completeNewPassword,
      getJWTToken,
      login,
      logout,
      signUp,
      forgotPassword,
      forgotPasswordConfirm,
      changePassword,
    }),
    [user, isAuthenticated, userGroups]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      "`useUser` hook must be used within a `UserProvider` component"
    );
  }
  return context;
};
