import React, { Fragment, useEffect } from 'react';

import clsx from 'clsx';

// Components
import CustomTextField from '../../UI/TextField/CustomTextField';
import FormPane from '../../UI/SignUp/FormPane';
import SelectPlanPane from '../../UI/SignUp/SelectPlanPane';
import analytics from '../../analytics';
// Form validation
import { useFormContext } from 'react-hook-form';

// Material ui
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core/';

// Material ui styles
import { makeStyles } from '@material-ui/core/styles';

// Translation
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#E8CB47',
    color: '#FFFFFF',
  },
  back: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    borderRadius: '10px',
    border: '2px solid #E5E8ED',
    marginBottom: '20px',
    '&:hover': {
      backgroundColor: '#f6ebb5',
      cursor: 'pointer',
      border: '2px solid #e8cc47',
    },
  },
  cardChecked: {
    backgroundColor: '#f6ebb5',
    border: '2px solid #E8CC47',
  },
  cardField: {
    marginBottom: '15px',
    backgroundColor: 'white',
    padding: '11px 16px',
    borderRadius: '6px',
    border: '1px solid #CCC',
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    lineHeight: '1.3333333',
  },
  forms: {
    backgroundColor: '#F9FAFB',
    padding: theme.spacing(2),
  },
  icon: {
    height: '35px',
    marginRight: '5px',
  },
  iconTitle: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '1px',
    paddingBottom: '0px',
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  stepper: {
    backgroundColor: theme.palette.background.light,
  },
  leftArrow: {
    transform: 'rotate(180deg)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '1px',
    paddingBottom: '24px',
  },
  pos: {
    marginBottom: 12,
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0)',
    },
    '20%': {
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale(1)',
    },
  },
  ripple: {
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '',
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: '120px',
      height: '120px',
      marginLeft: '-60px',
      marginTop: '-60px',
      background: 'rgba(255, 255, 255, 0.3)',
      borderRadius: '100%',
      transform: 'scale(0)',
    },
    '&:not(: active): after': {
      animation: '$ripple 0.6s ease-out',
    },
    '&: after': {
      visibility: 'hidden',
    },
    '&focus: after': {
      visibility: 'visible',
    },
  },
}));

function PaymentForm(props) {
  let {
    paymentType,
    plan,
    setPaymentType,
    setPlan,
    billingAddressStatus,
    setBillingAddressStatus,
    paperInvoiceStatus,
    setPaperInvoiceStatus,
    compiledForm,
  } = props;

  // Translation
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    analytics.page({ name: '/signup/paymentform' });
  }, []);

  // clsx
  const cardStyle = clsx({
    [classes.ripple]: true, //always apply
    [classes.card]: true, //only when open === true
    [classes.cardChecked]: paymentType === 'card', //only when open === true
  });
  const invoiceStyle = clsx({
    [classes.ripple]: true, //always apply
    [classes.card]: true, //only when open === true
    [classes.cardChecked]: paymentType === 'invoice', //only when open === true
  });

  // Validation
  const { errors, register } = useFormContext();

  return (
    <Grid container>
      <SelectPlanPane onClick={setPlan} plan={plan} />
      <FormPane>
        {/* <Grid item xs={12}>
          <Typography variant='h4' className={classes.title}>
            {t('signup.paymentForm.paymentType')}
          </Typography>
        </Grid> */}
        {/* <Grid item container spacing={2} xs={12}>
          <Grid item xs={12} sm={6}>
            <Card
              className={cardStyle}
              variant='outlined'
              onClick={() => setPaymentType('card')}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <img
                      alt='Visa'
                      className={classes.icon}
                      src={process.env.PUBLIC_URL + '/images/visa.png'}
                    />
                    <img
                      alt='MasterCard'
                      className={classes.icon}
                      src={process.env.PUBLIC_URL + '/images/mastercard.png'}
                    />
                    <img
                      alt='American Express'
                      className={classes.icon}
                      src={process.env.PUBLIC_URL + '/images/amex.png'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.iconTitle}
                      color='textSecondary'
                      gutterBottom
                    >
                      {t('signup.paymentForm.creditCard')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              id='invoice'
              className={invoiceStyle}
              variant='outlined'
              onClick={() => setPaymentType('invoice')}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <img
                      alt='Invoice'
                      className={classes.icon}
                      src={process.env.PUBLIC_URL + '/images/invoice.png'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.iconTitle}
                      color='textSecondary'
                      gutterBottom
                    >
                      {t('signup.paymentForm.invoice')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {paymentType === 'card' ? (
          <Fragment>
            <Grid item xs={12}>
              <Typography variant='h4' className={classes.title}>
                {t('signup.paymentForm.title')}
              </Typography>
              <Typography variant='body1'>
                {t('signup.paymentForm.textCard')}
              </Typography>
            </Grid>
          </Fragment>
        ) : ( */}
        <Fragment>
          <Grid item xs={12}>
            <Typography variant='h4' className={classes.title}>
              {t('signup.invoiceForm.title')}
            </Typography>
          </Grid>

          <Grid item xs={12} key='alternateInvoiceEmail'>
            <CustomTextField
              id={'alternateInvoiceEmail'}
              name={'alternateInvoiceEmail'}
              className={classes.input}
              variant='outlined'
              defaultValue={compiledForm.account.email}
              fullWidth
              inputRef={register({
                required: t(
                  'signup.invoiceForm.alternateInvoiceEmail.error.required'
                ),
              })}
              label={t('signup.invoiceForm.alternateInvoiceEmail.label')}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paperInvoiceStatus}
                  onChange={() => setPaperInvoiceStatus()}
                  name='checkedA'
                />
              }
              label={t('signup.invoiceForm.paperBilling.checkbox')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={billingAddressStatus}
                  onChange={() => setBillingAddressStatus()}
                  name='checkedB'
                />
              }
              label={t('signup.invoiceForm.billingAddress.checkbox')}
            />
          </Grid>

          {billingAddressStatus ? (
            <Fragment>
              <Grid item xs={12} key='placeholderAddress'>
                <CustomTextField
                  id={'placeholderAddress'}
                  name={'placeholderAddress'}
                  className={classes.input}
                  disabled
                  defaultValue={compiledForm.address.address}
                  variant='outlined'
                  fullWidth
                  label={t('signup.invoiceForm.address.label')}
                />
              </Grid>
              <Grid item xs={12} key='placeholderCity'>
                <CustomTextField
                  id={'placeholderCity'}
                  name={'placeholderCity'}
                  className={classes.input}
                  disabled
                  defaultValue={compiledForm.address.city}
                  variant='outlined'
                  fullWidth
                  label={t('signup.invoiceForm.city.label')}
                />
              </Grid>
              <Grid item xs={12} key='placeholderZip'>
                <CustomTextField
                  id={'placeholderZip'}
                  name={'placeholderZip'}
                  className={classes.input}
                  disabled
                  defaultValue={compiledForm.address.zipcode}
                  variant='outlined'
                  fullWidth
                  label={t('signup.invoiceForm.zipcode.label')}
                />
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} key='billingAddress'>
                <CustomTextField
                  className={classes.input}
                  name={'billingAddress'}
                  variant='outlined'
                  fullWidth
                  id={'billingAddress'}
                  label={t('signup.invoiceForm.address.label')}
                  autoFocus
                  inputRef={register({
                    required: t('signup.invoiceForm.address.error.required'),
                  })}
                  error={!!errors.billingAddress}
                  helperText={
                    errors.billingAddress && errors['billingAddress'].message
                  }
                />
              </Grid>
              <Grid item xs={12} key='billingCity'>
                <CustomTextField
                  className={classes.input}
                  variant='outlined'
                  fullWidth
                  id='billingCity'
                  label={t('signup.invoiceForm.city.label')}
                  name='billingCity'
                  inputRef={register({
                    required: t('signup.invoiceForm.city.error.required'),
                  })}
                  error={!!errors.billingCity}
                  helperText={
                    errors.billingCity && errors['billingCity'].message
                  }
                />
              </Grid>
              <Grid item xs={12} key='billingZipcode'>
                <CustomTextField
                  className={classes.input}
                  variant='outlined'
                  fullWidth
                  name='billingZipcode'
                  label={t('signup.invoiceForm.zipcode.label')}
                  id='billingZipcode'
                  inputRef={register({
                    required: t('signup.invoiceForm.zipcode.error.required'),
                  })}
                  error={!!errors.billingZipcode}
                  helperText={
                    errors.billingZipcode && errors['billingZipcode'].message
                  }
                />
              </Grid>
            </Fragment>
          )}
        </Fragment>
        {/* )} */}
      </FormPane>
    </Grid>
  );
}

export default PaymentForm;
