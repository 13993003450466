import React, { Fragment, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import { SplitClient, SplitTreatments } from "@splitsoftware/splitio-react";

// Material ui icons
import StopIcon from "@material-ui/icons/Stop";
import { useUser } from "../../libs/contextLib";

// Components
import DeleteButton from "../../UI/Buttons/DeleteButton";
import EditButton from "../../UI/Buttons/EditButton";
import OrderRepairButton from "../../UI/Buttons/OrderRepairButton";
import DeliverButton from "../../UI/Buttons/DeliverButton";
import ContactInformationBox from "../../UI/ContactInformationBox";

import CheckoutToolButton from "../CheckoutToolButton";
import ReturnToolButton from "../ReturnToolButton";

import { useHistory } from "react-router-dom";

// Mobile detect
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  checkoutButton: {
    color: "#000",
    backgroundColor: theme.palette.primary.main,
  },
  returnButton: {
    color: "#000",
    backgroundColor: theme.palette.primary.main,
  },
  description: {
    fontSize: "14px",
  },
  gridMargin: {
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  headline: {
    fontSize: "1.15rem",
    fontWeight: "400",
    marginBottom: "20px",
  },
  subHeadline: {
    fontSize: "1.0rem",
    fontWeight: "400",
    color: "grey",
  },
  image: {
    width: "300px",
    height: "200px",
    objectFit: "contain",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  },

  imageFull: {
    width: "100%",
    marginTop: "55px",
  },
  imageText: {
    dispkay: "block",
  },
  itemSection: {
    marginTop: "10px",
  },
}));

const ToolDetailsTab = memo((props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();

  const {
    data,
    toolId,
    removeLoading,
    handleRemove,
    setOpen,
    setDeliverDialogOpen,
    setRepairDialogOpen,
  } = props;

  const TOOL_REPAIR_FEATURE = "tool_repair";
  const TOOL_DELIVER_FEATURE = "tool_delivery";

  return (
    <Fragment>
      {isMobile && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            {data.tool.imageSignedUrl && (
              <Fragment>
                <img
                  alt="Tool"
                  onClick={() => setOpen(true)}
                  src={data.tool.imageSignedUrl}
                  className={classes.image}
                />
                <Typography className={classes.imageText} variant="caption">
                  {t("tool.imageEnlarge")}
                </Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.headline}>
            {data.tool.model} {data.tool.manufacturer.name}
          </Typography>
          <Typography className={classes.description}>
            {data.tool.other}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} className={classes.itemSection}>
          <Grid item xs={12} className={classes.gridMargin}>
            <Typography className={classes.subHeadline}>
              {t("tool.model")}
            </Typography>
            <Typography className={classes.description}>
              {data.tool.model}
            </Typography>
          </Grid>

          {data.tool.location && (
            <Grid item xs={12} className={classes.gridMargin}>
              <Typography className={classes.subHeadline}>
                {t("tool.location.title")}
              </Typography>
              <Typography className={classes.description}>
                {data.tool.location.name}
              </Typography>
            </Grid>
          )}
          {data?.tool?.assignee && (
            <Grid item xs={12} className={classes.gridMargin}>
              <Typography className={classes.subHeadline}>
                {t("tool.assignee.title")}
              </Typography>

              <Typography className={classes.description}>
                {data?.tool?.assignee?.firstName}{" "}
                {data?.tool?.assignee?.lastName}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.gridMargin}>
            <Typography className={classes.subHeadline}>
              {t(
                `tool.status${
                  user.accountType ? `_${user.accountType}` : ""
                }.title`
              )}
            </Typography>
            <Typography className={classes.description}>
              <StopIcon
                fontSize="small"
                style={{
                  color: t(`checkoutsMapping.colors.${data?.tool?.status}`),
                  marginLeft: "-5px",
                  marginBottom: "-5px",
                }}
              />
              {t(
                `tool.status${
                  user?.accountType ? `_${user?.accountType}` : ""
                }.${data.tool.status}`
              )}
            </Typography>
            {data.tool.status === "CHECKOUT" && (
              <ContactInformationBox user={data?.tool?.checkouts?.[0].user} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.itemSection}>
          <Grid item xs={12} className={classes.gridMargin}>
            <Typography className={classes.subHeadline}>
              {t("tool.code")}
            </Typography>
            <Typography className={classes.description}>
              {data.tool.code}
            </Typography>
          </Grid>
          {data?.tool?.serialNumber && (
            <Grid item xs={12} className={classes.gridMargin}>
              <Typography className={classes.subHeadline}>
                {t("tool.serialNumber")}
              </Typography>
              <Typography className={classes.description}>
                {data.tool.serialNumber}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.gridMargin}>
            <Typography className={classes.subHeadline}>
              {t("tool.manufacturer")}
            </Typography>
            {data.tool.manufacturer && (
              <Typography className={classes.description}>
                {data.tool.manufacturer.name}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <SplitTreatments names={[TOOL_REPAIR_FEATURE]}>
              {({ treatments, isReady }) => {
                if (
                  isReady &&
                  treatments[TOOL_REPAIR_FEATURE].treatment === "on"
                ) {
                  if (data?.tool?.repairs) {
                    return (
                      <Fragment>
                        <Typography className={classes.subHeadline}>
                          {t("tool.repairs.title")}
                        </Typography>
                        <Typography className={classes.description}>
                          {data?.tool?.repairs?.[0].status}
                        </Typography>
                      </Fragment>
                    );
                  }
                }
              }}
            </SplitTreatments>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid container item xs={6} style={{ backgroundColor: 'pink' }}> */}
      <Grid container item spacing={2} className={classes.itemSection}>
        <Grid item xs={12}>
          {data?.tool?.description && (
            <Fragment>
              <Typography className={classes.subHeadline}>
                {t("tool.other")}
              </Typography>
              <Typography className={classes.description}>
                {data?.tool?.description}
              </Typography>
            </Fragment>
          )}
        </Grid>
      </Grid>
      {/* </Grid> */}
      {!isMobile && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            {data?.tool?.imageSignedUrl && (
              <Fragment>
                <img
                  alt="Tool"
                  src={data.tool.imageSignedUrl}
                  onClick={() => setOpen(true)}
                  className={classes.image}
                />
                <Typography className={classes.imageText} variant="caption">
                  {t("tool.imageEnlarge")}
                </Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={2}>
        <Grid item xs={12} sm md>
          <EditButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(`/tools/${toolId}/edit`)}
          >
            {t("tool.edit.buttonText")}
          </EditButton>
        </Grid>

        <Grid item xs={12} sm md>
          {data.tool.status === "AVAILABLE" ? (
            <CheckoutToolButton
              fullWidth
              loaderColor="#000"
              className={classes.checkoutButton}
              variant="contained"
              color="primary"
              toolId={toolId}
            />
          ) : (
            <ReturnToolButton
              fullWidth
              loaderColor="#000"
              className={classes.returnButton}
              variant="contained"
              color="primary"
              toolId={toolId}
              disabled={data?.tool?.checkouts?.[0]?.user?.id !== user?.username}
            />
          )}
        </Grid>

        <Grid item xs={12} sm md>
          <DeleteButton
            fullWidth
            variant="contained"
            color="primary"
            isLoading={removeLoading}
            onClick={() => handleRemove(toolId)}
          >
            {t("tool.delete.buttonText")}
          </DeleteButton>
        </Grid>

      </Grid>
      <Grid container item spacing={2}>
        <SplitClient splitKey={user?.username}>
          <SplitTreatments names={[TOOL_REPAIR_FEATURE]}>
            {({ treatments, isReady }) => {
              if (
                isReady &&
                treatments[TOOL_REPAIR_FEATURE].treatment === "on"
              ) {
                return (
                  <Grid item xs sm md>
                    <OrderRepairButton
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      onClick={() => setRepairDialogOpen(true)}
                    >
                      {t("tool.orderRepair.buttonText")}
                    </OrderRepairButton>{" "}
                  </Grid>
                );
              }
            }}
          </SplitTreatments>
          <SplitTreatments names={[TOOL_DELIVER_FEATURE]}>
            {({ treatments, isReady }) => {
              if (
                isReady &&
                treatments[TOOL_DELIVER_FEATURE].treatment === "on"
              ) {
                return (
                  <Grid item xs sm md>
                    <DeliverButton
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      onClick={() => setDeliverDialogOpen(true)}
                    >
                      {t("tool.deliver.buttonText")}
                    </DeliverButton>
                  </Grid>
                );
              }
            }}
          </SplitTreatments>
        </SplitClient>
      </Grid>
    </Fragment>
  );
});

export default ToolDetailsTab;
