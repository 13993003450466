import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { ReformatDateTime, ReformatToolStatus } from "../../libs/formattingLib";
import TableActionMenu from "../../UI/Table/TableActionMenu";
import BaseCard from "../../UI/Cards/BaseCard";

// Hooks
import { useUser } from "../../libs/contextLib";

// Components
import Loader from "../../UI/Loader/Loader";
import Table from "../../UI/Table/Table";
import Snackbar from "../Snackbar";
import { TitleComponent } from "../Title";

// Mobile detect
import { isMobile } from "react-device-detect";

import { LIST_TOOLS } from "../../graphql/queries";
import { REMOVE_TOOL } from "../../graphql/mutations";

import { makeStyles } from "@material-ui/core/styles";

import { Typography, Box, Button, Container, Grid } from "@material-ui/core";

// Material ui icons
import AddIcon from "@material-ui/icons/Add";
import Copyright from "../Copyright";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: "relative",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    marginRight: "5px",
  },
  menuButton: {
    marginRight: "2px",
    marginLeft: "2px",
  },
}));

export default function Tools() {
  const [severity, setSeverity] = useState();
  const [transactionText, setTransactionText] = useState();
  const [snackbar, setSnackbar] = useState(false);

  // Hooks
  const { user } = useUser();

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, data: toolData } = useQuery(LIST_TOOLS, {
    fetchPolicy: "network-only",
    /* Set for loading and onCompleted callback on refetch to be called */
    notifyOnNetworkStatusChange: true,
  });

  const updateCache = (cache, { data }) => {
    try {
      const existingTools = cache.readQuery({
        query: LIST_TOOLS,
      });

      cache.evict({ id: `Tool:${data.removeTool.id}` });

      const tools = existingTools.tools.filter(
        (t) => t.id !== data.removeTool.id
      );
      cache.writeQuery({
        query: LIST_TOOLS,
        data: { tools: tools },
      });

      return tools;
    } catch (err) {
      console.log(err);
    }
  };

  const [removeTool, { loading: removeLoading }] = useMutation(REMOVE_TOOL, {
    refetchQueries: [{ query: LIST_TOOLS }],
    update: updateCache,
    onCompleted: () => {
      setTransactionText(t("tools.removeTool.success"));
      setSeverity("success");
      setSnackbar(true);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSeverity("error");
      setSnackbar(true);
    },
  });

  const handleEdit = (toolId) => {
    history.push(`/tools/${toolId}/edit`);
  };

  const handleRemove = (toolId) => {
    removeTool({ variables: { toolId: toolId } });
  };

  const handleDuplicate = (toolId) => {
    removeTool({ variables: { toolId: toolId } });
  };

  let headCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "code",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.code`
      ),
      options: { filterType: "textField" },
    },
    {
      name: "model",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.model`
      ),
      options: { filterType: "textField" },
    },
    {
      name: "manufacturer.name",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.manufacturer`
      ),
      options: { filterType: "multiselect" },
    },
    {
      name: "currentCheckoutUser",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.currentCheckoutUser`
      ),
      options: {
        sort: true,
        filterType: "multiselect",
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1?.data?.firstName && obj1?.data?.lastName ? `${obj1.data?.firstName} ${obj1.data?.lastName}`: " "
            let val2 = obj2?.data?.firstName && obj2?.data?.lastName ? `${obj2.data?.firstName} ${obj2.data?.lastName}`: " "
            return (typeof val1.localeCompare === 'function' ? val1.localeCompare(val2) : val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
        customFilterListOptions: {
          render: ({value}) => {
            return value ? value : "Ej utcheckad";
          },
        },
        filterOptions: {
          renderValue: (value) => {
            return value ? value : "Ej utcheckad";
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.firstName && value?.lastName ? `${value.firstName} ${value.lastName}`: "";
        },
      },
    },
    {
      name: "status",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.status`
      ),
      options: {
        filterType: "multiselect",
        customFilterListOptions: {
          render: (value) => ReformatToolStatus(t, value, user?.accountType),
        },
        filterOptions: {
          renderValue: (value) =>
            t(
              `checkoutsMapping.names${
                user.accountType ? `_${user.accountType}` : ""
              }.${value}`
            ),
        },
        customBodyRender: (value) => ReformatToolStatus(t, value, user?.accountType),
      },
    },
    {
      name: "createdDate",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.createdDate`
      ),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
  ];

  if (user.accountType === "rental") {
    headCells.push({
      name: "assignee.firstName",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.firstName`
      ),
      options: { filterType: "dropdown" },
    });
    headCells.push({
      name: "assignee.lastName",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.lastName`
      ),
      options: { filterType: "dropdown" },
    });
  }

  if (user.accountType === null) {
    headCells.push({
      name: "location.name",
      label: t("tools.table.header.location"),
      options: { filterType: "dropdown" },
    });
  }

  headCells.push({
    name: "id",
    label: t(
      `tools.table.header${
        user.accountType ? `_${user.accountType}` : ""
      }.action`
    ),
    options: {
      viewColumns: false,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <TableActionMenu
            menuItems={[
              {
                action: () => handleEdit(value),
                icon: <EditIcon className={classes.icon} />,
                text: t("tools.table.actions.edit"),
              },
              {
                action: () => handleRemove(value),
                icon: <DeleteIcon className={classes.icon} />,
                text: t("tools.table.actions.remove"),
              },
              // {
              //   action: () => handleDuplicate(value),
              //   icon: <FileCopyIcon className={classes.icon} />,
              //   text: t("tools.table.actions.duplicate"),
              // },
            ]}
          />
        );
      },
      onCellClick: (e) => "",
    },
  });

  return (
    <Fragment>
      <TitleComponent title={t("tools.title")} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                color="primary"
                id="add_tool_button"
                variant="contained"
                disableElevation
                startIcon={<AddIcon />}
                style={{ color: "#FFFFFF", marginBottom: "10px" }}
                onClick={() => history.push("/tools/add")}
              >
                {t("tools.addTool.buttonText")}
              </Button>
            </Grid>
            {loading || removeLoading ? (
              <Loader />
            ) : (
              <Grid item xs={12}>
                <BaseCard>
                  <Typography variant="h2">{t("tools.title")}</Typography>
                  <Fragment>
                    {toolData?.tools && (
                      <Table
                        storageKey="bsToolsTable"
                        headCells={headCells}
                        data={toolData?.tools}
                        onClick={(row) => history.push(`/tools/${row[0]}`)}
                      />
                    )}
                  </Fragment>
                </BaseCard>
              </Grid>
            )}
          </Grid>
          <Snackbar
            setOpen={setSnackbar}
            open={snackbar}
            text={transactionText}
            severity={severity}
          />

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
