import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import QRDialog from './QRDialog';
import { useUser } from '../libs/contextLib';
import Snackbar from './Snackbar'
import { useForm } from 'react-hook-form';
import ReturnToolDialog from './ReturnToolDialog'
import clsx from "clsx";
import analytics from '../analytics'

import { useMutation } from '@apollo/client';

import { RETURN_TOOL } from '../graphql/mutations'
import { LIST_USER_CHECKOUTS, LIST_USERS } from '../graphql/queries'

import {
    Button,
    Grid,
    CircularProgress,
} from '@material-ui/core';


import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    loading: {
        marginRight: "10px !important",
        animation: "spin 1s infinite linear",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    headline: {
        fontSize: '1.15rem',
        fontWeight: '400',
        marginBottom: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    manualCodeText: {
        fontSize: '0.8rem',
        fontWeight: '300',
        textDecoration: 'none',
        color: "#000",
        cursor: "pointer"
    },
    copyright: {
        position: "fixed",
        bottom: theme.spacing(2)
    }
}));


export default function ReturnToolButton(props) {
    const classes = useStyles();
    const { control, register, handleSubmit, errors } = useForm();
    const [returnTool, setReturnTool] = useState(false);
    const [code, setCode] = useState();
    const { user } = useUser();
    const { t } = useTranslation();


    const [returnToolDialog, setReturnToolDialog] = useState(false);
    const [manualCode, setManualCode] = useState(false);

    const [open, setOpen] = useState(false);
    const [transactionText, setTransactionText] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setManualCode(false)
        setReturnTool(true);
    };

    const handleClose = () => {
        setReturnTool(false);
    };

    const getCoordinates = () => {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true, timeout: 5000 });
            }, 0);
        });
    }

    // Splice the website of a code
    function getFormattedCode(code) {
        if (code.includes('/codes/')) {
            return code.split('/codes/')[1]
        }
        return code
    }

    const onSubmit = async (data) => {
        setManualCode(false)
        setReturnToolDialog(false)
        setLoading(true)
        let latitude = null;
        let longitude = null;

        if ("geolocation" in navigator) {
            try {
                const position = await getCoordinates()
                latitude = position.coords.latitude
                longitude = position.coords.longitude
            }
            catch (e) {
                console.log("Location information disabled / not available, permission granted, OS issue")
            }
        } else {
            console.log("Location information disabled / not available, no permission granted")
        }

        // Alter code if website
        let formattedCode = getFormattedCode(code ? code : data.code)

        analytics.track('returnTool', {
            code: formattedCode,
            "userId": user['username']
        })

        updateReturnTool({
            variables: {
                "code": formattedCode,
                "userId": user['username'],
                "isComplete": data.isComplete,
                "isWorking": data.isWorking,
                "isCleaned": data.isCleaned,
                "lat": latitude,
                "lng": longitude
            }
        });
    };

    const handleScan = async scannedId => {
        if (!scannedId) {
            return
        }
        handleClose();
        if (scannedId) {
            setCode(scannedId)
            setReturnToolDialog(true)
        }
    }

    const handleError = err => {
        console.error(err)
    }

    const [updateReturnTool] = useMutation(RETURN_TOOL, {
        refetchQueries: [
            { query: LIST_USER_CHECKOUTS, variables: { "userId": user['username'], "status": "CHECKOUT" } },
            { query: LIST_USERS },
        ],
        onCompleted: () => {
            setTransactionText(t("dashboard.returnTool.snackbarText.success"))
            setOpen(true)
            setSeverity("success")
            setLoading(false)
        },
        onError: (error) => {
            if ("graphQLErrors" in error && error.graphQLErrors.length > 0 && "extensions" in error.graphQLErrors[0] && "code" in error.graphQLErrors[0].extensions) {
                setTransactionText(t(`errors.${error.graphQLErrors[0].extensions.code}`))
            } else {
                setTransactionText(t("errors.UNKNOWN_ERROR"))
            }
            setOpen(true)
            setSeverity("error")
            setLoading(false)
        }
    });


    return (
        <Fragment>
            <Grid item xs={12}>
                <Button
                    disableElevation
                    fullWidth={props.fullWidth}
                    className={clsx(props.className, classes.root)}
                    onClick={() => handleClickOpen()}
                    variant={props.variant}
                    color={props.color}
                    disabled={props.disabled || loading}
                >
                    {loading &&
                        <CircularProgress
                            size={24}
                            style={{ color: props.loaderColor }}
                            className={classes.loading}
                        />
                    }
                    {t("returnToolButton.buttonText")}
                </Button>
            </Grid>
            <Grid item xs={12}>
                {props.showLink &&
                    <a id="return_tool_code_text" onClick={() => {
                        setManualCode(true);
                        setReturnToolDialog(true);
                    }}
                        className={classes.manualCodeText}>
                        {t("returnToolButton.manualCodeText")}
                    </a>
                }
            </Grid>
            <QRDialog
                open={returnTool}
                handleScan={handleScan}
                handleClose={handleClose}
                handleError={handleError}
            />
            <ReturnToolDialog
                setOpen={setReturnToolDialog}
                open={returnToolDialog}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                control={control}
                manualCode={manualCode}
                errors={errors}
                register={register}
            />
            <Snackbar
                open={open}
                setOpen={setOpen}
                text={transactionText}
                severity={severity}
            />
        </Fragment>
    );
};
