import React, { Fragment, useRef } from 'react';

// Components
import CustomTextField from '../../UI/TextField/CustomTextField';
import ImagePane from '../../UI/SignUp/ImagePane';
import FormPane from '../../UI/SignUp/FormPane';

// Form validation
import { useFormContext, Controller } from "react-hook-form";

// Html parser
import Parser from 'html-react-parser';


// Material ui
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Hidden,
    FormHelperText,
    FormControl
}
    from '@material-ui/core/';


// Material ui styles
import { makeStyles } from "@material-ui/core/styles";

// Translation
import { useTranslation } from 'react-i18next';

export default function AccountForm(props) {

    const useStyles = makeStyles((theme) => ({
        checkbox: {
            marginTop: '20px',
        },
        input: {
            backgroundColor: theme.palette.common.white
        },
        title: {
            fontSize: 16,
            textTransform: 'uppercase',
            fontWeight: 600,
            letterSpacing: '1px',
            paddingBottom: '24px'
        }
    }));

    // Translation
    const { t } = useTranslation();
    const classes = useStyles();

    // Validation
    const { watch, errors } = useFormContext();

    // User password
    const password = useRef({});
    password.current = watch("password", "");

    const methods = useFormContext();
    const { register } = methods;

    return (
        <Grid container >
            <Hidden only={['xs']}>
                <ImagePane image={'person_screen_interaction.png'} />
            </Hidden>
            <FormPane >
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>
                        {t("signup.accountForm.title")}
                    </Typography>
                </Grid>

                <Fragment>
                    <CustomTextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        type="email"
                        id="email"
                        label={t('signup.accountForm.email.label')}
                        name="email"
                        autoComplete="email"
                        inputRef={
                            register({
                                required: t("signup.accountForm.email.error.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t("signup.accountForm.email.error.pattern")
                                }
                            })
                        }
                        error={!!errors.email}
                        helperText={errors.email && errors["email"].message}
                    />
                    <CustomTextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        name="password"
                        label={t('signup.accountForm.password.label')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={
                            register({
                                required: t("signup.accountForm.password.error.required"),
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!@$%^&*-]{8,}$/i,
                                    message: t("signup.accountForm.password.error.pattern")
                                }
                            })
                        }
                        error={!!errors.password}
                        helperText={errors.password && errors["password"].message}
                    />
                    <CustomTextField
                        className={classes.input}
                        variant="outlined"
                        fullWidth
                        name="confirmPassword"
                        label={t('signup.accountForm.confirmPassword.label')}
                        type="password"
                        id="confirmPassword"
                        autoComplete="current-password"
                        inputRef={
                            register({
                                required: t("signup.accountForm.confirmPassword.error.required"),
                                validate: value =>
                                    // true 
                                    value === password.current || t("signup.accountForm.confirmPassword.error.validate")

                            })
                        }
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword && errors["confirmPassword"].message}
                    />
                    <Grid item xs={12}>
                        <FormControl
                            required
                            component="fieldset"
                            error={!!errors.privacyPolicy}
                        >
                            <FormControlLabel
                                className={classes.checkbox}
                                label={Parser(t("signup.accountForm.privacyPolicy.label"))}
                                control={<Controller
                                    render={(props) => (
                                        <Checkbox
                                            checked={props.value}
                                            id="privacyPolicy"
                                            onChange={e => props.onChange(e.target.checked)} />
                                    )}
                                    control={props.control}
                                    defaultValue={false}
                                    name="privacyPolicy"
                                    id="privacyPolicy"
                                    rules={{ required: true }}

                                />}
                            />
                            {errors.privacyPolicy && <FormHelperText>{t('signup.accountForm.privacyPolicy.error.required')} </FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            required
                            component="fieldset"
                            error={!!errors.cookiePolicy}
                        >
                            <FormControlLabel
                                label={Parser(t("signup.accountForm.cookiePolicy.label"))}
                                control={<Controller
                                    render={(props) => (
                                        <Checkbox
                                            checked={props.value}
                                            id="cookiePolicy"
                                            onChange={e => props.onChange(e.target.checked)} />
                                    )}
                                    inputRef={
                                        register({
                                            required: t("signup.accountForm.cookiePolicy.error.required"),

                                        })
                                    }
                                    control={props.control}
                                    defaultValue={false}
                                    name="cookiePolicy"
                                    id="cookiePolicy"
                                    rules={{ required: true }}

                                />}
                            />
                            {errors.cookiePolicy && <FormHelperText>{t('signup.accountForm.cookiePolicy.error.required')}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Fragment>
            </FormPane>
        </Grid >

    );
}