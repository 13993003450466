import React from "react";

// Material ui
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    loading: {
        marginRight: "10px !important",
        animation: "spin 1s infinite linear",
        color: "#ffffff",
    }

}));

export default function DeleteButton({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) {
    const classes = useStyles();

    return (
        <Button
            disableElevation
            className={classes.root}
            onClick={props.onClick}
            endIcon={<DeleteIcon />}
            {...props}
        >
            {isLoading && <CircularProgress size={24} className={classes.loading} />}
            {props.children}
        </Button>
    );
}

