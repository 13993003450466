import React, { Fragment, useState } from 'react';
import classes from './BillingSuccess.module.css';
import { useUser } from '../libs/contextLib';

import {
    Typography,
    Grid,
} from '@material-ui/core';

import ItemView from '../UI/Views/ItemView'
import { TitleComponent } from './Title';

import { useTranslation } from 'react-i18next';

export default function BillingSuccess(props) {
    const { t } = useTranslation();
    const { user } = useUser();

    return (
        <Fragment>
            <TitleComponent title={t("checkout.title", { context: user.accountType })} />
            <ItemView buttonText={t("checkout.backButton.text")}>
                <Fragment>

                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" style={{ textAlign: 'center' }}>Thank you for signing up.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }}>It is our goal for you to always be happy with the service we provide, so please let us know if your purchase experience was anything short of excellent.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <svg className={classes.customSvg} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className={classes.circle} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className={classes.check} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                        </Grid>
                    </Grid>

                </Fragment>
            </ItemView>
        </Fragment>

    );
}