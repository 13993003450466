import React from "react";
import CustomTextField from "../UI/TextField/CustomTextField";
import { useUser } from "../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "PlusJakartaSans",
    fontWeight: 500,
    fontSize: "1.0rem",
    color: "#A5A5A5",
    lineHeight: "1.8",
  },
  dialog: {
    backgroundColor: "#F7F8FA",
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      backgroundColor: "#F7F8FA",
    },
  },
}));

export default function CheckoutToolDialog(props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
    props.setManualCode(false);
  };

  return (
    <Dialog
      className={classes.dialogWrapper}
      fullWidth={true}
      maxWidth={"md"}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box mx={{ xs: 2, sm: 4, md: 10 }} my={{ xs: 1, sm: 2, md: 4 }}>
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2">
                {t("checkoutToolDialog.title", { context: user.accountType })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props.manualCode && (
                <CustomTextField
                  autoFocus={true}
                  inputRef={props.register({
                    required: t("checkoutToolDialog.code.errors.required"),
                  })}
                  error={!!props.errors.code}
                  helperText={props.errors.code && props.errors["code"].message}
                  margin="normal"
                  name="code"
                  id="code"
                  label={t("checkoutToolDialog.code.title")}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                inputRef={props.register({})}
                margin="normal"
                name="marking"
                id="marking"
                label={t("checkoutToolDialog.marking.title")}
              />
            </Grid>
            <DialogActions>
              <Button id="checkout_tool_confirm_button" type="submit" color="primary">
                {t("checkoutToolDialog.submit", {
                  context: user.accountType,
                })}
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus>
                {t("checkoutToolDialog.cancel")}
              </Button>
            </DialogActions>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
