const config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: process.env.REACT_APP_AWS_REGION,
      BUCKET: process.env.REACT_APP_USER_UPLOAD_BUCKET
    },
    apiGateway: {
      REGION: process.env.REACT_APP_AWS_REGION,
      URL: process.env.REACT_APP_API_URL
    },
    cognito: {
      REGION: process.env.REACT_APP_AWS_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,

  }
  
  export default config;