import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "PlusJakartaSans",
    fontWeight: 500,
    fontSize: "1.0rem",
    color: "#A5A5A5",
    lineHeight: "1.8",
  },
  dialog: {
    backgroundColor: "#F7F8FA",
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      backgroundColor: "#F7F8FA",
      paddingRight: "90px",
      paddingLeft: "90px",
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
}));

export default function CustomDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      className={classes.dialogWrapper}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">{t("deleteDialog.title")}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("deleteDialog.contentText")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={"delete_user_dialog_button"}
          onClick={() => props.handleConfirmDelete(props.userId)}
          color="primary"
        >
          {t("deleteDialog.deleteButton")}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t("deleteDialog.cancelButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
