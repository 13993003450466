import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import QRDialog from './QRDialog';
import { useUser } from '../libs/contextLib';
import Snackbar from './Snackbar'
import CheckoutToolDialog from './CheckoutToolDialog'
import { useForm } from 'react-hook-form';
import clsx from "clsx";

import { useMutation } from '@apollo/client';
import analytics from '../analytics'

import { CHECKOUT_TOOL } from '../graphql/mutations'
import { LIST_USER_CHECKOUTS, LIST_USERS } from '../graphql/queries'

import {
    Button,
    CircularProgress,
    Grid
} from '@material-ui/core';


import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    loading: {
        marginRight: "10px !important",
        animation: "spin 1s infinite linear",
        color: "#000"
    },
    manualCodeText: {
        fontSize: '0.8rem',
        fontWeight: '300',
        textDecoration: 'none',
        color: "#000",
        cursor: "pointer"
    }
}));


export default function CheckoutToolButton(props) {
    const classes = useStyles();
    const { control, register, handleSubmit, errors } = useForm();
    const [checkoutTool, setCheckoutTool] = useState(false);
    const [code, setCode] = useState();
    const [manualCode, setManualCode] = useState(false);

    const { user } = useUser();
    const { t } = useTranslation();

    const [checkoutToolDialog, setCheckoutToolDialog] = useState(false);

    const [transactionText, setTransactionText] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);

    // Splice the website of a code
    function getFormattedCode(code) {
        if (code.includes('/codes/')) {
            return code.split('/codes/')[1]
        }
        return code
    }

    const handleClickOpen = () => {
        setManualCode(false)
        setCheckoutTool(true);
    };

    const handleClose = () => {
        setCheckoutTool(false);
    };


    const getCoordinates = () => {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true, timeout: 5000 });
            }, 0);
        });
    }

    const onSubmit = async (data) => {
        setManualCode(false)
        setCheckoutToolDialog(false)
        setLoading(true)
        let latitude = null;
        let longitude = null;

        if ("geolocation" in navigator) {
            try {
                const position = await getCoordinates()
                latitude = position.coords.latitude
                longitude = position.coords.longitude
            }
            catch (e) {
                console.log("Location information disabled / not available, permission granted, OS issue")
                setTransactionText(t("checkoutToolButton.location.error"))
                setSeverity("warning")
                setSnackbar(true);
            }
        } else {
            console.log("Location information disabled / not available, no permission granted")
            setTransactionText(t("checkoutToolButton.location.error"))
            setSeverity("warning")
            setSnackbar(true);
        }
        
        // Alter code if website
        let formattedCode = getFormattedCode(code ? code : data.code)

        analytics.track('checkoutTool', {
            code: formattedCode,
            "userId": user['username'],
            "marking": data.marking
        })

        updateCheckoutTool({
            variables: {
                "code": formattedCode,
                "userId": user['username'],
                "marking": data.marking,
                "lat": latitude,
                "lng": longitude
            }
        });
    }

    const handleScan = async scannedId => {
        if (!scannedId) {
            return
        }
        handleClose();
        if (scannedId) {
            setCode(scannedId)
            setCheckoutToolDialog(true)
        }
    }

    const handleError = err => {
        console.error(err)
    }

    const [updateCheckoutTool] = useMutation(CHECKOUT_TOOL, {
        refetchQueries: [
            { query: LIST_USER_CHECKOUTS, variables: { "userId": user['username'], "status": "CHECKOUT" } },
            { query: LIST_USERS }
        ],
        onCompleted: () => {
            setTransactionText(t("dashboard.checkoutTool.snackbarText.success"))
            setSnackbar(true)
            setSeverity("success")
            setLoading(false)
        },
        onError: (error) => {
            if ("graphQLErrors" in error && error.graphQLErrors.length > 0 && "extensions" in error.graphQLErrors[0] && "code" in error.graphQLErrors[0].extensions) {
                setTransactionText(t(`errors.${error.graphQLErrors[0].extensions.code}`))
            } else {
                setTransactionText(t("errors.UNKNOWN_ERROR"))
            }
            setSnackbar(true)
            setSeverity("error")
            setLoading(false)
        }
    });

    return (
        <Fragment>
            <Grid item xs={12}>
                <Button
                    disableElevation
                    className={clsx(props.className, classes.root)}
                    fullWidth={props.fullWidth}
                    onClick={() => handleClickOpen()}
                    variant={props.variant}
                    color={props.color}
                    disabled={props.disabled || loading}
                >
                    {loading &&
                        <CircularProgress
                            size={24}
                            style={{ color: props.loaderColor }}
                            className={classes.loading}
                        />
                    }
                    {t("checkoutToolButton.buttonText", { context: user.accountType })}
                </Button>
            </Grid>
            <Grid item xs={12}>
                {props.showLink &&
                    <a id="checkout_tool_code_text" onClick={() => {
                        setManualCode(true);
                        setCheckoutToolDialog(true);
                    }}
                        className={classes.manualCodeText}>
                        {t("checkoutToolButton.manualCodeText")}
                    </a>
                }
            </Grid>
            <QRDialog
                open={checkoutTool}
                handleScan={handleScan}
                handleClose={handleClose}
                handleError={handleError}
            />
            <CheckoutToolDialog
                setOpen={setCheckoutToolDialog}
                open={checkoutToolDialog}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                control={control}
                setManualCode={setManualCode}
                manualCode={manualCode}
                errors={errors}
                register={register}
            />
            <Snackbar
                open={snackbar}
                setOpen={setSnackbar}
                text={transactionText}
                severity={severity}
            />
        </Fragment>
    );
};
