import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import LoaderButton from "../../UI/Buttons/LoaderButton";
import BackButton from "../../UI/Buttons/BackButton";
import CustomTextField from "../../UI/TextField/CustomTextField";
import analytics from "../../analytics";
import { TitleComponent } from "../Title";

import Snackbar from "../Snackbar";

import {
  Box,
  Container,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import { LIST_USERS } from "../../graphql/queries";
import { ADD_USER } from "../../graphql/mutations";

// Phone input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { useMutation } from "@apollo/client";

import { useUser } from "../../libs/contextLib";

import { useTranslation } from "react-i18next";

import Copyright from "../Copyright";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.light,
  },
  submit: {
    color: "#FFFFFF",
  },
  phoneContainer: {
    height: "59px",
    borderRadius: "10px",
    marginTop: "16px",
    marginBottom: "10px",
  },
  phoneInput: {
    borderRadius: "10px",
    height: "59px",
    width: "100%",
  },
}));

export default function AddUser() {
  const { user } = useUser();
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [transactionText, setTransactionText] = useState("");
  const history = useHistory();

  const [addUser, { loading: isLoading }] = useMutation(ADD_USER, {
    refetchQueries: [{ query: LIST_USERS }],
    onCompleted: () => {
      reset();
      setTransactionText(t(`addUser${user.accountType ? `_${user.accountType}` : ''}.success`));
      setSeverity("success");
      setOpen(true);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSeverity("error");
      setOpen(true);
    },
  });

  const { control, register, handleSubmit, errors, reset } = useForm();

  const hasError = (inputName) => !!(errors && errors[inputName]);

  const onSubmit = async (data) => {
    analytics.track("addUser", {
      isAdmin: data.isAdmin,
    });

    await addUser({
      variables: {
        email: data.email,
        isAdmin: data.isAdmin,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
      },
    });
  };

  return (
    <Fragment>
      <TitleComponent title={t(`addUser.title`)} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BackButton
                buttonText={t(`addUser${user.accountType ? `_${user.accountType}` : ''}.backButton.text`)}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={classes.form}
                >
                  <Grid container>
                    <Grid item sm={12}>
                      <CustomTextField
                        inputRef={register({
                          required: t(`addUser${user.accountType ? `_${user.accountType}` : ''}.email.error.required`),
                        })}
                        error={hasError("email")}
                        helperText={
                          hasError("email") && errors["email"].message
                        }
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        type="email"
                        id="email"
                        label={t(`addUser${user.accountType ? `_${user.accountType}` : ''}.email.label`)}
                        name="email"
                        autoFocus
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item sm={6}>
                        <CustomTextField
                          inputRef={register({
                            required: t(`addUser${user.accountType ? `_${user.accountType}` : ''}.firstName.error.required`),
                          })}
                          error={hasError("firstName")}
                          helperText={
                            hasError("firstName") && errors["firstName"].message
                          }
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="firstName"
                          label={t(`addUser${user.accountType ? `_${user.accountType}` : ''}.firstName.label`)}
                          id="firstName"
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <CustomTextField
                          inputRef={register({
                            required: t(`addUser${user.accountType ? `_${user.accountType}` : ''}.lastName.error.required`),
                          })}
                          error={hasError("lastName")}
                          helperText={
                            hasError("lastName") && errors["lastName"].message
                          }
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="lastName"
                          label={t(`addUser${user.accountType ? `_${user.accountType}` : ''}.lastName.label`)}
                          id="lastName"
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <Typography variant="overline">
                        {t("settings.phone.label")}
                      </Typography>
                      <Controller
                        className={classes.phoneInput}
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ onChange, value, name }) => (
                          <PhoneInput
                            autocompleteSearch={true}
                            country="se"
                            inputProps={{ name }}
                            onChange={(tel, data) => {
                              onChange(tel);
                            }}
                            specialLabel={false}
                            containerClass={classes.phoneContainer}
                            inputStyle={
                              errors.phone
                                ? {
                                    color: "red",
                                    borderColor: "red",
                                    borderRadius: "10px",
                                    width: "100%",
                                  }
                                : {
                                    borderRadius: "10px",
                                    color: "black",
                                    width: "100%",
                                  }
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <FormControlLabel
                        label={t(`addUser${user.accountType ? `_${user.accountType}` : ''}.admin`)}
                        control={
                          <Controller
                            render={(props) => (
                              <Checkbox
                                checked={props.value}
                                id="isAdminCheckbox"
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                            control={control}
                            defaultValue={false}
                            name="isAdmin"
                            id="isAdmin"
                          />
                        }
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <LoaderButton
                      id="addUserButton"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isLoading}
                        isLoading={isLoading}
                      >
                        {t(`addUser${user.accountType ? `_${user.accountType}` : ''}.submit.buttonText`)}
                      </LoaderButton>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
          <Snackbar
            open={open}
            setOpen={setOpen}
            text={transactionText}
            severity={severity}
          />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
