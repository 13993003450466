import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ItemView from "../../UI/Views/ItemView";
import { TitleComponent } from "../Title";

import { Grid, IconButton, Tab, Tabs, Typography } from "@material-ui/core";

// Material ui icons
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import TextsmsIcon from "@material-ui/icons/Textsms";

import { useHistory } from "react-router-dom";
import { ReformatDateTime, ReformatToolStatus } from "../../libs/formattingLib";

import { useParams } from "react-router-dom";
import EditButton from "../../UI/Buttons/EditButton";
import DeleteButton from "../../UI/Buttons/DeleteButton";
import ResetPasswordButton from "../../UI/Buttons/ResetPasswordButton";

import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useUser } from "../../libs/contextLib";

// Query
import { LIST_USERS, GET_USER_WITH_CHECKOUTS } from "../../graphql/queries";

// Mutations
import { REMOVE_USER, RESET_PASSWORD_USER } from "../../graphql/mutations";

// Components
import CustomDialog from "../ConfirmDeleteDialog";
import Snackbar from "../Snackbar";
import Table from "../../UI/Table/Table";

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "14px",
  },
  grid: {
    marginBottom: "20px",
  },
  itemSection: {
    marginTop: "10px",
  },
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  hyperLink: {
    textDecoration: "none",
    color: "inherit",
  },
  subHeadline: {
    fontSize: "1.0rem",
    fontWeight: "400",
    color: "grey",
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function User(props) {
  const { t } = useTranslation();
  const history = useHistory();

  // Styles
  const classes = useStyles();

  const { userId } = useParams();
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [severity, setSeverity] = useState("");
  const [transactionText, setTransactionText] = useState("");
  const [snackbar, setSnackbar] = useState(false);

  const headCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "tool.code",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.code`
      ),
    },
    {
      name: "tool.model",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.model`
      ),
    },
    {
      name: "tool.manufacturer.name",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.manufacturer`
      ),
    },
    {
      name: "checkoutDate",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.checkoutDate`
      ),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
    {
      name: "status",
      label: t(
        `checkouts.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.status`
      ),
      options: {
        filterType: "multiselect",
        customFilterListOptions: {
          render: (value) => ReformatToolStatus(t, value, user?.accountType),
        },
        filterOptions: {
          renderValue: (value) =>
            t(
              `checkoutsMapping.names${
                user.accountType ? `_${user.accountType}` : ""
              }.${value}`
            ),
        },
        customBodyRender: (value) => ReformatToolStatus(t, value, user?.accountType),
      },
    },
  ];

  // Tabs
  const [tab, setTab] = useState(0);

  const handleConfirmDelete = (userId) => {
    removeUser({ variables: { userId: userId } });
    setOpen(false);
    history.push("/users");
  };

  const handleRemove = (userId) => {
    setUserToDelete(userId);
    setOpen(true);
  };

  const handleResetPassword = (userId) => {
    try {
      resetPasswordUser({ variables: { userId: userId } });
    } catch (err) {
      console.log(err);
    }
  };

  const [removeUser, { loading: loadingRemoveUser }] = useMutation(
    REMOVE_USER,
    {
      refetchQueries: [{ query: LIST_USERS }],
    }
  );

  const [resetPasswordUser, { loading: loadingResetPassword }] = useMutation(
    RESET_PASSWORD_USER,
    {
      onError: (err) => {
        setTransactionText(t("users.passwordReset.error"));
        setSeverity("error");
        setSnackbar(true);
      },
      onCompleted: () => {
        setTransactionText(t("users.passwordReset.success"));
        setSeverity("success");
        setSnackbar(true);
      },
    }
  );

  const { loading, error, data } = useQuery(GET_USER_WITH_CHECKOUTS, {
    variables: { userId: userId },
  });

  if (error) return `Error! ${error.message}`;

  return (
    <Fragment>
      <TitleComponent title={t("user.title")} />
      <ItemView loading={loading} buttonText={t("user.backButton.text")}>
        <Grid container className={classes.grid}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(newValue)}
            aria-label="Navigation tabs"
          >
            <Tab label={t("user.menu.details")} />
            <Tab
              label={t("user.menu.checkouts", { context: user.accountType })}
            />
          </Tabs>
        </Grid>
        <Grid container>
          {tab === 0 && (
            <Fragment>
              <Grid container className={classes.grid}>
                {data && !error && data.user && (
                  <Grid container item xs={6}>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.itemSection}
                    >
                      <Grid item sm={6}>
                        <Typography className={classes.subHeadline}>
                          {t("user.email")}
                        </Typography>
                        <Typography className={classes.description}>
                          {data.user.email}
                        </Typography>
                      </Grid>
                      {data?.user?.firstName && data?.user?.lastName && (
                        <Grid item sm={6}>
                          <Typography className={classes.subHeadline}>
                            {t("user.name", { context: user.accountType })}
                          </Typography>
                          <Typography className={classes.description}>
                            {data.user.firstName} {data.user.lastName}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item sm={6}>
                        <Typography className={classes.subHeadline}>
                          {t("user.userGroup")}
                        </Typography>
                        <Typography className={classes.description}>
                          {t(
                            `user.userTypes${
                              user.accountType ? `_${user.accountType}` : ""
                            }.${data?.user?.userGroup}`,
                            {
                              returnObjects: true,
                            }
                          )}
                          {/* {data?.user?.userGroup} */}
                        </Typography>
                      </Grid>
                      {data?.user?.phone && (
                        <Grid item sm={6}>
                          <Typography className={classes.subHeadline}>
                            {t("user.phone")}
                          </Typography>
                          <Typography className={classes.description}>
                            <a
                              className={classes.hyperLink}
                              href={"tel:+" + data?.user?.phone}
                            >
                              <IconButton
                                aria-label="delete"
                                className={classes.margin}
                              >
                                <PhoneInTalkIcon fontSize="small" />
                              </IconButton>
                              +{data?.user?.phone}
                            </a>
                          </Typography>
                          <Typography className={classes.description}>
                            <a
                              className={classes.hyperLink}
                              href={"sms:+" + data?.user?.phone}
                            >
                              <IconButton
                                aria-label="delete"
                                className={classes.margin}
                              >
                                <TextsmsIcon fontSize="small" />
                              </IconButton>
                              SMS
                            </a>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.itemSection}
                    ></Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.itemSection}
                    >
                      <Grid item sm={6}></Grid>
                      <Grid item sm={6}></Grid>
                      <Grid item sm={6}>
                        <Typography className={classes.subHeadline}>
                          {t("user.activeCheckouts", {
                            context: user.accountType,
                          })}
                        </Typography>
                        <Typography className={classes.description}>
                          {data?.user?.activeCheckouts}
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography className={classes.subHeadline}>
                          {t("user.totalCheckouts", {
                            context: user.accountType,
                          })}
                        </Typography>
                        <Typography className={classes.description}>
                          {data?.user?.checkouts?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <EditButton
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`/users/${userId}/edit`)}
                    fullWidth
                  >
                    {t("user.edit.buttonText")}
                  </EditButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ResetPasswordButton
                    variant="contained"
                    color="primary"
                    isLoading={loadingResetPassword}
                    onClick={() => handleResetPassword(userId)}
                    fullWidth
                  >
                    {t("user.resetPassword.buttonText")}
                  </ResetPasswordButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DeleteButton
                    id="delete_user"
                    variant="contained"
                    color="secondary"
                    isLoading={loadingRemoveUser}
                    fullWidth
                    onClick={() => {
                      if (userId === user.id) {
                        alert("You can't remove yourself");
                      } else {
                        handleRemove(userId);
                      }
                    }}
                  >
                    {t("user.delete.buttonText", { context: user.accountType })}
                  </DeleteButton>
                </Grid>
              </Grid>
            </Fragment>
          )}
          {tab === 1 && (
            <Fragment>
              {data?.user?.checkouts?.length > 0 ? (
                <Table
                  headCells={headCells}
                  data={data?.user?.checkouts}
                  onClick={(row) => history.push(`/checkouts/${row[0]}`)}
                />
              ) : (
                t("user.noCheckouts", { context: user.accountType })
              )}
            </Fragment>
          )}
        </Grid>

        <CustomDialog
          id={"delete_user_dialog"}
          open={open}
          setOpen={setOpen}
          handleConfirmDelete={handleConfirmDelete}
          userId={userToDelete}
        />
        <Snackbar
          setOpen={setSnackbar}
          open={snackbar}
          text={transactionText}
          severity={severity}
        />
      </ItemView>
    </Fragment>
  );
}
