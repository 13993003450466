import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
    Card,
    CardContent
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: "14px",
        padding: "26px",
    },
    cardContent: {
        padding: "0",
        paddingBottom: "0",
        "& .MuiCardContent-root:last-child": {
            padding: "0"
        },
        "&:last-child": {
            padding: "0"
        }
    },

}));

const BaseCard = (props) => {
    const classes = useStyles();

    return (
        <Card
            style={props.style}
            className={classes.card}
            variant="outlined">
            <CardContent classes={{ root: classes.cardContent }}>
                {props.children}
            </CardContent>
        </Card>
    )
}



export default BaseCard;