import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material ui
import {
    Typography,
    TextField
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "10px",
        [`& fieldset`]: {
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:focus .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiFilledInput-notchedOutline": {
          border: "none",
        },
        "& .MuiFilledInput-underline:before": {
          border: "none",
        },
        "& .MuiFilledInput-underline:after": {
          border: "none",
        },
        "& .MuiFilledInput-root": {
          background: theme.palette.common.white,
          borderRadius: "10px",
        },
        "& .MuiAutocomplete-endAdornment": {
          color: "#2A497F",
        },
        "& .MuiFilledInput-adornedEnd:after": {
          color: "#2A497F",
        },
        "& .MuiTextField-root": {
          marginBottom: "0",
        },
        "& .MuiInputBase-root": {
          color: "#2A497F",
        }
    }
}));

export default function CustomTextField(props) {
    const classes = useStyles();

    return (
        <Fragment>
            <Typography variant="overline">
                {props.label}
            </Typography>
            <TextField
                InputProps={{...props.InputProps, disableUnderline: true}}
                FormHelperTextProps={{ style: { backgroundColor: "#F9FAFB", margin: "0px", paddingLeft: "14px", paddingRight: "14px", paddingTop: "3px" } }}
                className={classes.input}
                variant="filled"
                fullWidth={true}
                mutiline={props.mutiline}
                name={props.name}
                defaultValue={props.defaultValue}
                label={props.label}
                type={props.type}
                id={props.id}
                autoComplete={props.autoComplete}
                inputRef={props.inputRef}
                error={props.error}
                helperText={props.helperText}
                value={props.value}
                {...props}
            />
        </Fragment>
    );
}