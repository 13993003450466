import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useUser } from '../../libs/contextLib';

import { ReformatDateTime, ReformatToolStatus } from "../../libs/formattingLib";

import { LIST_CHECKOUTS, EXPORT_DATA } from "../../graphql/queries";
import BaseCard from "../../UI/Cards/BaseCard";

// Components
import Table from "../../UI/Table/Table";
import Loader from "../../UI/Loader/Loader";

import { TitleComponent } from "../Title";
import Copyright from "../Copyright";

import {
  Typography,
  Box,
  Container,
  Grid
} from "@material-ui/core";

// Mobile detect
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: "relative",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  menuButton: {
    marginRight: "2px",
    marginLeft: "2px",
  },
}));

export default function Checkouts() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();

  let headCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "tool.code",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.code`),
      options: { filterType: "textField" },
    },
    {
      name: "status",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.status`),
      options: {
        filterType: "multiselect",
        customFilterListOptions: {
          render: (value) => ReformatToolStatus(t, value, user?.accountType),
        },
        filterOptions: {
          renderValue: (value) => t(`checkoutsMapping.names${user?.accountType ? `_${user.accountType}` : ''}.${value}`),
        },
        customBodyRender: (value) => ReformatToolStatus(t, value, user?.accountType),
      },
    },
    {
      name: "returnDate",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.returnDate`),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
    {
      name: "checkoutDate",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.checkoutDate`),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
    {
      name: "user.email",
      label: t("checkouts.table.header.email"),
      options: { filterType: "textField" },
    },
    {
      name: "tool.model",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.model`),
      options: { filterType: "textField" },
    },
    {
      name: "tool.manufacturer.name",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.manufacturer`),
      options: { filterType: "multiselect" },
    },
  ];

  if (user.accountType === 'rental') {
    headCells.push({
      name: "user.firstName",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.firstName`),
      options: { filterType: "textField" },
    })
    headCells.push({
      name: "user.lastName",
      label: t(`checkouts.table.header${user.accountType ? `_${user.accountType}` : ''}.lastName`),
      options: { filterType: "textField" },
    })
  }

  const { data: checkoutData, loading } = useQuery(LIST_CHECKOUTS, {
    fetchPolicy: "no-cache",
    /* Set for loading and onCompleted callback on refetch to be called */
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Fragment>
      <TitleComponent title={t(`checkouts${user.accountType ? `_${user.accountType}` : ''}.title`)} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            </Grid>
            {loading ? (
              <Loader />
            ) : (
              <Grid item xs={12}>
                <BaseCard>
                  <Typography variant="h2">
                    {t(`checkouts${user.accountType ? `_${user.accountType}` : ''}.title`)}
                  </Typography>
                  {checkoutData?.checkouts ? (
                    <Table
                      storageKey="bsCheckoutsTable"
                      headCells={headCells}
                      data={checkoutData.checkouts}
                      onClick={(row) => history.push(`/checkouts/${row[0]}`)}
                    />
                  ) : (
                    t("checkouts.noCheckouts")
                  )}
                </BaseCard>

              </Grid>
            )}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
