import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Hooks
import { useForm, Controller } from 'react-hook-form';
import CustomTextField from '../../UI/TextField/CustomTextField'
import { useHistory } from 'react-router-dom';
import ItemView from '../../UI/Views/ItemView'
import analytics from '../../analytics'
import { TitleComponent } from '../Title';
import { useUser } from '../../libs/contextLib';

// Phone input
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import {
    useParams
} from "react-router-dom";

// Components
import LoaderButton from '../../UI/Buttons/LoaderButton';

import { GET_USER } from '../../graphql/queries'
import { EDIT_USER } from '../../graphql/mutations'

import {
    Grid,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';

import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    addButton: {
        marginBottom: '20px'
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    iconMargin: {
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.light
    },
    submit: {
        marginTop: '15px',
        color: "#FFFFFF"
    },
    fixedHeight: {
        height: 240,
    },
    textfield: {
        marginBottom: '15px'
    },
    typography: {
        display: 'block',
        marginBottom: '5px'
    },
}));

export default function EditUser(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { control, register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const { user } = useUser();

    const [editUser, { loading: isLoading }] = useMutation(EDIT_USER, {
        onCompleted: () => history.push("/users")
    });

    let { userId } = useParams();

    const { loading, userError, data: userData } = useQuery(GET_USER, {
        variables: { "userId": userId },
    });

    const hasError = inputName => !!(errors && errors[inputName])


    const onSubmit = async (data) => {

        analytics.track('editUser', {
            "userId": userId,
            "isAdmin": data.isAdmin
        }
        )

        await editUser({ variables: { "userId": userId, "firstName": data.firstName, "lastName": data.lastName, "phone": data.phone, "isAdmin": data.isAdmin } });
    }

    return (
        <Fragment>
            <TitleComponent title={t("editUser.title")} />
            <ItemView loading={loading} buttonText={t("editUser.backButton.text")}>
                <Grid item sm={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    inputRef={
                                        register({
                                            required: t("editUser.firstName.error.required")
                                        })
                                    }
                                    error={hasError("model")}
                                    helperText={hasError("model") && errors["model"].message}
                                    variant="outlined"
                                    fullWidth
                                    name="firstName"
                                    label={t(`editUser.firstName${user.accountType ? `_${user.accountType}` : ''}.label`)}
                                    id="firstName"
                                    defaultValue={userData?.user.firstName}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    inputRef={
                                        register({
                                            required: t("editUser.lastName.error.required")
                                        })
                                    }
                                    error={hasError("lastName")}
                                    helperText={hasError("lastName") && errors["lastName"].message}
                                    variant="outlined"
                                    fullWidth
                                    name="lastName"
                                    label={t(`editUser.lastName${user.accountType ? `_${user.accountType}` : ''}.label`)}
                                    id="lastName"
                                    defaultValue={userData?.user.lastName}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>

                            <Typography variant="overline">
                                {t('settings.phone.label')}
                            </Typography>
                            <Controller
                                className={classes.phoneInput}
                                name="phone"
                                control={control}
                                defaultValue={userData?.user?.phone}
                                rules={{ required: false }}
                                render={({ onChange, value, name }) => (
                                    <PhoneInput
                                        value={value}
                                        autocompleteSearch={true}
                                        country="se"
                                        inputProps={{ name, }}
                                        onChange={(tel, data) => {
                                            onChange(tel)
                                        }}
                                        specialLabel={false}
                                        containerClass={classes.phoneContainer}
                                        inputStyle={errors.phone ? { color: 'red', borderColor: 'red', borderRadius: "10px", width: '100%' } : { borderRadius: "10px", color: 'black', width: '100%' }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <FormControlLabel
                                label={t("addUser.admin")}
                                control={<Controller
                                    render={(props) => (
                                        <Checkbox
                                            name="isAdmin"
                                            id="isAdmin"
                                            checked={props.value}
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                            }}
                                        />
                                    )}
                                    defaultValue={userData?.user.userGroup === "admin"}
                                    control={control}
                                    name="isAdmin"
                                    id="isAdmin"
                                />}
                            />
                        </Grid>
                        <Grid item sm={12}>


                            <LoaderButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={isLoading}
                                isLoading={isLoading}
                            >
                                {t('editUser.submit.buttonText')}
                            </LoaderButton>
                        </Grid>
                    </form>

                </Grid>
            </ItemView>
        </Fragment>
    );
}