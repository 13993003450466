import React, { Fragment, useState, useEffect } from 'react';
import './App.css';
import Routes from './Routes'
import Navbar from './components/Navbar'
import DrawerMenu from './components/DrawerMenu'
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import analytics from './analytics'

import {
    CssBaseline
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    }
}));

function usePageViews() {
    /* https://medium.com/@jehanzaib_awan/how-to-integrate-segment-io-7f89575968fd */
    const location = useLocation();
    useEffect(() => {
        analytics.page({name: location.pathname})
    }, [location])
}
function App() {
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    
    usePageViews()

    return (
        <div className={classes.root}>
            {!['/login', "/signup", "/reset-password", "/force-change-password"].includes(location.pathname) &&
                <Fragment>
                    <CssBaseline />
                    <Navbar open={open} setOpen={setOpen} />
                    <DrawerMenu open={open} setOpen={setOpen} />
                </Fragment>
            }
            <Routes />
        </div>
    );
}

export default App;
