import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useUser } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated, userGroups } = useUser();
  let { allowed } = rest;
  let groupAuthorized= true;

  if (allowed && userGroups) {
    groupAuthorized = allowed.filter(value => userGroups.includes(value))
  }

  return (
    <Route {...rest}>
      {isAuthenticated && groupAuthorized ? (
        children
      ) : (
        <Redirect to={
          `/login?redirect=${pathname}${search}`
        } />
      )}
    </Route>
  );
}