import React, { Fragment } from 'react';
import classes from './BillingFail.module.css';
import { useUser } from '../libs/contextLib';

import {
    Typography,
    Grid,
} from '@material-ui/core';
import ItemView from '../UI/Views/ItemView'
import { TitleComponent } from './Title';


import { useTranslation } from 'react-i18next';

export default function BillingFail(props) {
    const { t } = useTranslation();
    const { user } = useUser();

    return (
        <Fragment>
            <TitleComponent title={t("checkout.title", { context: user.accountType })} />
            <ItemView buttonText={t("checkout.backButton.text")}>
                <Fragment>

                    <Grid container >
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" style={{textAlign: 'center'}}>Something went wrong and your payment did not go through.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography  style={{textAlign: 'center'}}>Please try again or contact our support at <a href='mailto:info@buildsight.se'>info@buildsight.se</a></Typography>
                        </Grid>

                        <svg className={classes.customSvg} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className={classes.circle} fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className={[classes.path, classes.line].join(' ')} fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            < line className={[classes.path, classes.line].join(' ')} fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>

                    </Grid>
                </Fragment>
            </ItemView>
        </Fragment>

    );
}