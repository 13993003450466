import React, { useReducer, useState } from 'react';

// Components
import LoaderButton from '../UI/Buttons/LoaderButton';
import FormPane from '../UI/SignUp/FormPane'
import ImagePane from '../UI/SignUp/ImagePane'
import CustomTextField from '../UI/TextField/CustomTextField'

import {
    Container,
    CssBaseline,
    Paper,
    Grid,
    Typography,
    Box,
    Hidden,
    Snackbar
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { useUser } from '../libs/contextLib';
import { useForm } from 'react-hook-form';

import Copyright from "./Copyright"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#FFFFFF'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ForceChangePassword() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { completeNewPassword } = useUser();
    const { register, handleSubmit, errors, setError } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState("");

    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const hasError = inputName => !!(errors && errors[inputName])

    const onSubmit = async (user) => {
        try {
            setIsLoading(true)
            await completeNewPassword(user.password)
        } catch (err) {
            if (err.code === "PasswordPolicyException") {
                setError("password", {
                    type: "manual",
                    message: t("login.password.error.pattern")
                });
            }
            setLoginError(err.message);
            setOpen(true);
            setIsLoading(false);
        }
    };

    const initialState = {
        email: "",
        password: ""
    }

    useReducer(formReducer, initialState);

    function formReducer(prevState, action) {
        return { ...prevState, [action.type]: action.value };
    };

    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Paper className={classes.paper}>
                <Grid container>
                    <Hidden only={['xs']}>
                        <ImagePane image={'person_screen_interaction.png'} />
                    </Hidden>
                    <FormPane >
                        <Typography component="h1" variant="h5">
                            {t('forceChangePassword.title')}
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>

                            <CustomTextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="password"
                                name="password"
                                label={t('forceChangePassword.newPassword.label')}
                                id="newPassword"
                                inputRef={
                                    register({
                                        required: t("forceChangePassword.newPassword.required")
                                    })
                                }
                                error={hasError("newPassword")}
                                helperText={hasError("newPassword") && errors["newPassword"].message}

                            />
                            <CustomTextField
                                variant="outlined"
                                type="password"
                                margin="normal"
                                fullWidth
                                name="confirmNewPassword"
                                label={t('forceChangePassword.confirmNewPassword.label')}
                                id="confirmNewPassword"
                                inputRef={
                                    register({
                                        required: t("forceChangePassword.confirmNewPassword.required")
                                    })
                                }
                                error={hasError("confirmNewPassword")}
                                helperText={hasError("confirmNewPassword") && errors["confirmNewPassword"].message}

                            />
                            <LoaderButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={isLoading}
                                isLoading={isLoading}
                            >
                                {t('forceChangePassword.submit.buttonText')}
                            </LoaderButton>
                        </form>
                    </FormPane>
                </Grid>
            </Paper>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <span>{loginError}</span>
                </Alert>
            </Snackbar>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container >
    )
}

export default ForceChangePassword;