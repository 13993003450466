import React, { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';

import classes from './FileDrop.module.css'


// Material ui
import {

    Paper,

}
    from '@material-ui/core/';

const FileDrop = memo(props => {
    const { text, onDrop, multiple, accept, subtext } = props
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: multiple, accept: accept })
    const { ref, ...rootProps } = getRootProps()

    return (
        <RootRef rootRef={ref}>
            <Paper {...rootProps} className={classes.Paper}>
                <input {...getInputProps()} />
                <p>{text}</p>
                <em>{subtext}</em>
            </Paper>
        </RootRef>
    )
})

export default FileDrop