import { gql } from "@apollo/client";

export const ADD_LOCATION = gql`
  mutation addLocation(
    $name: String!
    $address: String
    $city: String
    $zipcode: String
    $placeId: String
  ) {
    createLocation(
      name: $name
      address: $address
      city: $city
      zipcode: $zipcode
      placeId: $placeId
    ) {
      id
      name
      address
      city
      zipcode
      placeId
      __typename
    }
  }
`;

export const REMOVE_LOCATION = gql`
  mutation removeLocation($locationId: ID!) {
    removeLocation(locationId: $locationId) {
      id
      __typename
    }
  }
`;

export const CREATE_REPAIR = gql`
  mutation createRepair(
    $toolId: String!
    $description: String
    $repairType: String
  ) {
    createRepair(
      toolId: $toolId
      description: $description
      repairType: $repairType
    ) {
      status
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation createReport(
    $name: String
    $reportType: ReportType
    $fileType: ReportFile
    $destination: ReportDestinations
    $recipients: [ID]
    $periodInDays: Int
    $frequency: ReportFrequency
    $startDate: Date
  ) {
    createReport(
      name: $name
      reportType: $reportType
      fileType: $fileType
      destination: $destination
      recipients: $recipients
      periodInDays: $periodInDays
      frequency: $frequency
      startDate: $startDate
    ) {
      id
      name
    }
  }
`;

export const REMOVE_REPORT = gql`
  mutation removeReport($reportId: ID!) {
    removeReport(reportId: $reportId) {
      id
      __typename
    }
  }
`;

export const ADD_TOOL = gql`
  mutation addTool(
    $code: String!
    $model: String
    $manufacturer: ManufacturerInput
    $image: String
    $serialNumber: String
    $description: String
    $location: LocationInput
    $assignee: UserInput
    $purchaseDate: DateTime
    $purchasePrice: Int
    $serviceDate: DateTime
    $serviceInterval: Int,
    $serviceIntervalType: ServiceIntervalType
    $documents: [DocumentInput]
  ) {
    createTool(
      tool: {
        code: $code
        model: $model
        manufacturer: $manufacturer
        image: $image
        serialNumber: $serialNumber
        description: $description
        location: $location
        assignee: $assignee
        purchaseDate: $purchaseDate
        purchasePrice: $purchasePrice
        serviceDate: $serviceDate
        serviceInterval: $serviceInterval
        serviceIntervalType: $serviceIntervalType
        documents: $documents
      }
    ) {
      id
      code
      accountId
      model
      manufacturer {
        id
        name
      }
      serialNumber
      description
      assignee {
        id
        firstName
        lastName
      }
      location {
        id
        name
      }
      image
      purchaseDate
      purchasePrice
      serviceDate
      serviceInterval
      serviceIntervalType
      checkouts {
        id
        status
      }
    }
  }
`;

export const CHECKOUT_TOOL = gql`
  mutation checkoutTool(
    $code: String!
    $userId: String!
    $marking: String
    $lat: String
    $lng: String
  ) {
    checkoutTool(
      code: $code
      userId: $userId
      marking: $marking
      lat: $lat
      lng: $lng
    ) {
      id
      tool {
        id
        code
        model
        image
        __typename
      }
      user {
        id
        firstName
        lastName
        email
        phone
      }
      lat
      long
      marking
      status
      __typename
    }
  }
`;

export const RETURN_TOOL = gql`
  mutation returnTool(
    $code: String!
    $userId: String!
    $isWorking: Boolean!
    $isComplete: Boolean!
    $isCleaned: Boolean!
    $lat: String
    $lng: String
  ) {
    returnTool(
      code: $code
      userId: $userId
      isWorking: $isWorking
      isComplete: $isComplete
      isCleaned: $isCleaned
      lat: $lat
      lng: $lng
    ) {
      id
      tool {
        id
        model
        image
        __typename
      }
      status
      __typename
    }
  }
`;

export const EDIT_TOOL = gql`
  mutation editTool(
    $toolId: ID!
    $code: String
    $model: String
    $manufacturer: ManufacturerInput
    $serialNumber: String
    $description: String
    $location: LocationInput
    $assignee: UserInput
    $purchaseDate: DateTime
    $purchasePrice: Int
    $serviceDate: DateTime
    $serviceInterval: Int
    $serviceIntervalType: ServiceIntervalType
    $image: String
  ) {
    updateTool(
      toolId: $toolId
      tool: {
        code: $code
        model: $model
        manufacturer: $manufacturer
        serialNumber: $serialNumber
        description: $description
        location: $location
        assignee: $assignee
        purchaseDate: $purchaseDate
        purchasePrice: $purchasePrice
        serviceDate: $serviceDate
        serviceInterval: $serviceInterval
        serviceIntervalType: $serviceIntervalType
        image: $image
      }
    ) {
      id
      code
      accountId
      model
      manufacturer {
        name
      }
      serialNumber
      description
      image
      imageSignedUrl
      __typename
    }
  }
`;

export const REMOVE_TOOL = gql`
  mutation removeTool($toolId: ID!) {
    removeTool(toolId: $toolId) {
      id
      __typename
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser(
    $email: String!
    $isAdmin: Boolean!
    $firstName: String!
    $lastName: String!
    $phone: String
  ) {
    createUser(
      email: $email
      isAdmin: $isAdmin
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      id
      email
      firstName
      lastName
      phone
      userGroup
    }
  }
`;

export const EDIT_ACCOUNT = gql`
  mutation editAccount(
    $address: String
    $name: String
    $city: String
    $zipcode: String
  ) {
    updateAccount(
      address: $address
      name: $name
      city: $city
      zipcode: $zipcode
    ) {
      id
      address
      name
      city
      zipcode
    }
  }
`;
export const EDIT_USER = gql`
  mutation editUser(
    $userId: ID!
    $firstName: String
    $lastName: String
    $phone: String
    $language: String
    $isAdmin: Boolean
    $lastLogin: DateTime
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      language: $language
      isAdmin: $isAdmin
      lastLogin: $lastLogin
    ) {
      id
      firstName
      lastName
      phone
      language
      userGroup
    }
  }
`;

export const EDIT_USER_EMAIL = gql`
  mutation editUserEmail($userId: ID!, $email: String!) {
    updateUser(userId: $userId, email: $email) {
      id
      email
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($userId: ID!) {
    removeUser(userId: $userId) {
      id
    }
  }
`;

export const RESET_PASSWORD_USER = gql`
  mutation resetPasswordUser($userId: String!) {
    resetPasswordUser(userId: $userId) {
      ok
    }
  }
`;

export const CREATE_DELIVERY_ORDER = gql`
  mutation createDelivery(
    $serviceType: String!
    $toAddress: String
    $toZipcode: String
    $toCity: String
    $toCountryCode: String
    $toPlaceId: String
    $fromAddress: String
    $fromZipcode: String
    $fromCity: String
    $fromCountryCode: String
    $fromPlaceId: String
    $price: Int
  ) {
    createDelivery(
      serviceType: $serviceType
      toAddress: {
        address: $toAddress
        zipcode: $toZipcode
        city: $toCity
        country: $toCountryCode
        placeId: $toPlaceId
      }
      fromAddress: {
        address: $fromAddress
        zipcode: $fromZipcode
        city: $fromCity
        country: $fromCountryCode
        placeId: $fromPlaceId
      }
      price: $price
    ) {
      price
    }
  }
`;
