import React, { Fragment, useState } from "react";

// Hooks
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useUser } from '../../libs/contextLib';

import Table from "../../UI/Table/Table";
import Loader from "../../UI/Loader/Loader";
import { TitleComponent } from "../Title";
import Snackbar from "../Snackbar";
import TableActionMenu from "../../UI/Table/TableActionMenu";
import BaseCard from "../../UI/Cards/BaseCard";

import { LIST_LOCATIONS } from "../../graphql/queries";
import { REMOVE_LOCATION } from "../../graphql/mutations";

import {
  Typography,
  Box,
  Container,
  Grid,
  Button
} from "@material-ui/core";

// Material ui icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

// Mobile detect
import { isMobile } from "react-device-detect";

import Copyright from "../Copyright";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: "relative",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    marginRight: "5px",
  },
  menuButton: {
    marginRight: "2px",
    marginLeft: "2px",
  },
}));

export default function Locations() {

  // Hooks
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();


  const [severity, setSeverity] = useState("");
  const [transactionText, setTransactionText] = useState("");
  const [snackbar, setSnackbar] = useState(false);

  const updateCache = (cache, { data }) => {
    try {
      const existingLocations = cache.readQuery({
        query: LIST_LOCATIONS,
      });

      cache.evict({ id: `Location:${data.removeLocation.id}` });

      const locations = existingLocations.locations.filter(
        (t) => t.id !== data.removeLocation.id
      );
      cache.writeQuery({
        query: LIST_LOCATIONS,
        data: { locations: locations },
      });

      return locations;
    } catch (err) {
      console.log(err);
    }
  };

  const [removeLocation] = useMutation(REMOVE_LOCATION, {
    refetchQueries: [{ query: LIST_LOCATIONS }],
    update: updateCache,
    onCompleted: () => {
      setTransactionText(t("locations.removeLocation.success"));
      setSeverity("success");
      setSnackbar(true);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSeverity("error");
      setSnackbar(true);
    },
  });

  const handleRemove = (locationId) => {
    removeLocation({ variables: { locationId: locationId } });
  };

  const headCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "name",
      label: t("locations.table.header.name"),
      options: { filterType: "textField" },
    },
    {
      name: "address",
      label: t("locations.table.header.address"),
      options: { filterType: "textField" },
    },
    {
      name: "city",
      label: t("locations.table.header.city"),
    },
    {
      name: "zipcode",
      label: t("locations.table.header.zipcode"),
    },

    {
      name: "id",
      label: t(`tools.table.header${user.accountType ? `_${user.accountType}` : ''}.action`),
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: () => (
          <TableActionMenu
            menuItems={[
              {
                action: handleRemove,
                icon: <DeleteIcon className={classes.icon} />,
                text: t("locations.table.actions.remove"),
              },
            ]}
          />
        ),
        viewColumns: false,
      },
    },
  ];

  const { data, loading, refetch } = useQuery(LIST_LOCATIONS, {
    fetchPolicy: "network-only",
  });

  return (
    <Fragment>
      <TitleComponent title={t("locations.title")} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={12} >
              <Button
                color="primary"
                variant="contained"
                disableElevation
                startIcon={<AddIcon />}
                style={{ color: "#FFFFFF", marginBottom: "10px" }}
                onClick={() => history.push("/locations/add")}
              >
                {t("locations.addLocation.buttonText")}
              </Button>
            </Grid>
            {loading ? (
              <Loader />
            ) : (
              <Grid item xs={12}>
                <BaseCard>
                  <Typography variant="h2">
                    {t("locations.title")}
                  </Typography>
                  {data && data.locations ? (
                    <Table
                      storageKey="bsLocationsTable"
                      headCells={headCells}
                      data={data.locations}
                    />
                  ) : (
                    t("locations.noLocations")
                  )}
                </BaseCard>
              </Grid>
            )}
          </Grid>
          <Snackbar
            setOpen={setSnackbar}
            open={snackbar}
            text={transactionText}
            severity={severity}
          />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
