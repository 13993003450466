import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    width: "100%",
    overflowX: "auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 340,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableCell: {
    cursor: "pointer",
    paddingRight: 4,
    paddingLeft: 4,
  },
  tableHead: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, headCells, storageKey } = props;

  const options = {
    onRowClick: (row, rowMeta) =>
      "onClick" in props ? props.onClick(row) : null,
    selectableRows: "none",
    selectableRowsHeader: false,
    responsive: "standard",
    enableNestedDataAccess: ".",
    storageKey: storageKey,
    textLabels: {
      body: {
        noMatch: t("table.body.noMatch"),
        toolTip: t("table.body.sort"),
        columnHeaderTooltip: (column) =>
          `${t("table.body.sort_for")} ${column.label}`,
      },
      pagination: {
        next: t("table.pagination.next"),
        previous: t("table.pagination.previous"),
        rowsPerPage: t("table.pagination.rowsPerPage"),
        displayRows: t("table.pagination.displayRows"),
      },
      toolbar: {
        search: t("table.toolbar.search"),
        downloadCsv: t("table.toolbar.downloadCsv"),
        print: t("table.toolbar.print"),
        viewColumns: t("table.toolbar.viewColumns"),
        filterTable: t("table.toolbar.filterTable"),
      },
      filter: {
        all: t("table.filter.all"),
        title: t("table.filter.title"),
        reset: t("table.filter.reset"),
      },
      viewColumns: {
        title: t("table.viewColumns.title"),
        titleAria: t("table.viewColumns.titleAria"),
      },
      selectedRows: {
        text: t("table.selectedRows.text"),
        delete: t("table.selectedRows.delete"),
        deleteAria: t("table.selectedRows.deleteAria"),
      },
    },
  };

  return (
    <div className={classes.root}>
        <MUIDataTable data={data} columns={headCells} options={options} />
    </div>
  );
}
