import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontFamily: ["PlusJakartaSans", "sans-serif"],
            fontSize: "2.125rem",
            fontWeight: 800,
            fontStyle: "normal",
            color: "black"
        },
        h2: {
            fontFamily: "PlusJakartaSans",
            color: "#313131",
            fontWeight: 700,
            fontSize: "1.50rem",
            fontStyle: "normal"
        },
        h3: {
            fontFamily: "PlusJakartaSans",
            fontWeight: 500,
            color: "#A5A5A5",
            fontSize: "1.25rem",
            fontStyle: "normal"
        },
        h4: {
            fontFamily: ["PlusJakartaSans", "sans-serif"],
            fontWeight: 700,
            fontSize: "1.0rem",
            fontStyle: "normal",
        },
        h5: {
            fontFamily: "PlusJakartaSans",
            fontWeight: 800,
            color: "black",
            fontStyle: "normal" 
        },
        h6: {
            fontFamily: "PlusJakartaSans",
            fontWeight: 500,
            fontSize: "0.8rem",
            fontStyle: "normal"
        },
        subtitle1: {
            fontFamily: "PlusJakartaSans",
            fontSize: "0.75rem",
            color: "rgba(42, 73, 127, 0.4)",
            fontWeight: 500,
            fontStyle: "normal"
        },
        subtitle2: {
            fontFamily: "PlusJakartaSans",
            fontWeight: 600,
            color: "#A3DD27",
            fontStyle: "normal",
        }
    },
    palette: {
        text: {
            primary: "#000000",
            secondary: "#1C3666"
        },
        primary: {
            main: "#E8CC47"
        },
        secondary: {
            main: "#1C3666"
        },
        background: {
            //default: "#EEEFF0",
            default: "#FFFFFF",
            white: "#FFFFFF",
            light: "#F9FAFB",
            lighter: "#FBFCFD"
        }
    }
});

export default theme;