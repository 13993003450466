import React, { Fragment, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_DELIVERY_PRICE_ESTIMATE } from '../../graphql/queries'
import { CREATE_DELIVERY_ORDER } from '../../graphql/mutations'

import Snackbar from '../Snackbar';


import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography
} from '@material-ui/core';

import LoaderButton from '../../UI/Buttons/LoaderButton';
import { useTranslation } from 'react-i18next';

import AutocompleteAddress from '../AutocompleteAddress'

export default function DeliverDialog(props) {
  const { t } = useTranslation();
  const { open, setOpen, data } = props

  const { control } = useForm();
  const [valueFrom, setValueFrom] = useState(null);
  const [valueTo, setValueTo] = useState(null);
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [transactionText, setTransactionText] = useState("");
  const [severity, setSeverity] = useState("success");

  const [createDeliveryOrder, { loading: order_loading, data: order_data }] = useMutation(CREATE_DELIVERY_ORDER, {
    onComplete: (data) => {
      setIsLoading(false)
    },
    onError: (error) => {
      if (error.graphQLErrors.length > 0 && "extensions" in error.graphQLErrors[0] && "code" in error.graphQLErrors[0].extensions) {
        setTransactionText(t(`errors.${error.graphQLErrors[0].extensions.code}`))
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"))
      }
      setIsLoading(false)
      setSeverity("error")
      setSnackbar(true);
    }
  });


  const [getPriceEstimate, { loading, data: estimated_price }] = useLazyQuery(GET_DELIVERY_PRICE_ESTIMATE, {
    onComplete: (data) => {
      setIsLoading(false)
    },
    onError: (error) => {
      if (error.graphQLErrors.length > 0 && "extensions" in error.graphQLErrors[0] && "code" in error.graphQLErrors[0].extensions) {
        setTransactionText(t(`errors.${error.graphQLErrors[0].extensions.code}`))
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"))
      }
      setIsLoading(false)
      setSeverity("error")
      setSnackbar(true);
    }
  });

  const computePrice = () => {
    setIsLoading(true)

    let fromAddress = valueFrom;
    if (!valueFrom && valueFrom.address !== toolLocation.address) {
      fromAddress = toolLocation;
    }

    getPriceEstimate({ variables: { serviceType: "Direkt", toPlaceId: valueTo.place_id, fromPlaceId: valueFrom.place_id } })
  }

  const placeOrder = () => {
    setIsLoading(true)

    let fromAddress = valueFrom;
    if (!valueFrom && valueFrom.address !== toolLocation.address) {
      fromAddress = toolLocation;
    }

    createDeliveryOrder({ variables: { serviceType: "Direkt", toPlaceId: valueTo.place_id, fromPlaceId: valueFrom.place_id, price: estimated_price.priceEstimate.price } })
  }


  const [deliveryTime, setDeliveryTime] = React.useState('1hour')

  const handleClose = () => {
    setOpen(false);
  };


  const toolLocation = data && data.tool.location ? data.tool.location : null

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form>

          <DialogTitle id="form-dialog-title">{t("deliverDialog.title")}</DialogTitle>
          <DialogContent>

            <DialogContentText>
              {t("deliverDialog.text")}
            </DialogContentText>
            <Controller
              render={
                props => (
                  <AutocompleteAddress {...props} setValue={setValueFrom} label={t("deliverDialog.form.from.label")} />
                )}
              defaultValue={toolLocation ? toolLocation.address : null}
              name="from"
              id="from"
              control={control}
              rules={{ required: t("addLocation.from.error.required") }}
            />

            <Controller
              render={
                props => (
                  <AutocompleteAddress {...props} setValue={setValueTo} label={t("deliverDialog.form.to.label")} />
                )}
              defaultValue={null}
              name="tp"
              id="to"
              control={control}
              rules={{ required: t("addLocation.to.error.required") }}
            />

            <FormControl
              variant="outlined"
            >
              <Typography variant="overline">
                {t("deliverDialog.form.selectTime.label")}
              </Typography>
              <InputLabel id="select-time">
                {t("deliverDialog.form.selectTime.label")}
              </InputLabel>
              <Select
                autoWidth={true}
                labelId="select-time"
                id="select-time"
                value={deliveryTime}
                onChange={(e, data) => {
                  setDeliveryTime(data.props.value)
                }
                }
                label={t("deliverDialog.form.selectTime.label")}
              >
                <MenuItem value={'1hour'}>{t("deliverDialog.form.selectTime.options.1hour")}</MenuItem>
                <MenuItem value={'3hour'}>{t("deliverDialog.form.selectTime.options.3hour")}</MenuItem>
                <MenuItem value={'1day'}>{t("deliverDialog.form.selectTime.options.1day")}</MenuItem>
                <MenuItem value={'2day'}>{t("deliverDialog.form.selectTime.options.2day")}</MenuItem>
              </Select>
            </FormControl>
            <br></br>
            <br></br>
            <LoaderButton
              type="button"
              variant="contained"
              color="primary"
              disabled={false}
              isLoading={isLoading}
              onClick={() => computePrice()}
            >
              {t('deliverDialog.price.buttonText')}
            </LoaderButton>
            {estimated_price &&
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Fragment>
                    <Typography variant="h5">
                      {t("deliverDialog.price.title")}
                    </Typography>
                    <Typography variant="h6">
                      {estimated_price.priceEstimate.price} SEK
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12}>
                  <LoaderButton
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={false}
                    isLoading={isLoading}
                    onClick={() => placeOrder()}
                  >
                    {t('deliverDialog.order.buttonText')}
                  </LoaderButton>
                </Grid>
              </Grid>
            }

            {/* {price &&
              <Fragment>
                <Typography variant="h5">
                  {t("deliverDialog.price.title")}
                </Typography>
                <Typography variant="h6">
                  {price}
                </Typography>
              </Fragment>
            } */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("deliverDialog.cancel.buttonText")}
            </Button>
            <Button onClick={handleClose} color="primary">
              {t("deliverDialog.confirm.buttonText")}
            </Button>
          </DialogActions>
        </form>

      </Dialog>
      <Snackbar setOpen={setSnackbar} open={snackbar} text={transactionText} severity={severity} />

    </div >
  );
}