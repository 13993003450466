import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import BillingFail from "./components/BillingFail";
import BillingSuccess from "./components/BillingSuccess";
import ChangePassword from "./components/ChangePassword";
import ChangeEmail from "./components/ChangeEmail";

import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/Login";

import NotFound from "./components/NotFound";
import SignUp from "./components/signup/SignUp";
import ResetPassword from "./components/ResetPassword";
import ForceChangePassword from "./components/ForceChangePassword";

/* Tool */
import Tools from "./components/tool/Tools"
import Tool from "./components/tool/Tool"
import AddTool from "./components/tool/AddTool"
import EditTool from "./components/tool/EditTool"

/* Location */
import AddLocation from "./components/location/AddLocation"
import Locations from "./components/location/Locations"

/* Checkout */
import Checkouts from "./components/checkout/Checkouts"
import Checkout from "./components/checkout/Checkout"

/* Report */
import AddReport from "./components/report/AddReport"
import Reports from "./components/report/Reports"
import Report from "./components/report/Report"

/* User */
import Users from "./components/user/Users"
import AddUser from "./components/user/AddUser"
import EditUser from "./components/user/EditUser"
import User from "./components/user/User"
import Settings from "./components/user/Settings"

import { useForm, FormProvider } from "react-hook-form";

import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
    const methods = useForm({ mode: "onBlur" });
    const { watch, errors } = methods;
    useEffect(() => {
    }, [watch, errors]);

    return (
        <Switch>
            <AuthenticatedRoute exact path="/">
                <Dashboard />
            </AuthenticatedRoute>


            {/* Checkouts */}
            <AuthenticatedRoute allowed={["admin"]} exact path="/checkouts">
                <Checkouts />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/checkouts/:checkoutId">
                <Checkout />
            </AuthenticatedRoute>

            {/* Codes */}
            <AuthenticatedRoute exact path="/codes/:QRcode">
                <Dashboard />
            </AuthenticatedRoute>

            {/* Reports */}
            <AuthenticatedRoute allowed={["admin"]} exact path="/reports">
                <Reports />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/reports/add">
                <AddReport />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/reports/:reportId">
                <Report />
            </AuthenticatedRoute>


            {/* Locations */}
            <AuthenticatedRoute allowed={["admin"]} exact path="/locations">
                <Locations />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/locations/add">
                <AddLocation />
            </AuthenticatedRoute>

            {/* Tools */}
            <AuthenticatedRoute exact path="/tools">
                <Tools />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/tools/add">
                <AddTool />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/tools/:toolId">
                <Tool />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/tools/:toolId/edit">
                <EditTool />
            </AuthenticatedRoute>

            {/* Settings */}
            <AuthenticatedRoute exact path="/settings">
                <Settings />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/change-password">
                <ChangePassword />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/change-email">
                <ChangeEmail />
            </AuthenticatedRoute>

            {/* Users */}
            <AuthenticatedRoute allowed={["admin"]} exact path="/users">
                <Users />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/users/add">
                <AddUser />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/users/:userId">
                <User />
            </AuthenticatedRoute>
            <AuthenticatedRoute allowed={["admin"]} exact path="/users/:userId/edit">
                <EditUser />
            </AuthenticatedRoute>


            {/* Billing */}
            <UnauthenticatedRoute exact path="/billing-fail/:id">
                <BillingFail />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/billing-success/:id">
                <BillingSuccess />
            </UnauthenticatedRoute>


            <UnauthenticatedRoute exact path="/force-change-password">
                <ForceChangePassword />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/login">
                <Login />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/signup">
                <FormProvider {...methods}>
                    <SignUp />
                </FormProvider>
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/signup/:id">
                <FormProvider {...methods}>
                    <SignUp />
                </FormProvider>
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/reset-password">
                <ResetPassword />
            </UnauthenticatedRoute>
            <AuthenticatedRoute>
                <NotFound />
            </AuthenticatedRoute>
        </Switch>
    );
}