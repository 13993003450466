import React, { Fragment, useState } from 'react';
import { useHistory } from "react-router-dom";

// Auth
import { Auth } from "aws-amplify";
import { useUser } from '../libs/contextLib';

// Components
import ItemView from '../UI/Views/ItemView';
import LoaderButton from '../UI/Buttons/LoaderButton';
import { TitleComponent } from './Title';

// GraphQL
import { EDIT_USER_EMAIL } from '../graphql/mutations'
import { useMutation } from '@apollo/client';


// import classes from './ChangeEmail.module.css';

// Material ui
import {
    Grid,
    Snackbar,
    TextField,
    Typography,
}
    from '@material-ui/core/';

import { makeStyles } from "@material-ui/core/styles";

// Material ui lab
import MuiAlert from '@material-ui/lab/Alert';

// Translation
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));


// Snackbar
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangeEmail() {
    const history = useHistory();

    // Email
    const [email, setEmail] = useState();

    // Error
    const [updateError, setUpdateError] = useState("");

    // Loading
    const [isLoadingChange, setIsLoadingChange] = useState(false);

    const { user } = useUser();

    // Code
    const [codeSent, setCodeSent] = useState(false);

    // Snackbar
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // Style
    const classes = useStyles();

    // Translate
    const { t } = useTranslation();

    // Update email
    const [editUserEmail] = useMutation(EDIT_USER_EMAIL, {
        onCompleted: () => history.push("/settings")
    });

    // Validation
    const { register, handleSubmit, errors } = useForm();


    // Update user data
    async function submitCodeHandler(userData) {
        setIsLoadingChange(true);

        // Update user data
        try {
            await Auth.verifyCurrentUserAttributeSubmit("email", userData.code);

            // Add backend call here to update user email in table
            await editUserEmail({ variables: { "userId": user['username'], "email": email } });

            // history.push("/settings");


        } catch (err) {
            setUpdateError(err.message);
            setOpen(true);
            setIsLoadingChange(false);
        }

    }

    // Update user data
    async function submitEmailHandler(userData) {
        setIsLoadingChange(true);

        // Update user email
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(cognitoUser, { email: userData.email });
            setCodeSent(true);
            setEmail(userData.email);
            setIsLoadingChange(false);


        } catch (err) {
            setUpdateError(err.message);
            setOpen(true);
            setIsLoadingChange(false);
        }

    }

    function renderEmailForm() {
        return (
            <Fragment>
                <TitleComponent title={t("changeEmail.title")} />
                <ItemView width={'sm'} loading={false} buttonText={t("settings.backButton.text")}>

                    <Typography component="h1" variant="h5">
                        {t('changeEmail.title')}
                    </Typography>
                    <form onSubmit={handleSubmit(submitEmailHandler)} className={classes.form}>
                        <Grid container spacing={2} key="email">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t('changeEmail.email.label')}
                                    name="email"
                                    autoComplete="email"
                                    inputRef={
                                        register({
                                            required: t("changeEmail.email.error.required"),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: t("changeEmail.email.error.pattern")
                                            }
                                        })
                                    }
                                    error={!!errors.email}
                                    helperText={errors.email && errors["email"].message}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <LoaderButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoadingChange}
                                    isLoading={isLoadingChange}
                                >
                                    {t('changeEmail.submit.buttonText')}
                                </LoaderButton>
                            </Grid>

                        </Grid>
                    </form>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            {updateError}
                        </Alert>
                    </Snackbar>
                </ItemView >
            </Fragment>
        )
    }


    function renderConfirmationForm() {
        return (
            <Fragment>
                <TitleComponent title={t("changeEmail.title")} />
                <ItemView width={'sm'} loading={false} buttonText={t("settings.backButton.text")}>
                    <Typography component="h1" variant="h5">
                        {t('changeEmail.title')}
                    </Typography>
                    <form onSubmit={handleSubmit(submitCodeHandler)} className={classes.form}>
                        <Grid container spacing={2} key="code">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="code"
                                    label={t('changeEmail.code.label')}
                                    margin="normal"
                                    name="code"
                                    inputRef={
                                        register({
                                            required: t("changeEmail.code.error.required")
                                        })
                                    }
                                    error={!!errors.code}
                                    helperText={errors.code && errors["code"].message}
                                    type="tel"
                                />
                                <Typography
                                    variant="body2">{t('changeEmail.code.helperText', { returnObjects: true })}
                                </Typography>

                            </Grid>

                            <Grid item xs={12}>
                                <LoaderButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoadingChange}
                                    isLoading={isLoadingChange}
                                >
                                    {t('changeEmail.submit.buttonText')}
                                </LoaderButton>
                            </Grid>

                        </Grid>
                    </form>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            {updateError}
                        </Alert>
                    </Snackbar>
                </ItemView >
            </Fragment>
        )
    }


    return (
        !codeSent ? renderEmailForm() : renderConfirmationForm()
    );
}