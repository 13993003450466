import React from "react";

// Material ui
import {
    Grid
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    forms: {
        backgroundColor: '#F9FAFB',
        borderBottomRightRadius: '10px',
        borderTopRightRadius: '10px',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    }
}));
export default function FormPane({ children }) {
    const classes = useStyles();

    return (
        <Grid container item xs={12} md={6} className={classes.forms} direction="row" alignItems="center" >
                {children}
        </Grid>
    );
}