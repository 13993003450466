import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import analytics from "../../analytics";
import { makeStyles } from "@material-ui/core/styles";

import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Snackbar from "../Snackbar";
import { CREATE_REPAIR } from "../../graphql/mutations";
import { LIST_REPAIRS } from "../../graphql/queries";

import CustomTextField from "../../UI/TextField/CustomTextField";
import LoaderButton from "../../UI/Buttons/LoaderButton";

import CustomRadio from "../../UI/RadioButtons/ServiceTypeRadio";
import CloseIcon from "@material-ui/icons/Close";

import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "PlusJakartaSans",
    fontWeight: 500,
    fontSize: "1.0rem",
    color: "#A5A5A5",
    lineHeight: "1.8",
  },
  dialog: {
    backgroundColor: "#F7F8FA",
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      backgroundColor: "#F7F8FA",
    },
  },
}));

export default function RepairDialog(props) {
  const classes = useStyles();

  const { t } = useTranslation();
  const { open, setOpen } = props;

  const [snackbar, setSnackbar] = useState(false);
  const [transactionText, setTransactionText] = useState("");
  const [severity, setSeverity] = useState("success");
  const [serviceType, setServiceType] = useState();
  const { register, handleSubmit } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const [createRepairOrder, { loading }] = useMutation(CREATE_REPAIR, {
    refetchQueries: [{ query: LIST_REPAIRS }],
    onCompleted: () => {
      handleClose();
      setTransactionText(t("tool.repairs.success"));
      setSeverity("success");
      setSnackbar(true);
      setServiceType(null);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSeverity("error");
      setSnackbar(true);
      setServiceType(null);
    },
  });

  const onSubmit = async (data) => {
    analytics.track("createRepair", {
      id: data.id,
      description: data.description,
      toolId: props.data.tool.id,
    });

    await createRepairOrder({
      variables: {
        ...data,
        toolId: props.data.tool.id,
        repairType: serviceType,
      },
    });
  };

  return (
    <Fragment>
      <Dialog
        className={classes.dialogWrapper}
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box mx={{ xs: 2, sm: 4, md: 10 }} my={{ xs: 1, sm: 2, md: 4 }}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container justifyContent={"flex-end"}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Typography variant="h2">{t("repairDialog.title")}</Typography>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    {t("repairDialog.text")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    rows={4}
                    multiline
                    autoFocus
                    inputRef={register({})}
                    id="description"
                    name="description"
                    label={t("repairDialog.form.problemDescription.label")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline">
                    {t("repairDialog.form.serviceType.label")}
                  </Typography>
                  <CustomRadio
                    serviceType={serviceType}
                    setServiceType={setServiceType}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LoaderButton
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                    isLoading={loading}
                  >
                    {t("repairDialog.confirm.buttonText")}
                  </LoaderButton>
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        </Box>
      </Dialog>
      <Snackbar
        setOpen={setSnackbar}
        open={snackbar}
        text={transactionText}
        severity={severity}
      />
    </Fragment>
  );
}
