import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import CustomTextField from "../UI/TextField/CustomTextField";

import {
  Grid,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  FormControl,
  Checkbox,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "PlusJakartaSans",
    fontWeight: 500,
    fontSize: "1.0rem",
    color: "#A5A5A5",
    lineHeight: "1.8",
  },
  dialog: {
    backgroundColor: "#F7F8FA",
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      backgroundColor: "#F7F8FA",
    },
  },
}));
export default function ReturnToolDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <Dialog
      className={classes.dialogWrapper}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"md"}
    >
      <Box mx={{ xs: 2, sm: 4, md: 10 }} my={{ xs: 1, sm: 2, md: 4 }}>
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2">
                {t("returnToolDialog.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props.manualCode && (
                <CustomTextField
                  autoFocus={true}
                  fullWidth
                  inputRef={props.register({
                    required: t("returnToolDialog.code.errors.required"),
                  })}
                  error={!!props.errors.code}
                  helperText={props.errors.code && props.errors["code"].message}
                  margin="normal"
                  name="code"
                  id="code"
                  label={t("returnToolDialog.code.title")}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                component="fieldset"
                error={!!props.errors.isComplete}
              >
                <FormControlLabel
                  label={t("returnToolDialog.isComplete.title")}
                  control={
                    <Controller
                      render={(props) => (
                        <Checkbox
                          checked={props.value}
                          id="toolIsComplete"
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                      control={props.control}
                      defaultValue={false}
                      name="isComplete"
                      id="isComplete"
                      rules={{ required: true }}
                      error={!!props.errors.isComplete}
                    />
                  }
                />
                {props.errors.isComplete && (
                  <FormHelperText>
                    {t("returnToolDialog.isComplete.errors.required")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                component="fieldset"
                error={!!props.errors.isCleaned}
              >
                <FormControlLabel
                  label={t("returnToolDialog.isCleaned.title")}
                  control={
                    <Controller
                      render={(props) => (
                        <Checkbox
                          checked={props.value}
                          id="toolIsCleaned"
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                      control={props.control}
                      defaultValue={false}
                      name="isCleaned"
                      id="isCleaned"
                      rules={{ required: true }}
                      error={!!props.errors.isCleaned}
                      helperText={
                        props.errors.isCleaned &&
                        t("returnToolDialog.isCleaned.errors.required")
                      }
                    />
                  }
                />
                {props.errors.isCleaned && (
                  <FormHelperText>
                    {t("returnToolDialog.isCleaned.errors.required")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                component="fieldset"
                error={!!props.errors.isWorking}
              >
                <FormControlLabel
                  label={t("returnToolDialog.isWorking.title")}
                  control={
                    <Controller
                      render={(props) => (
                        <Checkbox
                          checked={props.value}
                          id="toolIsWorking"
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                      control={props.control}
                      defaultValue={false}
                      name="isWorking"
                      id="isWorking"
                      rules={{ required: true }}
                      error={!!props.errors.isWorking}
                      helperText={
                        props.errors.isWorking &&
                        t("returnToolDialog.isWorking.errors.required")
                      }
                    />
                  }
                />
                {props.errors.isWorking && (
                  <FormHelperText>
                    {t("returnToolDialog.isWorking.errors.required")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <CustomTextField
                    rows={4}
                    multiline
                    margin="normal"
                    fullWidth
                    label={t("returnToolDialog.comment.title")}
                  />
                }
                name="comment"
                control={props.control}
                defaultValue=""
              />
            </Grid>
            <DialogActions>
              <Button id="returnToolConfirmButton" type="submit" color="primary">
                {t("returnToolDialog.submit")}
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus>
                {t("returnToolDialog.cancel")}
              </Button>
            </DialogActions>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
