import React, { Fragment, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

// Hooks
import { useForm, Controller } from "react-hook-form";
import { useUser } from "../../libs/contextLib";
import CustomTextField from "../../UI/TextField/CustomTextField";
import DatepickerDate from "../../UI/Datepicker/DatepickerDate";

// Components
import FileDrop from "../../UI/FileDrop/FileDrop";
import LoaderButton from "../../UI/Buttons/LoaderButton";
import ItemView from "../../UI/Views/ItemView";
import Loader from "../../UI/Loader/Loader";
import Snackbar from "../Snackbar";
import { TitleComponent } from "../Title";
import CustomSelect from "../../UI/Select/CustomSelect";

import { EDIT_TOOL_PAGE } from "../../graphql/queries";
import { EDIT_TOOL } from "../../graphql/mutations";

import analytics from "../../analytics";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useParams } from "react-router-dom";

import QRDialog from "../QRDialog";

// Hooks
import { s3Upload } from "../../libs/awsLib";

import {
  Typography,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";

// Material ui icons
import DeleteIcon from "@material-ui/icons/Delete";

import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginBottom: "20px",
    color: "#FFFFFF",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  iconMargin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.light,
  },
  submit: {
    marginTop: "15px",
    color: "#FFFFFF",
  },
  fixedHeight: {
    height: 240,
  },
  textfield: {
    marginBottom: "15px",
  },
  typography: {
    display: "block",
    marginBottom: "5px",
  },
  image: {
    width: 80,
  },
  gridList: {
    width: 200,
    transform: "translateZ(0)",
  },
  titleBar: {
    background: "rgba(0,0,0,0.3) 0%",
  },
  icon: {
    color: "white",
  },
}));

export default function EditTool(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
  } = useForm();

  const [transactionText, setTransactionText] = useState("");
  const [severity, setSeverity] = useState("success");

  // QR scan
  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  // Loading
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [editTool] = useMutation(EDIT_TOOL, {
    onCompleted: () => history.push("/tools"),
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSnackbar(true);
      setSeverity("error");
      setLoading(false);
    },
  });

  let { toolId } = useParams();

  const { data, loading: loadingData } = useQuery(EDIT_TOOL_PAGE, {
    onCompleted: (data) => {
      setValue("image", data?.tool?.image);
      setValue("code", data?.tool?.code);
    },
    variables: { toolId: toolId },
  });

  const hasError = (inputName) => !!(errors && errors[inputName]);

  const filterTypename = (nested_data) => {
    const allowed = ["id", "name"];
    return Object.keys(nested_data)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = nested_data[key];
        return obj;
      }, {});
  };

  const onSubmit = async (data) => {
    setLoading(true);

    data.toolId = toolId;

    // if image changed, upload and update new image name
    if (image) {
      data.image = await s3Upload(image, user.attributes["custom:accountId"]);
    }

    // if image was removed, update to remove image (else do thing)
    if (image == null) {
      data.image = "";
    }

    analytics.track("editTool", {
      toolId: toolId,
      model: data.model,
      manufacturer: data.manufacturer.name,
    });

    /* this is very ugly but I haven't found a better way yet, could be graphql client middleware? */
    if (data.hasOwnProperty("manufacturer") && data.manufacturer != null) {
      data.manufacturer = filterTypename(data.manufacturer);
    }

    if (data.hasOwnProperty("location") && data.location != null) {
      data.location = filterTypename(data.location);
    }

    if (data.hasOwnProperty("assignee") && data.assignee != null) {
      data.assignee = filterTypename(data.assignee);
    }

    await editTool({ variables: data });
  };
  const handleClickOpen = () => {
    setOpenQRDialog(true);
  };

  const handleClose = () => {
    setOpenQRDialog(false);
  };

  const handleError = (err) => {
    console.error(err);
  };

  // Upload files
  const [image, setImage] = useState("");
  const handleOnDrop = useCallback(
    (acceptedFiles) => {
      setImage(acceptedFiles[0]);
      // Do something with the files
    },
    [image]
  );

  // Reset image
  function handleResetImage() {
    setValue("image", null);
    setValue("imageSignedUrl", null);
    setImage("");
  }

  const handleScan = (data) => {
    if (data) {
      setValue("code", data);
      handleClose();
    }
  };

  return (
    <Fragment>
      <TitleComponent title={t("editTool.title")} />
      <ItemView buttonText={t("editTool.backButton.text")}>
        {loadingData ? (
          <Loader />
        ) : (
          <Fragment>
            <Grid item sm={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                  disableElevation
                  className={classes.addButton}
                  onClick={handleClickOpen}
                  variant="contained"
                  color="primary"
                >
                  {t("editTool.QRLabel")}
                </Button>
                <br></br>

                <CustomTextField
                  inputRef={register({
                    required: t("editTool.code.error.required"),
                  })}
                  error={hasError("code")}
                  helperText={hasError("code") && errors["code"].message}
                  variant="outlined"
                  fullWidth
                  id="code"
                  label={t("editTool.code.label")}
                  name="code"
                  defaultValue={data?.tool?.code}
                />
                <CustomTextField
                  inputRef={register({
                    required: t("editTool.model.error.required"),
                  })}
                  error={hasError("model")}
                  helperText={hasError("model") && errors["model"].message}
                  variant="outlined"
                  fullWidth
                  name="model"
                  label={t("editTool.model.label")}
                  id="model"
                  defaultValue={data?.tool?.model}
                />

                {data?.manufacturers && (
                  <Controller
                    render={(props) => (
                      <Autocomplete
                        defaultValue={data?.tool?.manufacturer}
                        value={props.value}
                        /* only include valid input options for manufacturer input */
                        options={data?.manufacturers.map((manufacturer) => ({
                          id: manufacturer.id,
                          name: manufacturer.name,
                        }))}
                        classes={{
                          option: classes.option,
                        }}
                        onChange={(_, manufacturer) => {
                          props.onChange(manufacturer);
                        }}
                        getOptionSelected={(option, value) =>
                          value.name === option.name
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <React.Fragment>{option.name}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            error={hasError("manufacturer")}
                            helperText={
                              hasError("manufacturer") &&
                              errors["manufacturer"].message
                            }
                            label={t("editTool.manufacturer.label")}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                    defaultValue={data.tool?.manufacturer}
                    name="manufacturer"
                    id="manufacturer"
                    control={control}
                    rules={{
                      required: t("editTool.manufacturer.error.required"),
                    }}
                  />
                )}

                <CustomTextField
                  inputRef={register({})}
                  fullWidth
                  name="serialNumber"
                  label={t("editTool.serialNumber.label")}
                  id="serialNumber"
                  defaultValue={data?.tool?.serialNumber}
                />

                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="overline">
                      {t("editTool.purchaseDate.title", {
                        returnObjects: true,
                      })}
                    </Typography>
                    <Controller
                      render={(props) => (
                        <DatepickerDate
                          data={t("edit.purchaseDate", { returnObjects: true })}
                          error={!!errors.date}
                          fullWidth={true}
                          helperText={errors.date && errors.date.message}
                          id="purchaseDate"
                          name="date"
                          onChange={(value) => props.onChange(value)}
                          rules={{ required: false }}
                          value={props.value}
                        />
                      )}
                      name="purchaseDate"
                      id="purchaseDate"
                      control={control}
                      defaultValue={data?.tool?.purchaseDate}
                      rules={{ required: false }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      render={({ onChange, value, onBlur }) => (
                        <CustomTextField
                          onChange={(e) => onChange(e.target.value)}
                          value={value}
                          name="purchasePrice"
                          label={t("editTool.purchasePrice.label")}
                          id="purchasePrice"
                        />
                      )}
                      defaultValue={data?.tool?.purchasePrice}
                      name="purchasePrice"
                      id="purchasePrice"
                    />
                  </Grid>
                </Grid>
                <CustomTextField
                  inputRef={register({})}
                  fullWidth
                  name="description"
                  multiline
                  rows={4}
                  label={t("editTool.other.label")}
                  id="description"
                  defaultValue={data?.tool?.description}
                />

                {data?.users && (
                  <Controller
                    render={(props) => (
                      <Autocomplete
                        defaultValue={data.tool?.assignee}
                        value={props.value}
                        /* only include valid input options for users input */
                        options={data?.users.map((user) => ({
                          id: user.id,
                          email: user.email,
                          firstName: user.firstName,
                          lastName: user.lastName,
                        }))}
                        classes={{
                          option: classes.option,
                        }}
                        onChange={(_, value) => {
                          if (value == null) {
                            value = { id: null, firstName: "", lastName: "" };
                          }
                          props.onChange(value);
                        }}
                        getOptionSelected={(option, value) =>
                          value.id === option.id
                        }
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.firstName} {option.lastName}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            error={hasError("assignee")}
                            helperText={
                              hasError("assignee") && errors["assignee"].message
                            }
                            label={t("addTool.assignee.label")}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                    defaultValue={data?.tool?.assignee}
                    name="assignee"
                    id="assignee"
                    control={control}
                    rules={{ required: false }}
                  />
                )}

                {data?.locations && (
                  <Controller
                    render={(props) => (
                      <Autocomplete
                        defaultValue={data?.tool?.location}
                        value={props.value}
                        /* only include valid input options for users input */
                        options={data?.locations}
                        classes={{
                          option: classes.option,
                        }}
                        onChange={(_, value) => {
                          if (value == null) {
                            value = { id: null, name: "" };
                          }
                          props.onChange(value);
                        }}
                        getOptionSelected={(option, value) =>
                          value.name === option.name
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <React.Fragment>{option.name}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            error={hasError("location")}
                            helperText={
                              hasError("location") && errors["location"].message
                            }
                            label={t("addTool.location.label")}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                    defaultValue={data?.tool?.location}
                    name="location"
                    id="location"
                    control={control}
                    rules={{ required: false }}
                  />
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      render={({ onChange, value, onBlur }) => (
                        <CustomTextField
                          onChange={(e) => onChange(e.target.value)}
                          value={value}
                          error={hasError("serviceInterval")}
                          helperText={
                            hasError("serviceInterval") &&
                            errors["serviceInterval"].message
                          }
                          label={t("addTool.serviceInterval.label")}
                        />
                      )}
                      name="serviceInterval"
                      id="serviceInterval"
                      defaultValue={data?.tool?.serviceInterval}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                      error={!!errors.serviceIntervalType}
                    >
                      <Typography variant="overline">
                        {t("addTool.serviceIntervalType.label")}
                      </Typography>
                      <CustomSelect
                        name="serviceIntervalType"
                        id="serviceIntervalType"
                        label={t("addTool.serviceIntervalType.label")}
                        control={control}
                        defaultValue={data?.tool?.serviceIntervalType}
                      >
                        <MenuItem value={"DAY"}>
                          {t("addTool.serviceIntervalType.days")}
                        </MenuItem>
                        <MenuItem value={"WEEK"}>
                          {t("addTool.serviceIntervalType.weeks")}
                        </MenuItem>
                        <MenuItem value={"MONTH"}>
                          {t("addTool.serviceIntervalType.months")}
                        </MenuItem>
                        <MenuItem value={"QUARTER"}>
                          {t("addTool.serviceIntervalType.quarters")}
                        </MenuItem>
                        <MenuItem value={"YEAR"}>
                          {t("addTool.serviceIntervalType.years")}
                        </MenuItem>
                      </CustomSelect>
                      {errors.serviceIntervalType && (
                        <FormHelperText>
                          {t("addTool.serviceIntervalType.error")}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline">
                    {t("addTool.serviceDate.label")}
                  </Typography>
                  <Controller
                    render={(props) => (
                      <DatepickerDate
                        data={t("addTool.serviceDate", {
                          returnObjects: true,
                        })}
                        variant="filled"
                        maxDate={new Date()}
                        error={!!errors.date}
                        fullWidth={true}
                        helperText={errors.date && errors.date.message}
                        id="serviceDate"
                        name="serviceDate"
                        onChange={(value) => props.onChange(value)}
                        rules={{ required: false }}
                        value={props.value}
                      />
                    )}
                    name="serviceDate"
                    id="serviceDate"
                    control={control}
                    defaultValue={data?.tool?.serviceDate}
                    rules={{ required: false }}
                  />
                </Grid>

                <Typography className={classes.typography} variant="overline">
                  {t("editTool.image.title")}
                </Typography>
                <Controller
                  name="image"
                  id="image"
                  control={control}
                  defaultValue={data?.tool?.image}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Fragment>
                      {watch("image") ? (
                        <Fragment>
                          <ImageList
                            rowHeight={150}
                            gap={1}
                            className={classes.gridList}
                          >
                            <ImageListItem cols={1} rows={1}>
                              <img alt="Tool" src={data?.tool.imageSignedUrl} />
                              <ImageListItemBar
                                actionIcon={
                                  <IconButton
                                    className={classes.icon}
                                    onClick={handleResetImage}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                }
                                actionPosition="right"
                                className={classes.titleBar}
                              />
                            </ImageListItem>
                          </ImageList>
                        </Fragment>
                      ) : !watch("image") && !image ? (
                        <FileDrop
                          text={t("editTool.image.description")}
                          subtext={t("editTool.image.description_subtext")}
                          accept="image/jpeg,image/png"
                          onDrop={handleOnDrop}
                        />
                      ) : (
                        <Fragment>
                          <Typography>
                            {image.name}
                            <IconButton
                              onClick={handleResetImage}
                              className={classes.iconMargin}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                />

                <LoaderButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  isLoading={loading}
                >
                  {t("editTool.submit.buttonText")}
                </LoaderButton>
              </form>
            </Grid>
            <QRDialog
              open={openQRDialog}
              handleScan={handleScan}
              handleClose={handleClose}
              handleError={handleError}
            />
            <Snackbar
              open={snackbar}
              setOpen={setSnackbar}
              text={transactionText}
              severity={severity}
            />
          </Fragment>
        )}
      </ItemView>
    </Fragment>
  );
}
