import React, { Fragment, useEffect, useState } from 'react';
import analytics from '../../analytics'
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';

import { ADD_LOCATION } from '../../graphql/mutations'
import { LIST_LOCATIONS } from '../../graphql/queries'

// Components
import LoaderButton from '../../UI/Buttons/LoaderButton';
import BackButton from '../../UI/Buttons/BackButton'
import CustomTextField from '../../UI/TextField/CustomTextField'

import Snackbar from '../Snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { TitleComponent } from '../Title';
import Copyright from '../Copyright'

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

// Hooks
import { useHistory } from 'react-router-dom';

import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import { useMutation } from '@apollo/client';

import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
    accordion: {
        backgroundColor: '#F9FAFB',
        boxShadow: 'none',
        padding: '0px 0px 0px 0px',
    },
    accordionGrid: {
        padding: '0px 0px 16px 0px',
    },
    addButton: {
        marginBottom: '20px'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    iconMargin: {
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.light
    },
    submit: {
        marginTop: '15px',
        color: "#FFFFFF"
    },
    fixedHeight: {
        height: 240,
    },
    textfield: {
        marginBottom: '15px'
    },
    typography: {
        display: 'block',
        marginBottom: '5px'
    },
}));


function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export default function AddLocation(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [snackbar, setSnackbar] = useState(false);
    const [transactionText, setTransactionText] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    const [addLocation] = useMutation(ADD_LOCATION, {
        refetchQueries: [{ query: LIST_LOCATIONS }
        ],
        onCompleted: () => {
            reset()
            setTransactionText(t("addLocation.success"))
            setSeverity("success")
            setSnackbar(true);
        },
        onError: (error) => {
            if (error.graphQLErrors.length > 0 && "extensions" in error.graphQLErrors[0] && "code" in error.graphQLErrors[0].extensions) {
                setTransactionText(t(`errors.${error.graphQLErrors[0].extensions.code}`))
            } else {
                setTransactionText(t("errors.UNKNOWN_ERROR"))
            }
            setSeverity("error")
            setSnackbar(true);
        }
    });

    const { register, handleSubmit, errors, reset, control,
        setValue
    } = useForm();

    const history = useHistory();

    const hasError = inputName => !!(errors && errors[inputName])


    const onSubmit = async (data) => {
        setLoading(true)

        let locationEntry = {
            "name": data.name,
            "address": data.address.description,
            "placeId": data.address.place_id
        }

        analytics.track('addLocation', {
            name: data.name,
            address: data.address.description,
            placeId: data.address.place_id
        })


        await addLocation({ variables: locationEntry });

        handleResetForm()
        setLoading(false)

    }

    const [value, setAddress] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const [zipCode, setZipCode] = React.useState('');

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyDSb7T2r1yP3fX1_3AaQFEm2C95G3am49Y&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);

            }, 200),
        [],
    );

    useEffect(() => {
        if (window.google) {
            let map = new window.google.maps.Map(document.createElement("div"));
            if (map && value) {
                let places1 = new window.google.maps.places.PlacesService(map);
                places1.getDetails({ placeId: value.place_id }, function (
                    place,
                    status
                ) {
                    for (let item of place.address_components) {
                        if (item.types[0] === 'postal_code') {
                            setZipCode(item.long_name)
                        }
                    }
                });
            }
            else if (value === null) {
                setZipCode("")
            }
        }
    }, [value])

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);


    // Handle the reset of the form after submit
    function handleResetForm() {
        setAddress(null)
        setValue("name", "", { shouldDirty: false })
    }


    return (
        <Fragment>
            <TitleComponent title={t("addLocation.title")} />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="md" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <BackButton
                                buttonText={t("addLocation.backButton.buttonText")}
                                onClick={() => history.goBack()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                                    <CustomTextField
                                        inputRef={
                                            register({
                                                required: t("addLocation.name.error.required"),
                                            })
                                        }
                                        error={hasError("name")}
                                        helperText={hasError("name") && errors["name"].message}
                                        id="name"
                                        label={t('addLocation.name.label')}
                                        name="name"
                                    />

                                    <Controller
                                        render={
                                            props => (
                                                <Autocomplete
                                                    id="address"
                                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                    filterOptions={(x) => x}
                                                    options={options}
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        setOptions(newValue ? [newValue, ...options] : options);
                                                        setAddress(newValue);
                                                        props.onChange(newValue)
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <CustomTextField {...params} label={t("addLocation.address.label")} />
                                                    )}
                                                    renderOption={(option) => {
                                                        const matches = 'main_text_matched_substrings' in option.structured_formatting ? option.structured_formatting.main_text_matched_substrings : [];
                                                        const parts = parse(
                                                            option.structured_formatting.main_text,
                                                            matches.map((match) => [match.offset, match.offset + match.length]),
                                                        );

                                                        return (
                                                            <Grid container alignItems="center">
                                                                <Grid item>
                                                                    <LocationOnIcon className={classes.icon} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    {parts.map((part, index) => (
                                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                                            {part.text}
                                                                        </span>
                                                                    ))}

                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {option.structured_formatting.secondary_text}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    }}
                                                />
                                            )}
                                        defaultValue={null}
                                        name="address"
                                        id="address"
                                        control={control}
                                        rules={{ required: t("addLocation.address.error.required") }}
                                    />

                                    <LoaderButton
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={loading}
                                        isLoading={loading}
                                    >
                                        {loading ? t('addLocation.submit.buttonTextLoading') : t('addLocation.submit.buttonText')}
                                    </LoaderButton>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Snackbar setOpen={setSnackbar} open={snackbar} text={transactionText} severity={severity} />
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Copyright />
                        </Grid>
                    </Grid>
                </Container >
            </main >
        </Fragment>
    );
}