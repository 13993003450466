
import React, { useState } from "react";
import { Controller } from 'react-hook-form';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

// Material ui
import {
    Grid,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiCardContent-root": {
            paddingBottom: "0"
        }
    },
    input: {
        backgroundColor: theme.palette.common.white,
        [`& fieldset`]: {
            borderRadius: "10px",
        },
        "& .MuiCardContent-root": {
            paddingBottom: "0"
        }
    },
    card: {
        borderRadius: '10px',
        height: "55px",
        textAlign: "center",
        border: "none",
        transition: 'none',
        boxShadow: "inset 0 0 0 2px transparent",
        '&:hover': {
            backgroundColor: '#f6ebb5',
            border: 'none',
            boxShadow: "inset 0 0 0 2px #F2C917",
            cursor: 'pointer',
            transition: 'none'
        }
    },
    cardChecked: {
        backgroundColor: '#f6ebb5',
        boxShadow: "inset 0 0 0 2px #F2C917",
    },
    iconTitle: {
        fontFamily: "PlusJakartaSans",
        fontSize: 16,
        fontWeight: 500,
    }
}));

export default function CustomRadio(props) {
    const {serviceType, setServiceType} = props

    const classes = useStyles();
    const { t } = useTranslation();
    // clsx
    const repairsStyle = clsx({
        [classes.card]: true, //only when open === true
        [classes.cardChecked]: serviceType === 'repairs', //only when open === true
    })
    const serviceStyle = clsx({
        [classes.card]: true, //only when open === true
        [classes.cardChecked]: serviceType === 'service', //only when open === true
    })

    return (
        <Grid
            alignContent={"center"}
            alignItems={"center"}
            container
            spacing={2}
        >
            <Grid item xs={6} sm={3}>
                <Card className={repairsStyle} variant="outlined" onClick={() => setServiceType('repairs')}>
                    <CardContent className={classes.root}>
                        <Typography className={classes.iconTitle} color="textSecondary">
                            {t("repairDialog.form.serviceType.repairs")}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Card className={serviceStyle} variant="outlined" onClick={() => setServiceType('service')}>
                    <CardContent>
                        <Typography className={classes.iconTitle} color="textSecondary">
                            {t("repairDialog.form.serviceType.service")}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
