import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, MenuItem, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function TableActionMenu(props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="delete"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        id={`user_table_icon`}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        {props.menuItems.map((menuItem, index) => (
          <MenuItem
          id={`user_table_${index}`}
            key={index}
            onClick={(e) => {
              handleClose(e);
              menuItem.action();
            }}
          >
            {menuItem?.icon}
            {t(menuItem.text)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
