import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    IconButton,
    Typography,
} from '@material-ui/core';

import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import TextsmsIcon from '@material-ui/icons/Textsms';

const useStyles = makeStyles((theme) => ({
    description: {
        fontSize: '14px',
    },
    links: {
        textDecoration: 'none',
        color: "#000"
    }
}))

const ContactInformationBox = (props) => {
    const classes = useStyles();

    const { user } = props;
    return (
        <Fragment>
            <Typography className={classes.description}>{user.firstName} {user.lastName}</Typography>
            <Typography className={classes.description}>{user.email}</Typography>
            {user.phone && <Typography className={classes.description}>
                <a className={classes.links} href={"tel:+" + user.phone}>
                    +{user.phone}
                    <IconButton aria-label="delete" className={classes.margin}>
                        <PhoneInTalkIcon fontSize="small" />
                    </IconButton>
                </a>
            </Typography>}
            {user.phone && <Typography className={classes.description}>
                <a className={classes.links} href={"sms:+" + user.phone}>


                    SMS <IconButton aria-label="delete" className={classes.margin}>
                        <TextsmsIcon fontSize="small" />
                    </IconButton>
                </a>
            </Typography>}
        </Fragment>)
}

export default ContactInformationBox;