import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BaseCard from "../../UI/Cards/BaseCard";

import clsx from "clsx";
import {
    Chip,
    Grid,
    Typography,
    Tooltip
} from '@material-ui/core';

import ShowChartIcon from '@material-ui/icons/ShowChart';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        borderRadius: "14px"
    },
    cardContent: {
        padding: "0"
    },
    pos: {
        marginBottom: 12,
        fontSize: 12
    },
    mainValue: {
        fontSize: "2.625rem"
    },
    chipIcon: {
        cursor: "pointer"
    },
    baseChip: {
        backgroundColor: "rgba(163, 221, 39, 0.1)",
        color: "#A3DD27",
        "& .MuiChip-deleteIcon": {
            color: "#A3DD27",
        },
        "&:hover": {
            "& .MuiChip-deleteIcon": {
            }
        },
        '&:focus': {
            backgroundColor: "rgba(163, 221, 39, 0.1)",
        },
        '&:active': {
            backgroundColor: "rgba(163, 221, 39, 0.1)",
            "& .MuiChip-deleteIcon": {
            }
        }
    },
    positiveChange: {
        color: "#A3DD27",
        backgroundColor: "rgba(163, 221, 39, 0.1)",
        '&:focus': {
            backgroundColor: "rgba(163, 221, 39, 0.1)",
        },
        '&:active': {
            backgroundColor: "rgba(163, 221, 39, 0.1)",
            "& .MuiChip-deleteIcon": {
            }
        },
        "& .MuiChip-deleteIcon": {
            color: "#A3DD27",
        },
        "&:hover": {
            "& .MuiChip-deleteIcon": {
            }
        },
    },
    positiveChangeLabel: {
        color: "#A3DD27"
    },
    negativeChangeLabel: {
        color: "#DD2727"
    },
    negativeChange: {
        color: "#DD2727",
        backgroundColor: "rgba(221, 39, 39, 0.1)",
        '&:focus': {
            backgroundColor: "rgba(221, 39, 39, 0.1)",
        },
        '&:active': {
            backgroundColor: "rgba(221, 39, 39, 0.1)",
            "& .MuiChip-deleteIcon": {
            }
        },
        "& .MuiChip-deleteIcon": {
            color: "#DD2727",
            transform: "scaleX(-1)"
        },
        "&:hover": {
            "& .MuiChip-deleteIcon": {
            }
        },
    }
}));

const KPICard = (props) => {
    const classes = useStyles();
    return (
        <BaseCard>
            <div className={classes.root}>

                <Grid container justifyContent={"space-between"}>
                    <Typography variant="h4" color="textSecondary">
                        {props.label}
                    </Typography>
                    <Tooltip placement="top" title={props.changeTooltip}>
                        <Chip
                            clickable={false}
                            className={clsx({
                                [classes.baseChip]: true,
                                [classes.positiveChange]: props.absoluteChange > 0,
                                [classes.negativeChange]: props.absoluteChange < 0
                            })}
                            label={
                                <Typography className={clsx({
                                    [classes.positiveChangeLabel]: props.absoluteChange > 0,
                                    [classes.negativeChangeLabel]: props.absoluteChange < 0
                                })}
                                    variant="subtitle2">{props.percentageChange === 0 ? "-" : props.percentageChange}%</Typography>}
                            deleteIcon={<ShowChartIcon className={classes.chipIcon} fontSize="small" />}
                            onDelete={() => { }}
                        />
                    </Tooltip>
                </Grid>
                <Grid container style={{ paddingBottom: "8px" }}>
                    <Grid item xs={12}>
                        <Typography variant="h1" className={classes.mainValue}>
                            {props.value}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>
                            {props.periodValue} {props.changeLabel}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </BaseCard >
    );

};
export default KPICard;