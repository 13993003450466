import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Container,
    Grid,
    Paper,
} from '@material-ui/core';
import BackButton from '../Buttons/BackButton'
import { useHistory } from 'react-router-dom';

// Components
import Copyright from '../../components/Copyright'
import Loader from '../Loader/Loader';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.light
    }
}));

export default function ItemView(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth={props.width ? props.width : 'md'} className={classes.container}>
                <Grid container spacing={3}>
                    {props.loading
                        ?
                        <Loader />
                        :
                        <Fragment>
                            <Grid item xs={12}>
                                <BackButton
                                    buttonText={props.buttonText}
                                    onClick={() => history.goBack()} />
                            </Grid>
                            <Grid item xs={12} >
                                <Paper elevation={0} className={classes.paper} spacing={2}>
                                    <Grid container spacing={2}>
                                        {props.children}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Fragment>
                    }
                </Grid>
                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
        </main>
    );
}