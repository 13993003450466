import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import { useUser } from '../libs/contextLib';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import {
    Avatar,
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Hidden
} from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "white"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    logoWrapper: {
        flexGrow: 1,
    },
    logo: {
        width: '130px',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    }
}));

export default function Navbar(props) {
    const { logout } = useUser();
    const history = useHistory();
    const classes = useStyles();
    const { open, setOpen } = props;

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <AppBar elevation={0} position="absolute" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Hidden smUp implementation="js">

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <div className={classes.logoWrapper}>
                    <a href="/"><img alt="Logo" className={classes.logo} src={process.env.PUBLIC_URL + '/images/logo.png'} /></a>
                </div>
                {/* <IconButton onClick={() => { window.open("https://buildsight.stonly.com/kb/sv/") }} color="inherit">
                    <HelpIcon style={{color: "#A5A5A5"}}/>
                </IconButton> */}
                <IconButton onClick={handleClick} color="inherit">
                    <Avatar>{/*user && user['attributes']*/}</Avatar>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => {
                        handleClose()
                        history.push("/settings")
                    }
                    }>{t("navbar.settings")}</MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        logout()
                    }
                    }>{t("navbar.logout")}</MenuItem>
                </Menu>

            </Toolbar>
        </AppBar>
    );
}