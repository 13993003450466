import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import Cache from 'i18next-localstorage-cache';

i18n
    .use(backend)
    .use(detector)
    .use(initReactI18next)
    .use(Cache)
    .init({
        fallbackLng: ["sv", "en"],
        nonExplicitSupportedLngs: true,
        interpolation: {
        escapeValue: false,
        backend: {
            loadPath: '/translations/{{lng}}.json',
            allowMultiLoading: true
          },
          react: {
            wait: true,
            useSuspense: true
          },
          Cache: {
            prefix: 'translation_',
            expirationTime: Infinity,
            Version: {},
          },
    }
  });

  export default i18n;