import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Grid,
} from "@material-ui/core";

// Material ui icons
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "../Snackbar";

import { useHistory } from "react-router-dom";
import { useUser } from "../../libs/contextLib";

// Components
import ToolDetailsTab from "./ToolDetailsTab";
import {
  ReformatDateTime,
  ReformatToolStatus,
  ReformatDownloadLink,
} from "../../libs/formattingLib";

import ItemView from "../../UI/Views/ItemView";
import Table from "../../UI/Table/Table";
import RepairDialog from "./RepairDialog";
import DeliverDialog from "./DeliverDialog";
import { TitleComponent } from "../Title";

import { LIST_TOOLS, GET_TOOL } from "../../graphql/queries";
import { REMOVE_TOOL } from "../../graphql/mutations";

import { useParams } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  checkoutButton: {
    color: "#000",
    backgroundColor: theme.palette.primary.main,
  },
  returnButton: {
    color: "#000",
    backgroundColor: theme.palette.primary.main,
  },
  description: {
    fontSize: "14px",
  },
  gridMargin: {
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  headline: {
    fontSize: "1.15rem",
    fontWeight: "400",
    marginBottom: "20px",
  },
  subHeadline: {
    fontSize: "1.0rem",
    fontWeight: "400",
    color: "grey",
  },
  image: {
    width: "300px",
    height: "200px",
    objectFit: "cover",
    position: "relative",
    // background: '#FFF',
    // zIndex: '-1',
    "&:hover": {
      cursor: "pointer",
      // border: '2px solid #e8cc47',
      // backgroundColor: '#000',
      // background: 'rgba(0, 255, 255, 0.5)',
      opacity: "0.5",
    },
  },

  imageFull: {
    width: "100%",
    marginTop: "55px",
  },
  itemSection: {
    marginTop: "10px",
  },
}));

export default function Tool(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();

  const [snackbar, setSnackbar] = useState(false);

  const [repairDialogOpen, setRepairDialogOpen] = useState(false);
  const [deliverDialogOpen, setDeliverDialogOpen] = useState(false);

  const [transactionText, setTransactionText] = useState("");
  const [severity, setSeverity] = useState("success");

  // Table head cells
  const checkoutsHeadCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "user",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.currentCheckoutUser`
      ),
      options: {
        sort: true,
        filterType: "multiselect",
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 =
              obj1?.data?.firstName && obj1?.data?.lastName
                ? `${obj1.data?.firstName} ${obj1.data?.lastName}`
                : " ";
            let val2 =
              obj2?.data?.firstName && obj2?.data?.lastName
                ? `${obj2.data?.firstName} ${obj2.data?.lastName}`
                : " ";
            return (
              (typeof val1.localeCompare === "function"
                ? val1.localeCompare(val2)
                : val1 - val2) * (order === "asc" ? 1 : -1)
            );
          };
        },
        customFilterListOptions: {
          render: ({ value }) => {
            return value ? value : "Ej utcheckad";
          },
        },
        filterOptions: {
          renderValue: (value) => {
            return value ? value : "Ej utcheckad";
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.firstName && value?.lastName
            ? `${value.firstName} ${value.lastName}`
            : "";
        },
      },
    },
    {
      name: "returnDate",
      label: t(
        `tool.checkouts.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.returnDate`
      ),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
    {
      name: "checkoutDate",
      label: t(
        `tool.checkouts.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.checkoutDate`
      ),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
  ];

  const documentsHeadCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "name",
      label: t("documents.table.header.name"),
    },
    {
      name: "type",
      label: t("documents.table.header.type"),
    },
    {
      name: "createdDate",
      label: t("documents.table.header.createdDate"),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
    {
      name: "documentSignedUrl",
      label: t("documents.table.header.url"),
    },
  ];

  let { toolId } = useParams();

  // Tabls
  const [tab, setTab] = useState(0);

  const [open, setOpen] = React.useState(false);

  const updateCache = (cache, { data }) => {
    // Fetch the todos from the cache
    try {
      const existingTools = cache.readQuery({
        query: LIST_TOOLS,
      });

      const tools = existingTools.tools.filter(
        (t) => t.id !== data.removeTool.id
      );

      cache.writeQuery({
        query: LIST_TOOLS,
        data: { tools: tools },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const { loading, error, data } = useQuery(GET_TOOL, {
    variables: { toolId: toolId },
    fetchPolicy: "network-only",
  });

  const [removeTool, { loading: removeLoading }] = useMutation(REMOVE_TOOL, {
    update: updateCache,
    onCompleted: () => history.push("/tools"),
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSeverity("error");
      setSnackbar(true);
    },
  });

  const handleRemove = (toolId) => {
    removeTool({ variables: { toolId: toolId } });
  };

  return (
    <Fragment>
      <TitleComponent title={t("tool.title")} />

      <ItemView loading={loading} buttonText={t("tool.backButton.text")}>
        {!error && data?.tool && (
          <Fragment>
            <Grid container>
              <Tabs
                variant="scrollable"
                scrollButtons="off"
                value={tab}
                onChange={(event, newValue) => setTab(newValue)}
                aria-label="Navigation tabs"
              >
                <Tab
                  label={t(
                    `tool.menu${
                      user.accountType ? `_${user.accountType}` : ""
                    }.details`
                  )}
                />
                <Tab
                  label={t(
                    `tool.menu${
                      user.accountType ? `_${user.accountType}` : ""
                    }.documents`
                  )}
                />
                <Tab
                  label={t(
                    `tool.menu${
                      user.accountType ? `_${user.accountType}` : ""
                    }.checkouts`
                  )}
                />
              </Tabs>
            </Grid>

            {tab === 0 && (
              <ToolDetailsTab
                data={data}
                toolId={toolId}
                removeLoading={removeLoading}
                handleRemove={handleRemove}
                setOpen={setOpen}
                setDeliverDialogOpen={setDeliverDialogOpen}
                s
                setRepairDialogOpen={setRepairDialogOpen}
              />
            )}
            {tab === 1 && (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.headline}>
                    {t("tool.document")}
                  </Typography>
                  {data.tool.documents.length > 0 && (
                    <Table
                      headCells={documentsHeadCells}
                      data={data.tool.documents}
                      onClick={() => {}}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {tab === 2 && (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.headline}>
                    {t(
                      `tool.checkouts.title${
                        user.accountType ? `_${user.accountType}` : ""
                      }`
                    )}
                  </Typography>
                  {data.tool.checkouts.length > 0 && (
                    <Table
                      headCells={checkoutsHeadCells}
                      data={data?.tool?.checkouts}
                      onClick={(row) => history.push(`/checkouts/${row[0]}`)}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <Dialog
              name="imageDialog"
              fullScreen
              open={open}
              onClose={() => setOpen(false)}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpen(false)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    onClick={() => setOpen(false)}
                    variant="h6"
                    className={classes.title}
                  >
                    {t("tool.imageDialog.close")}
                  </Typography>
                </Toolbar>
              </AppBar>
              <img
                alt="Tool"
                src={data.tool.imageSignedUrl}
                className={classes.imageFull}
              />
            </Dialog>
            <Snackbar
              setOpen={setSnackbar}
              open={snackbar}
              text={transactionText}
              severity={severity}
            />
            <RepairDialog
              setOpen={setRepairDialogOpen}
              data={data}
              open={repairDialogOpen}
            />
            <DeliverDialog
              setOpen={setDeliverDialogOpen}
              data={data}
              open={deliverDialogOpen}
            />
          </Fragment>
        )}
      </ItemView>
    </Fragment>
  );
}
