import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Material ui
import {
    CircularProgress
}
    from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        margin: 'auto',
        padding: '50px 0 0 0'
    },
    circularProgress: {
        color: '#EFC92A'
    }
}));

export default function Func() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
}