import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Tab,
    Tabs,
    Typography,
    Grid,
} from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';


import ItemView from '../../UI/Views/ItemView'
import ContactInformationBox from '../../UI/ContactInformationBox'

import { TitleComponent } from '../Title';

import { Map, Marker } from 'pigeon-maps'

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../libs/contextLib';

import { GET_CHECKOUT } from '../../graphql/queries'

// Moment
import moment from 'moment';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    menu: {
        paddingBottom: theme.spacing(4)
    },
    description: {
        fontSize: '14px',
    },
    headline: {
        fontSize: '1.15rem',
        fontWeight: '400',
        marginBottom: '20px',
    },
    subHeadline: {
        fontSize: '1.0rem',
        fontWeight: '400',
        color: 'grey'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 340,
    },
    image: {
        width: 80,
        marginTop: '5px',
    },
    itemSection: {
        marginTop: "10px"
    },
    codeLink: {
        textDecoration: 'none',
        color: "#000"
    }
}));

export default function Checkout(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState(0)
    const { user } = useUser();


    let { checkoutId } = useParams();

    const { loading, error, data } = useQuery(GET_CHECKOUT, {
        variables: { "checkoutId": checkoutId },
        fetchPolicy: "no-cache"
    });

    const stamenTerrain = (x, y, z, dpr) => {
        return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.jpg`
    }

    return (
        <Fragment>
            <TitleComponent title={t("checkout.title", { context: user.accountType })} />
            <ItemView loading={loading} buttonText={t("checkout.backButton.text")}>
                {data && !error && data.checkout &&
                    <Fragment>
                        <Grid container>
                            <Tabs
                                value={tab}
                                onChange={(event, newValue) => setTab(newValue)}
                                aria-label="Navigation tabs"
                            >
                                <Tab label={t("tool.menu.details")} />
                            </Tabs>
                        </Grid>

                        <Grid container item xs={6} >
                            <Grid container item xs={12}>
                                <Grid item sm={12}>
                                    {"tool" in data.checkout &&
                                        <Fragment>
                                            <Link to={"/tools/" + data.checkout.tool.id} className={classes.codeLink}>
                                                <Typography className={classes.headline}>{data.checkout.tool.model}</Typography>
                                            </Link>
                                            <Typography className={classes.description}>{data.checkout.tool.code}</Typography>
                                            <Typography className={classes.description}>{data.checkout.tool.other}</Typography>
                                        </Fragment>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} className={classes.itemSection}>
                                <Grid item sm={6}>
                                    <Typography className={classes.subHeadline}>{t("checkout.checkoutDate", { context: user.accountType })}</Typography>
                                    <Typography className={classes.description}>{moment.utc(data.checkout.checkoutDate).local().format('YYYY-MM-DD HH:mm')}</Typography>
                                </Grid>
                                {data.checkout.returnDate && <Grid item sm={6}>
                                    <Typography className={classes.subHeadline}>{t("checkout.returnDate")}</Typography>
                                    <Typography className={classes.description}>{data.checkout.returnDate ? moment.utc(data.checkout.returnDate).local().format('YYYY-MM-DD HH:mm') : ""}</Typography>
                                </Grid>}
                            </Grid>
                            <Grid container item xs={12} spacing={2} className={classes.itemSection}>
                                {data.checkout.marking && <Grid item sm={6}>
                                    <Typography className={classes.subHeadline}>{t("checkout.marking")}</Typography>
                                    <Typography className={classes.description}>{data.checkout.marking}</Typography>
                                </Grid>}
                                <Grid item sm={6}>
                                    <Typography className={classes.subHeadline}>{t("checkout.user")}</Typography>
                                    <ContactInformationBox user={data.checkout.user} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item sm={6} >
                            <Grid item xs={12}>

                                {data.checkout.lat && data.checkout.long ?
                                    <Fragment>
                                        <Typography className={classes.subHeadline}>{t("checkout.address")}</Typography>
                                        <p>{data.checkout.address}</p>
                                        <Map
                                            provider={stamenTerrain}
                                            defaultCenter={[data.checkout.lat, data.checkout.long]}
                                            defaultZoom={14}
                                            height={400}>
                                            <Marker anchor={[data.checkout.lat, data.checkout.long]} payload={1} onClick={({ event, anchor, payload }) => { }} />
                                        </Map>
                                    </Fragment>
                                    :
                                    <Typography className={classes.subHeadline}>{t("checkout.address_not_available", { context: user.accountType })}</Typography>
                                }
                                {data.checkout.image &&
                                    <Fragment>
                                        <Typography className={classes.description}>{t("checkout.image")}</Typography>
                                        <img alt={t("checkout.image")} src={data.checkout.image} className={classes.image} />
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Fragment>
                }
            </ItemView>
        </Fragment>

    );
}