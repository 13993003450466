import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

// Material ui
import {
    Card,
    CardContent,
    Typography,
    Grid
} from '@material-ui/core';

// Material ui icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '10px',
        border: '2px solid #E5E8ED',
        '&:hover': {
            backgroundColor: '#f6ebb5',
            cursor: 'pointer',
            border: '2px solid #e8cc47',
        }

    },
    cardChecked: {
        backgroundColor: '#f6ebb5',
        border: '2px solid #E8CC47'
    },
    currency: {
        fontSize: 18,
        fontWeight: 600,
        display: 'flex',
        marginRight: '2px',
        color: 'black',

    },
    forms: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6)
    },
    planSection: {
        marginTop: theme.spacing(2),
    },
    frequency: {
        fontSize: 16,
        marginTop: '3px',
        letterSpacing: '1px',
        color: 'black',

    },
    Grid: {
        padding: '10px 0px 10px 0px',
    },
    IconGrid: {
        marginTop: '10px',
        textAlign: 'center',
    },
    iconNotSelected: {
        color: '#E5E8ED',
    },
    title: {
        fontSize: 16,
        textTransform: 'uppercase',
        fontWeight: 600,
        letterSpacing: '1px',
        paddingTop: '8px',
        color: 'black',
    }
}));

export default function SelectPlanPane(props) {
    const classes = useStyles();
    const { t } = useTranslation();


    return (
        <Grid container item xs={12} md={6} className={classes.forms} alignContent={"flex-start"} >
            <Grid item xs={12}>
                <img alt="Logo" src={process.env.PUBLIC_URL + '/images/logo.png'} width="160" />
            </Grid>
            <Grid className={classes.planSection} item xs={12} >
                <Typography variant="h4" className={classes.title}>
                    {t("signup.paymentForm.selectPlan")}
                </Typography>
            </Grid>
            <Grid className={classes.Grid} item xs={12}>
                <Card variant="outlined" className={props.plan === 1 ? [classes.card, classes.cardChecked].join(' ') : classes.card} onClick={() => props.onClick(1)}>
                    <CardContent>
                        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
                            <Grid item xs={2} className={classes.IconGrid}>
                                {props.plan === 1 ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon className={classes.iconNotSelected} />}

                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.title} color="textSecondary" >
                                    {t("signup.paymentForm.plans.plan1.title")}
                                </Typography>
                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                    {t("signup.paymentForm.plans.plan1.text")}

                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.currency} color="textSecondary" gutterBottom>
                                    {t("signup.paymentForm.plans.plan1.prince")}
                                    <Typography component={'span'} className={classes.frequency} color="textSecondary">  {t("signup.paymentForm.plans.plan1.frequency")}</Typography>
                                </Typography>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}