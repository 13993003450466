import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import QRDialog from "../QRDialog";
import { useUser } from "../../libs/contextLib";
import Snackbar from "../Snackbar";
import ReturnToolDialog from "../ReturnToolDialog";
import { useForm } from "react-hook-form";
import Loader from "../../UI/Loader/Loader";
import { useHistory, useParams } from "react-router-dom";
import Table from "../../UI/Table/Table";
import CheckoutToolButton from "../CheckoutToolButton";
import CheckoutToolDialog from "../CheckoutToolDialog";
import { ActivityFeed } from "./ActivityFeed";
import BarChartCard from "./BarChartCard";
import BaseCard from "../../UI/Cards/BaseCard";
import { ReformatDateTime } from "../../libs/formattingLib";

import { TitleComponent } from "../Title";
import KPICard from "../../UI/Cards/KPICard";
// Analytics
import analytics from "../../analytics";

import ReturnToolButton from "../ReturnToolButton";

// Lodash
import * as _ from "lodash";

// Mobile detect
import { isMobile } from "react-device-detect";

// Moment
import moment from "moment";

// Query
import { useLazyQuery } from "@apollo/client";

// Routes
import { useQuery, useMutation } from "@apollo/client";

import { CHECKOUT_TOOL, RETURN_TOOL } from "../../graphql/mutations";
import {
  LIST_USER_CHECKOUTS,
  GET_TOOL_CHECKOUT_STATUS_BY_CODE,
  DASHBOARD_PAGE,
} from "../../graphql/queries";

import { Container, Grid, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import Copyright from "../Copyright";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: "relative",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  headline: {
    fontSize: "1.15rem",
    fontWeight: "400",
    marginTop: "20px",
  },
  gridHeadline: {
    marginBottom: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  checkoutButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    fontSize: "10px",
    fontWeight: 600,
  },
  returnButton: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    fontSize: "10px",
    fontWeight: 600,
  },
  copyright: {
    position: "fixed",
    bottom: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, userGroups } = useUser();
  const history = useHistory();

  const headCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "tool.code",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.code`
      ),
    },
    {
      name: "tool.model",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.model`
      ),
    },
    {
      name: "tool.manufacturer.name",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.manufacturer`
      ),
    },
    {
      name: "checkoutDate",
      label: t(
        `dashboard.table${
          user.accountType ? `_${user.accountType}` : ""
        }.header.checkoutDate`
      ),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
  ];

  const [checkoutTool, setCheckoutTool] = useState(false);
  const [returnTool, setReturnTool] = useState(false);
  const [code, setCode] = useState();

  const [returnToolDialog, setReturnToolDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [transactionText, setTransactionText] = useState("");
  const [severity, setSeverity] = useState("success");
  const { control, register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  // Automatic checkout
  const [checkoutToolDialog, setCheckoutToolDialog] = useState(false);
  const [manualCode, setManualCode] = useState();

  // Loading full screen
  const [isLoading, setIsLoading] = useState(false);

  // Path parameters
  const { QRcode } = useParams();

  const onSubmitCheckout = async (data) => {
    setManualCode(false);
    setCheckoutToolDialog(false);
    setLoading(true);
    let latitude = null;
    let longitude = null;

    if ("geolocation" in navigator) {
      try {
        const position = await getCoordinates();
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      } catch (e) {
        console.log(
          "Location information disabled / not available, permission granted, OS issue"
        );
      }
    } else {
      console.log(
        "Location information disabled / not available, no permission granted"
      );
    }

    analytics.track("checkoutTool", {
      code: code,
      userId: user["username"],
      marking: data.marking,
    });

    updateCheckoutTool({
      variables: {
        code: code,
        userId: user["username"],
        marking: data.marking,
        lat: latitude,
        lng: longitude,
      },
    });
  };

  const onSubmitReturn = handleSubmit(async (data) => {
    setReturnToolDialog(false);
    setCheckoutToolDialog(false);
    setLoading(true);
    let latitude = null;
    let longitude = null;

    if ("geolocation" in navigator) {
      try {
        const position = await getCoordinates();
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      } catch (e) {
        console.log(
          "Location information disabled / not available, permission granted, OS issue"
        );
      }
    } else {
      console.log(
        "Location information disabled / not available, no permission granted"
      );
    }

    try {
      updateReturnTool({
        variables: {
          code: code,
          userId: user["username"],
          isComplete: data.isComplete,
          isWorking: data.isWorking,
          isCleaned: data.isCleaned,
          lat: latitude,
          lng: longitude,
        },
      });
    } catch (e) {
      console.log("e :", e);
    }
  });

  const [updateCheckoutTool] = useMutation(CHECKOUT_TOOL, {
    refetchQueries: [
      {
        query: LIST_USER_CHECKOUTS,
        variables: { userId: user["username"], status: "CHECKOUT" },
      },
      // { query: LIST_USERS },
    ],
    onCompleted: () => {
      setTransactionText(t("dashboard.checkoutTool.snackbarText.success"));
      setOpen(true);
      setSeverity("success");
      setLoading(false);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setOpen(true);
      setSeverity("error");
      setLoading(false);
    },
  });

  const [updateReturnTool] = useMutation(RETURN_TOOL, {
    refetchQueries: [
      {
        query: LIST_USER_CHECKOUTS,
        variables: { userId: user["username"], status: "CHECKOUT" },
      },
      // { query: LIST_USERS },
    ],
    onCompleted: () => {
      setTransactionText(t("dashboard.returnTool.snackbarText.success"));
      setOpen(true);
      setSeverity("success");
      setLoading(false);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setOpen(true);
      setSeverity("error");
      setLoading(false);
    },
  });

  const handleClose = () => {
    setReturnTool(false);
    setCheckoutTool(false);
  };

  const getCoordinates = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
        });
      }, 0);
    });
  };

  const handleScan = async (scannedId) => {
    if (!scannedId) {
      return;
    }
    handleClose();
    if (scannedId) {
      let latitude = null;
      let longitude = null;

      if (returnTool) {
        setCode(scannedId);
        setReturnToolDialog(true);
      } else if (checkoutTool) {
        setLoading(true);
        // FIXME, duplication here...
        if ("geolocation" in navigator) {
          try {
            const position = await getCoordinates();
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
          } catch (e) {
            console.log(
              "Location information disabled / not available, permission granted, OS issue"
            );
          }
        } else {
          console.log(
            "Location information disabled / not available, no permission granted"
          );
        }

        try {
          await updateCheckoutTool({
            variables: {
              code: scannedId,
              userId: user["username"],
              lat: latitude,
              lng: longitude,
            },
          });
        } catch (e) {
          console.log("e :", e);
        }
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleCheckoutClick = (data) => {
    history.push(`/checkouts/${data[0]}`);
  };

  const currentPeriodStart = moment()
    .subtract({ days: 7 })
    .local()
    .startOf("day")
    .toISOString();
  const currentPeriodEnd = moment().local().startOf("day").toISOString();
  const previousPeriodStart = moment()
    .subtract({ days: 14 })
    .local()
    .startOf("day")
    .toISOString();
  const previousPeriodEnd = moment()
    .subtract({ days: 7 })
    .local()
    .startOf("day")
    .toISOString();

  const { data, loading: loadingData } = useQuery(DASHBOARD_PAGE, {
    variables: {
      userId: user["username"],
      status: "CHECKOUT",
      periodInDays: 7,
      currentPeriodStart: currentPeriodStart,
      currentPeriodEnd: currentPeriodEnd,
      previousPeriodStart: previousPeriodStart,
      previousPeriodEnd: previousPeriodEnd,
    },
    fetchPolicy: "network-only",
  });

  const [getToolCheckoutStatusByCode] = useLazyQuery(
    GET_TOOL_CHECKOUT_STATUS_BY_CODE,
    {
      fetchPolicy: "network-only",
      onCompleted: (toolCheckoutStatus) => {
        if (
          toolCheckoutStatus.toolByCode &&
          toolCheckoutStatus.toolByCode.status === "CHECKOUT"
        ) {
          // Return tool
          setIsLoading(false);
          setReturnToolDialog(true);
        } else if (
          toolCheckoutStatus.toolByCode &&
          toolCheckoutStatus.toolByCode.status === "AVAILABLE"
        ) {
          // Checkout tool
          setIsLoading(false);
          // checkoutToolDialog
          setCheckoutToolDialog(true);
        } else {
          setOpen(true);
          setSeverity("error");
          setTransactionText(t("dashboard.scanQR.snackbarText.noTool"));
        }
      },
      onError: (error) => {
        if (
          error.graphQLErrors.length > 0 &&
          "extensions" in error.graphQLErrors[0] &&
          "code" in error.graphQLErrors[0].extensions
        ) {
          setTransactionText(
            t(`errors.${error.graphQLErrors[0].extensions.code}`)
          );
        } else {
          setTransactionText(t("errors.UNKNOWN_ERROR"));
        }
        setOpen(true);
        setSeverity("error");
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    let isMounted = true; // note this flag denote mount status
    if (QRcode && isMounted) {
      setIsLoading(true);
      setCode(QRcode);

      // Fetch tool
      getToolCheckoutStatusByCode({
        variables: {
          code: QRcode,
        },
      });
    }
    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [QRcode]);

  return (
    <Fragment>
      <TitleComponent title={t("dashboard.title")} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="center"
            >
              {loading || isLoading || loadingData ? (
                <Loader />
              ) : (
                <Fragment>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{ marginBottom: "20px" }}
                    spacing={4}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h1" style={{ marginBottom: "8px" }}>
                        {t("dashboard.welcome.title")} {data?.user?.firstName}{" "}
                        {data?.user?.lastName}
                      </Typography>
                      <Typography variant="h3">
                        {t("dashboard.welcome.text")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs sm={6}>
                          <CheckoutToolButton
                            fullWidth
                            id="checkout_tool_button"
                            showLink={true}
                            loaderColor="#FFF"
                            className={classes.checkoutButton}
                            variant="contained"
                            color="primary"
                          />
                        </Grid>
                        <Grid item xs sm={6}>
                          <ReturnToolButton
                            className={classes.returnButton}
                            id="return_tool_button"
                            showLink={true}
                            color="secondary"
                            fullWidth
                            loaderColor="#FFF"
                            variant="contained"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid
                      item
                      md={userGroups?.indexOf("admin") >= 0 ? 9 : 12}
                      style={{
                        maxWidth: "100%",
                      }}
                    >
                      {userGroups?.indexOf("admin") >= 0 && (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginBottom: "30px",
                          }}
                        >
                          <Grid item md={4} xs={12}>
                            <KPICard
                              changeTooltip={
                                data?.userCount?.relativeChange === 0
                                  ? t(
                                      `dashboard.kpicards.users${
                                        user.accountType
                                          ? `_${user.accountType}`
                                          : ""
                                      }.changeTooltipNoGrowth`
                                    )
                                  : t(
                                      `dashboard.kpicards.users${
                                        user.accountType
                                          ? `_${user.accountType}`
                                          : ""
                                      }.changeTooltip`
                                    )
                              }
                              name="users"
                              label={t(
                                `dashboard.kpicards.users${
                                  user.accountType ? `_${user.accountType}` : ""
                                }.label`
                              )}
                              percentageChange={data?.userCount?.relativeChange}
                              value={data?.userCount?.value}
                              periodValue={data?.userCount?.periodValue}
                              absoluteChange={data?.userCount?.absoluteChange}
                              changeLabel={`${t(
                                `dashboard.kpicards.users${
                                  user.accountType ? `_${user.accountType}` : ""
                                }.subtitle`
                              )} ${t("dashboard.kpicards.period.week")}`}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <KPICard
                              changeTooltip={
                                data?.toolCount?.relativeChange === 0
                                  ? t(
                                      "dashboard.kpicards.tools.changeTooltipNoGrowth"
                                    )
                                  : t("dashboard.kpicards.tools.changeTooltip")
                              }
                              name="tools"
                              label={t("dashboard.kpicards.tools.label")}
                              percentageChange={data?.toolCount?.relativeChange}
                              value={data?.toolCount?.value}
                              periodValue={data?.toolCount?.periodValue}
                              absoluteChange={data?.toolCount?.absoluteChange}
                              changeLabel={`${t(
                                "dashboard.kpicards.tools.subtitle"
                              )} ${t("dashboard.kpicards.period.week")}`}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <KPICard
                              changeTooltip={
                                data?.checkoutCount?.relativeChange === 0
                                  ? t(
                                      "dashboard.kpicards.checkouts.changeTooltipNoGrowth"
                                    )
                                  : t(
                                      "dashboard.kpicards.checkouts.changeTooltip"
                                    )
                              }
                              name="checkouts"
                              label={t(
                                `dashboard.kpicards.checkouts${
                                  user.accountType ? `_${user.accountType}` : ""
                                }.label`
                              )}
                              percentageChange={
                                data?.checkoutCount?.relativeChange
                              }
                              value={data?.checkoutCount?.value}
                              periodValue={data?.checkoutCount?.periodValue}
                              absoluteChange={
                                data?.checkoutCount?.absoluteChange
                              }
                              changeLabel={`${t(
                                `dashboard.kpicards.checkouts${
                                  user.accountType ? `_${user.accountType}` : ""
                                }.subtitle`
                              )} ${t("dashboard.kpicards.period.week")}`}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {userGroups?.indexOf("admin") >= 0 && (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginBottom: "30px",
                          }}
                        >
                          <Grid container item sm={12}>
                            <BarChartCard
                              t={t}
                              title={t(
                                `dashboard.chart${
                                  user.accountType ? `_${user.accountType}` : ""
                                }.title`
                              )}
                              legendText={t(
                                `dashboard.chart${
                                  user.accountType ? `_${user.accountType}` : ""
                                }.name`
                              )}
                              data={
                                Object.entries(
                                  data?.currentCheckouts
                                    .map(({ checkoutDate }) => {
                                      return moment(checkoutDate).format(
                                        "YYYY-MM-DD"
                                      );
                                    })
                                    .reduce((a, c) => {
                                      return (a[c] = (a[c] || 0) + 1), a;
                                    }, {})
                                )
                                  .map((item) => ({
                                    date: item[0],
                                    value: item[1],
                                  }))
                                  .sort((a, b) => {
                                    let keyA = new Date(a.date),
                                      keyB = new Date(b.date);
                                    if (keyA < keyB) return -1;
                                    if (keyA > keyB) return 1;
                                    return 0;
                                  }) ?? []
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={2}>
                        <Grid container item sm={12}>
                          <BaseCard style={{ width: "100%" }}>
                            <Grid item xs={12}>
                              <Typography variant="h2">
                                {t(
                                  `dashboard.table${
                                    user.accountType
                                      ? `_${user.accountType}`
                                      : ""
                                  }.title`
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {data?.userCheckouts?.length > 0 ? (
                                <Table
                                  storageKey="bsDashboardCheckoutsTable"
                                  headCells={headCells}
                                  data={data?.userCheckouts}
                                  onClick={handleCheckoutClick}
                                />
                              ) : (
                                t(
                                  `dashboard.table${
                                    user?.accountType
                                      ? `_${user.accountType}`
                                      : ""
                                  }.noCheckouts`
                                )
                              )}
                            </Grid>
                          </BaseCard>
                        </Grid>
                      </Grid>
                    </Grid>
                    {userGroups?.indexOf("admin") >= 0 && (
                      <Grid item md={3}>
                        <Grid container item spacing={2}>
                          <Grid item sm={12}>
                            <ActivityFeed
                              history={history}
                              t={t}
                              activities={data?.activities}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={3}></Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>

          <CheckoutToolDialog
            setOpen={setCheckoutToolDialog}
            open={checkoutToolDialog}
            onSubmit={onSubmitCheckout}
            handleSubmit={handleSubmit}
            control={control}
            setManualCode={setManualCode}
            manualCode={manualCode}
            errors={errors}
            register={register}
          />

          <QRDialog
            open={checkoutTool || returnTool}
            handleScan={handleScan}
            handleClose={handleClose}
            handleError={handleError}
          />
          <ReturnToolDialog
            setOpen={setReturnToolDialog}
            open={returnToolDialog}
            onSubmit={onSubmitReturn}
            handleSubmit={handleSubmit}
            setManualCode={setManualCode}
            manualCode={manualCode}
            control={control}
            errors={errors}
            register={register}
          />
          <Snackbar
            open={open}
            setOpen={setOpen}
            text={transactionText}
            severity={severity}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
          ></Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Copyright />
            </Grid>
          </Grid>
        </Container>
      </main>
    </Fragment>
  );
}
