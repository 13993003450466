import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Tab,
    Tabs,
    Typography,
    Grid,
} from '@material-ui/core';
import { ReformatDateTime, ReformatReportType } from '../../libs/formattingLib';

import { useUser } from '../../libs/contextLib';
import { useHistory } from 'react-router-dom';

// Components
import DeleteButton from '../../UI/Buttons/DeleteButton';
import ItemView from '../../UI/Views/ItemView'
import { TitleComponent } from '../Title';

import { LIST_REPORTS, GET_REPORT } from '../../graphql/queries'
import { REMOVE_REPORT } from '../../graphql/mutations'

import {
    useParams
} from "react-router-dom";

import { useQuery, useMutation } from '@apollo/client';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    checkoutButton: {
        color: "#000",
        backgroundColor: theme.palette.primary.main
    },
    returnButton: {
        color: "#000",
        backgroundColor: theme.palette.primary.main
    },
    description: {
        fontSize: '14px',
    },
    headline: {
        fontSize: '1.15rem',
        fontWeight: '400',
        marginBottom: '20px',
    },
    subHeadline: {
        fontSize: '1.0rem',
        fontWeight: '400',
        color: 'grey'
    },
    image: {
        width: "100%",
        marginTop: '5px',
    },
    itemSection: {
        marginTop: "10px"
    }
}));

export default function Report(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useUser();
    const history = useHistory();

    let { reportId } = useParams();

    // Tabls
    const [tab, setTab] = useState(0)

    const updateCache = (cache, { data }) => {
        // Fetch the todos from the cache
        try {
            const existingReports = cache.readQuery({
                query: LIST_REPORTS
            });

            const reports = existingReports.reports.filter((t) => t.id !== data.removeReport.id)

            cache.writeQuery({
                query: LIST_REPORTS,
                data: { reports: reports },
            });
        } catch (err) {
            console.log(err)
        }
    };

    const { loading, error, data } = useQuery(GET_REPORT, {
        variables: { "reportId": reportId },
        fetchPolicy: "network-only"
    });

    const [removeReport, { loading: removeLoading }] = useMutation(REMOVE_REPORT, {
        update: updateCache,
        onCompleted: () => history.push("/reports")
    });

    const handleRemove = (reportId) => {
        removeReport({ variables: { "reportId": reportId } });
    }

    return (
        <Fragment>
            <TitleComponent title={t("report.title")} />
            <ItemView loading={loading} buttonText={t("report.backButton.text")}>
                {data && !error && data.report &&
                    <Fragment>
                        <Grid container >
                            <Tabs
                                variant="scrollable"
                                scrollButtons="off"
                                value={tab}
                                onChange={(event, newValue) => setTab(newValue)}
                                aria-label="Navigation tabs"
                            >
                                <Tab label={t("report.menu.details")} />
                            </Tabs>
                        </Grid>

                        {tab === 0 &&

                            <Fragment>

                                <Grid container item xs={6} >
                                    <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.headline}>{data.report.name}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} className={classes.itemSection}>
                                        <Grid item sm={6}>
                                            <Typography className={classes.subHeadline}>{t("report.reportType.label")}</Typography>
                                            <Typography className={classes.description}>{t(`report.reportType.${data.report.reportType}`)}</Typography>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Typography className={classes.subHeadline}>{t("report.createdDate")}</Typography>
                                            <Typography className={classes.description}>{ReformatDateTime(data.report.createdDate)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} className={classes.itemSection}>
                                        <Grid item sm={6}>
                                            <Typography className={classes.subHeadline}>{t("report.status")}</Typography>
                                            <Typography className={classes.description}>{data.report.status}</Typography>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Typography className={classes.subHeadline}>{t("report.runDate")}</Typography>
                                            <Typography className={classes.description}>{data.report.runDate}</Typography>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Typography className={classes.subHeadline}>{t("report.frequency")}</Typography>
                                            <Typography className={classes.description}>{data.report.frequency}</Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} >
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item sm={4}> 
                                    </Grid>
                                    <Grid item sm={4}>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <DeleteButton
                                            variant="contained"
                                            color="primary"
                                            isLoading={removeLoading}
                                            onClick={() => handleRemove(reportId)}
                                        >
                                            {t('report.delete.buttonText')}
                                        </DeleteButton>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        }
                    </Fragment>
                }
            </ItemView>
        </Fragment>
    );
}