import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Material ui
import {
    Box,
    Tooltip,
    Typography
}
    from '@material-ui/core/';

// Material ui icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Material ui styles
import { withStyles } from '@material-ui/core/styles';

// Custom tooltip
const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "0.8rem",
    }
})(Tooltip);


const useStyles = makeStyles((theme) => ({
    box: {
        marginBottom: "5px"
    },
    icon: {
        marginBottom: "-4px",
        marginLeft: "5px",
        fontSize: "1.1rem"
    },
    typography: {
        display: "block"
    }
}));


export default function TypographyWithTooltip(props) {
    const { instructions, variant, className } = props;
    const classes = useStyles();


    return (
        <Box className={classes.box}>
            <Typography className={[classes.typography, className].join(' ')} variant={variant}>
                {props.children}
                {instructions &&
                    <CustomTooltip
                        placement="top"
                        title={instructions}>
                        <InfoOutlinedIcon
                            className={classes.icon}
                        />
                    </CustomTooltip>
                }
            </Typography>
        </Box>

    );
}