import React, { Fragment } from 'react';
import StopIcon from '@material-ui/icons/Stop';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import i18n from 'i18next';
import { useUser } from '../libs/contextLib';

import {
    IconButton,
} from '@material-ui/core';

// Moment
import moment from 'moment';
import 'moment/locale/sv'
import 'moment/locale/ru'
import 'moment/locale/pl'


/* Binding will default to bind the first argument */
export function ReformatToolStatus(t, value, accountType) {
    return <Fragment><StopIcon fontSize="small" style={{ color: t(`checkoutsMapping.colors.${value}`), marginLeft: '-5px', marginBottom: '-5px' }} /> {t(`checkoutsMapping.names${accountType ? `_${accountType}` : ''}.${value}`)} </Fragment>
}

export function ReformatDateTime(value) {
    if (value) {
        return moment.utc(value).local().format('YYYY-MM-DD HH:mm')
    } else {
        return value
    }
}

export function ReformatDate(value) {
    if (value) {
        return moment.utc(value).local().format('YYYY-MM-DD')
    } else {
        return value
    }
}
export function ReformatTime(value) {
    if (value) {
        return moment.utc(value).local().format('HH:mm')
    } else {
        return value
    }
}

export function ReformatDateTimePassed(t, value) {
    if (value) {
        /* * -1 since it's negative time has passed. */
        const minutes_since = moment.utc(value).local().diff(moment(), 'minutes') * -1
        /* X min ago */
        if (minutes_since < 60) {
            return `${moment.utc(value).local().diff(moment(), 'minutes') * -1} ${t('dateTime.timeMinutesPassed')}`
        /* X hrs ago */
        } else if (minutes_since > 60 && minutes_since < moment().local().startOf('day').diff(moment(), 'minutes') * -1) {
            return `${moment.utc(value).local().diff(moment(), 'hours') * -1} ${t('dateTime.timeHoursPassed')}`
        /* Yesterday */
        } else if (minutes_since > moment().local().startOf('day').diff(moment(), 'minutes') && minutes_since < moment().local().startOf('day').diff(moment().subtract(1, 'd').startOf('day'), 'minutes')) {
            return `${t('dateTime.yesterday')}`
        /* Date */
        } else {
            return moment.utc(value).local().format('YYYY-MM-DD')
        }
    } else {
        return value
    }
}

export function ReformatDateShortMonthName(value) {
    if (value) {
        moment.locale(getLanguage().slice(0, 2))
        return moment.utc(value).local().format('MMM')
    } else {
        return value
    }
}


export function ReformatDateShortDayName(value) {
    if (value) {
        moment.locale(getLanguage().slice(0, 2))
        return capitalizeFirstLetter(moment.utc(value).local().format('ddd'))
    } else {
        return value || ""
    }
}

export function ReformatDateFullDayName(value) {
    if (value) {
        moment.locale(getLanguage().slice(0, 2))
        return capitalizeFirstLetter(moment.utc(value).local().format('dddd'))
    } else {
        return value
    }
}

export function ReformatUserType(t, value, accountType) {
    if (value === "admin") {
        return t("users.admin")
    } else {
        return t("users.user", { context: accountType })
    }
}

export function ReformatDownloadLink(value) {
    return (<IconButton onClick={(event) => {
        event.stopPropagation();
        window.open(value, '_blank');
    }}>
        <CloudDownloadIcon />
    </IconButton>)
}

export const getLanguage = () => {
    return i18n.language ||
        (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
        'en';
};

export const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}