import React, { Fragment, useState } from "react";

// Hooks
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useUser } from "../../libs/contextLib";
import { TitleComponent } from "../Title";
import {
  ReformatDateTime,
  ReformatDownloadLink,
} from "../../libs/formattingLib";
import TableActionMenu from "../../UI/Table/TableActionMenu";
import BaseCard from "../../UI/Cards/BaseCard";

// Components
import Loader from "../../UI/Loader/Loader";
import Table from "../../UI/Table/Table";
import Snackbar from "../Snackbar";

import { LIST_REPORTS } from "../../graphql/queries";
import { REMOVE_REPORT } from "../../graphql/mutations";

import { makeStyles } from "@material-ui/core/styles";

import { Box, Container, Grid, Button, Typography } from "@material-ui/core";

// Material ui icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Copyright from "../Copyright";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: "relative",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    marginRight: "5px",
  },
}));

export default function Tools() {
  const { user } = useUser();

  const [severity, setSeverity] = useState("");
  const [transactionText, setTransactionText] = useState("");
  const [snackbar, setSnackbar] = useState(false);

  // Tools

  const { loading, data, refetch } = useQuery(LIST_REPORTS, {
    fetchPolicy: "network-only",
  });

  const updateCache = (cache, { data }) => {
    try {
      const existingReports = cache.readQuery({
        query: LIST_REPORTS,
      });

      cache.evict({ id: `Report:${data.removeReport.id}` });

      const reports = existingReports.tools.filter(
        (t) => t.id !== data.removeReport.id
      );
      cache.writeQuery({
        query: LIST_REPORTS,
        data: { reports: reports },
      });
      return reports;
    } catch (err) {
      console.log(err);
    }
  };

  const [removeReport, { loading: removeLoading }] = useMutation(
    REMOVE_REPORT,
    {
      refetchQueries: [{ query: LIST_REPORTS }],
      update: updateCache,
      onCompleted: () => {
        setTransactionText(t("reports.removeReport.success"));
        setSeverity("success");
        setSnackbar(true);
      },
      onError: (error) => {
        if (
          error.graphQLErrors.length > 0 &&
          "extensions" in error.graphQLErrors[0] &&
          "code" in error.graphQLErrors[0].extensions
        ) {
          setTransactionText(
            t(`errors.${error.graphQLErrors[0].extensions.code}`)
          );
        } else {
          setTransactionText(t("errors.UNKNOWN_ERROR"));
        }
        setSeverity("error");
        setSnackbar(true);
      },
    }
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleRemove = (reportId) => {
    removeReport({ variables: { reportId: reportId } });
  };

  const headCells = [
    { name: "id", options: { display: "excluded", filter: false } },

    {
      name: "name",
      label: t("reports.table.header.name"),
      options: {
        filterType: "textField",
      },
    },
    {
      name: "createdDate",
      label: t("reports.table.header.createdDate"),
      options: { filter: false, customBodyRender: ReformatDateTime },
    },
    {
      name: "reportType",
      label: t("reports.table.header.reportType"),
      options: {
        filterType: "dropdown",
        customBodyRender: (value) => t(`reports.reportType.${value}`),
      },
    },
    {
      name: "status",
      label: t("reports.table.header.status"),
      options: {
        filter: false,
      },
    },
    {
      name: "reportSignedUrl",
      label: t("reports.table.header.url"),
      options: {
        customBodyRender: ReformatDownloadLink,
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: t(
        `tools.table.header${
          user.accountType ? `_${user.accountType}` : ""
        }.action`
      ),
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <TableActionMenu
            menuItems={[
              {
                action: () => handleRemove(value),
                icon: <DeleteIcon className={classes.icon} />,
                text: t("reports.table.actions.remove"),
              },
            ]}
          />
        ),
      },
    },
  ];

  return (
    <Fragment>
      <TitleComponent title={t("reports.title")} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent={"space-between"}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                startIcon={<AddIcon />}
                style={{ color: "#FFFFFF", marginBottom: "10px" }}
                onClick={() => history.push("/reports/add")}
              >
                {t("reports.addReport.buttonText")}
              </Button>
            </Grid>
            {loading || removeLoading ? (
              <Loader />
            ) : (
              <Grid item xs={12}>
                <BaseCard>
                  <Typography variant="h2">{t("reports.title")}</Typography>
                  <Fragment>
                    {data?.reports && (
                      <Table
                        storageKey="bsReportsTable"
                        headCells={headCells}
                        data={data.reports}
                        onClick={(row) => history.push(`/reports/${row[0]}`)}
                      />
                    )}
                  </Fragment>
                </BaseCard>
              </Grid>
            )}
          </Grid>
          <Snackbar
            setOpen={setSnackbar}
            open={snackbar}
            text={transactionText}
            severity={severity}
          />

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
