import React, { Fragment, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import BaseCard from "../../UI/Cards/BaseCard";
import moment from "moment";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import {
  ReformatDateFullDayName,
  ReformatDateShortDayName,
} from "../../libs/formattingLib";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const useStyles = makeStyles((theme) => ({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  toolTip: {
    backgroundColor: "#1D3D77",
    borderRadius: "10px",
    paddingBottom: "4px",
    paddingTop: "4px",
    width: "120px",
    height: "60px",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      borderWidth: "10px",
      borderStyle: "solid",
      borderColor: "#1D3D77 transparent transparent transparent",
      top: "60px",
      left: "50px",
    },
  },
  toolTipLabel: {
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#FFF",
  },
  toolTipValue: {
    fontFamily: "Poppins",
    fontSize: "1.125rem",
    fontWeight: 600,
    color: "#FFF",
  },
  toolTipContent: {
    textAlign: "center",
    padding: "0",
    paddingBottom: "0",
    "& .MuiCardContent-root:last-child": {
      padding: "0",
    },
    "&:last-child": {
      padding: "0",
    },
  },
}));

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();
  return (
    <Fragment>
      {active && payload?.length >= 1 && (
        <Card className={classes.toolTip}>
          <CardContent className={classes.toolTipContent}>
            <Typography className={classes.toolTipLabel}>
              {ReformatDateFullDayName(label)}
            </Typography>
            <Typography className={classes.toolTipValue}>
              {payload[0].payload.value}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};

export default function BarChartCard(props) {
  const [activeBarIndex, setActiveBarIndex] = useState();
  const [toolTipYPosition, setToolTipYPosition] = useState();

  useEffect(() => {
    const barCharts = document.querySelectorAll(".recharts-bar-rectangle");

    if (barCharts.length > 0 && typeof activeBarIndex !== "undefined") {
      // Init tooltip values
      try {
        const barChart = barCharts[activeBarIndex];
        setToolTipYPosition(barChart.getBoundingClientRect().height);
      } catch (error) {
        console.log(error);
      }
    };
    return
  }, [activeBarIndex]);

  const lastWeeksDates = () => {
    let dates = []
    for (let i = 0; i < 7; i++) {
      dates.push(moment().subtract(i, "day").format("YYYY-MM-DD"))
    }
    return dates
  }
  const ticks = lastWeeksDates()

  return (
    <BaseCard style={{ width: "100%" }}>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">{props.title}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer width={"100%"} height={300}>
          <BarChart
            barGap={0}
            data={props.data}
            // margin={{
            //   top: 5,
            //   right: 30,
            //   left: 20,
            //   bottom: 5,
            // }}
            onMouseMove={(e) => {
              setActiveBarIndex(e.activeTooltipIndex);
            }}
          >
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
              position={{ y: 170 - toolTipYPosition }}
              offset={-60}
              allowEscapeViewBox={{ x: true, y: true }}
            />
            <XAxis
              interval={0}
              style={{
                fontSize: "1.125rem",
                fontFamily: "Cairo",
                fontWeight: 600,
              }}
              axisLine={false}
              tickLine={false}
              ticks={ticks}
              tickCount={ticks.length}
              dataKey="date"
              tickFormatter={ReformatDateShortDayName}
            />
            <YAxis
              allowDecimals={false}
              style={{
                fontSize: "1.125rem",
                fontFamily: "Cairo",
                fontWeight: 600,
              }}
              dataKey="value"
              axisLine={false}
              tickLine={false}
            />
            <Legend
              style={{
                fontSize: "1.125rem",
                fontFamily: "Cairo",
                fontWeight: 600,
              }}
              iconType="circle"
              iconSize={6}
              align="right"
              verticalAlign="top"
              height={36}
              formatter={(value) => (
                <span
                  style={{
                    color: "#CCC",
                    fontSize: "1.125rem",
                    fontWeight: 600,
                    fontFamily: "Cairo",
                  }}
                >
                  {value}
                </span>
              )}
            />
            <Bar
              barSize={10}
              dataKey="value"
              name={props.legendText}
              fill="#EFC92B"
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </BaseCard>
  );
}
