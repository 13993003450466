import React from "react";

// Material ui
import {
    Grid
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    images: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6)
    }
}));
export default function ImagePane(props) {
    const classes = useStyles();

    return (
        <Grid container item xs={6} className={classes.images} direction="row" alignItems="center">
            <Grid item xs={12}>
                <img alt="Logo" src={process.env.PUBLIC_URL + '/images/logo.png'} width="160" />
            </Grid>
            <Grid item xs={12}>
                <img alt="Illustration" src={process.env.PUBLIC_URL + `/images/${props.image}`} width="500" />
            </Grid>
        </Grid>
    );
}