import React, { Fragment, useState } from "react";

// Hooks
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useUser } from '../../libs/contextLib';
import TableActionMenu from "../../UI/Table/TableActionMenu";
import { ReformatUserType } from "../../libs/formattingLib";

// Components
import CustomDialog from "../ConfirmDeleteDialog";
import Loader from "../../UI/Loader/Loader";
import Table from "../../UI/Table/Table";
import Snackbar from "../Snackbar";
import { TitleComponent } from "../Title";
import BaseCard from "../../UI/Cards/BaseCard";

import { LIST_USERS } from "../../graphql/queries";
import { REMOVE_USER, RESET_PASSWORD_USER } from "../../graphql/mutations";

// Mobile detect
import { isMobile } from "react-device-detect";

// Material ui
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";

// Material ui icons
import AddIcon from "@material-ui/icons/Add";
import Copyright from "../Copyright";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: "relative",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    marginRight: "5px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  menuButton: {
    marginRight: "2px",
    marginLeft: "2px",
  },
}));

export default function Users() {
  const [resetPasswordUser, { loading: loadingResetPassword }] = useMutation(
    RESET_PASSWORD_USER,
    {
      onError: (err) => {
        setTransactionText(t("users.passwordReset.error"));
        setSeverity("error");
        setSnackbar(true);
      },
      onCompleted: () => {
        setTransactionText(t("users.passwordReset.success"));
        setSeverity("success");
        setSnackbar(true);
      },
    }
  );

  const updateCache = (cache, { data }) => {
    try {
      const existingUsers = cache.readQuery({
        query: LIST_USERS,
      });

      /* use cache.identify() here */
      cache.evict({ id: `User:${data.removeUser.id}` });

      const users = existingUsers.users.filter(
        (t) => t.id !== data.removeUser.id
      );

      cache.writeQuery({
        query: LIST_USERS,
        data: { users: [...users] },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const { user } = useUser();


  const [severity, setSeverity] = useState("");
  const [transactionText, setTransactionText] = useState("");
  const [snackbar, setSnackbar] = useState(false);

  const [removeUser, { loading: loadingRemoveUser }] = useMutation(
    REMOVE_USER,
    {
      update: updateCache,
      onCompleted: () => {
        setTransactionText(t("users.removeUser.success", { context: user.accountType }));
        setSeverity("success");
        setSnackbar(true);
      },
      onError: (error) => {
        if (
          error.graphQLErrors.length > 0 &&
          "extensions" in error.graphQLErrors[0] &&
          "code" in error.graphQLErrors[0].extensions
        ) {
          setTransactionText(
            t(`errors.${error.graphQLErrors[0].extensions.code}`)
          );
        } else {
          setTransactionText(t("errors.UNKNOWN_ERROR"));
        }
        setSeverity("error");
        setSnackbar(true);
      },
    }
  );

  const { loading: loadingUsers, data } = useQuery(LIST_USERS, {
    fetchPolicy: "network-only",
    /* Set for loading on refetch to be called */
    notifyOnNetworkStatusChange: true,
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [userToDelete, setUserToDelete] = useState(null);
  const [open, setOpen] = useState(false);

  const handleEdit = (userId) => {
    history.push(`/users/${userId}/edit`);
  };

  const handleConfirmDelete = (userId) => {
    removeUser({ variables: { userId: userId } });
    setOpen(false);
  };

  const handleRemove = (userId) => {
    setUserToDelete(userId);
    setOpen(true);
  };

  const handleResetPassword = (userId) => {
    try {
      resetPasswordUser({ variables: { userId: userId } });
    } catch (err) {
      console.log(err);
    }
  };
  const headCells = [
    { name: "id", options: { display: "excluded", filter: false } },
    {
      name: "email",
      label: t(`users.table.header.email`),
      options: { filterType: "textField" },
    },
    {
      name: "firstName",
      label: t(`users.table.header${user.accountType ? `_${user.accountType}` : ''}.firstName`),
      options: { filterType: "textField" },
    },
    {
      name: "lastName",
      label: t(`users.table.header${user.accountType ? `_${user.accountType}` : ''}.lastName`),
      options: { filterType: "textField" },
    },
    {
      name: "userGroup",
      label: t(`users.table.header.userGroup`),
      options: {
        filterType: "checkbox",
        customBodyRender: (value) => ReformatUserType(t, value, user.accountType),
      },
    },
    {
      name: "activeCheckouts",
      label: t(`users.table.header${user.accountType ? `_${user.accountType}` : ''}.activeCheckouts`),
      options: { filter: false },

    },
    {
      name: "id",
      label: t(`tools.table.header${user.accountType ? `_${user.accountType}` : ''}.action`),
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <TableActionMenu
            menuItems={[
              {
                action: () => handleEdit(value),
                icon: <EditIcon className={classes.icon} />,
                text: t("users.table.actions.edit"),
              },
              {
                action: () => handleRemove(value),
                icon: <DeleteIcon className={classes.icon} />,
                text: t("users.table.actions.remove", { context: user.accountType }),
              },
              {
                action: () => handleResetPassword(value),
                icon: <LockOpenIcon className={classes.icon} />,
                text: t("users.table.actions.resetPassword"),
              },
            ]}
          />
        ),
      },
    },
  ];

  return (
    <Fragment>
      <TitleComponent title={t(`users.title${user.accountType ? `_${user.accountType}` : ''}`)} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12}>
              <Button
                color="primary"
                id="addUserButton"
                variant="contained"
                disableElevation
                startIcon={<AddIcon />}
                style={{ color: "#FFFFFF", marginBottom: "10px" }}
                onClick={() => history.push("/users/add")}
              >
                {t("users.addUser.buttonText", { context: user.accountType })}
              </Button>
            </Grid>
            {loadingUsers || loadingRemoveUser || loadingResetPassword ? (
              <Loader />
            ) : (
              <Grid item xs={12}>
                <BaseCard>
                  <Typography variant="h2">
                    {t(`users.title${user.accountType ? `_${user.accountType}` : ''}`)}
                  </Typography>
                  {data && data.users ? (
                    <Table
                      storageKey="bsUsersTable"
                      headCells={headCells}
                      data={data.users}
                      onClick={(row) => history.push(`/users/${row[0]}`)}
                    />
                  ) : (
                    t("users.noUsers")
                  )}
                </BaseCard>
              </Grid>
            )}
            <CustomDialog
              open={open}
              setOpen={setOpen}
              handleConfirmDelete={handleConfirmDelete}
              userId={userToDelete}
            />
          </Grid>
          <Snackbar
            setOpen={setSnackbar}
            open={snackbar}
            text={transactionText}
            severity={severity}
          />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
