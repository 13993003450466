import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import LoaderButton from '../UI/Buttons/LoaderButton';
import FormPane from '../UI/SignUp/FormPane'
import ImagePane from '../UI/SignUp/ImagePane'
import CustomTextField from '../UI/TextField/CustomTextField'
import BackButton from '../UI/Buttons/BackButton'
// Material ui
import {
    Box,
    CssBaseline,
    Container,
    Grid,
    Snackbar,
    Typography,
    Paper
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';


// Mobile detect
import {
    isMobile
} from "react-device-detect";


import { makeStyles } from "@material-ui/core/styles";
import Copyright from "./Copyright"

import { useForm } from 'react-hook-form';
import { useUser } from '../libs/contextLib';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: "#FFFFFF"
    },
}));

export default function ResetPassword() {
    const { t } = useTranslation();
    const classes = useStyles();

    // Error
    const [loginError, setLoginError] = useState("");

    // Form
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    // Form validation
    const hasError = inputName => !!(errors && errors[inputName])
    const { register, handleSubmit, errors } = useForm();

    // Loading
    const [isLoading, setIsLoading] = useState(false);

    // Push
    const history = useHistory();

    // Snackbar
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("error");


    // Submit
    const { forgotPassword, forgotPasswordConfirm } = useUser();

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onEmailSubmit = async (user) => {

        setIsSendingCode(true);
        setIsLoading(true)
        try {
            await forgotPassword(user.email);
            setLoginError(t("resetPassword.codeSentConfirmation"));
            setSeverity("success");
            setOpen(true);
            setCodeSent(true);
            setIsLoading(false);
        } catch (err) {

            setLoginError(err.message);
            setOpen(true);
            setIsLoading(false);
        }

    }

    const onConfirmationSubmit = async (user) => {
        setIsConfirming(true);
        setIsLoading(true);
        try {
            await forgotPasswordConfirm(user.email, user.confirmationCode, user.password);
            setLoginError(t("resetPassword.codeConfirmation"));
            setSeverity("success");
            setOpen(true);
            setConfirmed(true);
            setIsLoading(false);
        } catch (err) {

            setLoginError(err.message);
            setOpen(true);
            setIsLoading(false);
        }


    }


    function renderRequestCodeForm() {
        return (
            <Container maxWidth="lg">
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Grid container>
                        {!isMobile && <ImagePane image={'person_screen_interaction.png'} />
                        }                        <FormPane >
                            <Typography component="h1" variant="h5">
                                {t('resetPassword.title')}
                            </Typography>
                            <form onSubmit={handleSubmit(onEmailSubmit)} className={classes.form}>
                                <CustomTextField
                                    inputRef={
                                        register({
                                            required: t("resetPassword.email.error.required"),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: t("login.email.error.pattern")
                                            }
                                        })
                                    }
                                    error={hasError("email")}
                                    helperText={hasError("email") && errors["email"].message}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label={t('resetPassword.email.label')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />

                                <LoaderButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                >
                                    {t('resetPassword.submit.buttonText')}
                                </LoaderButton>
                                <BackButton
                                    buttonText={t("resetPassword.backButton.text")}
                                    onClick={() => history.goBack()}
                                />
                            </form>
                        </FormPane>
                    </Grid>
                </Paper>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        )
    }

    function renderConfirmationForm() {
        return (
            <Container maxWidth="lg">
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Grid container>
                        {!isMobile && <ImagePane image={'person_screen_interaction.png'} />
                        }                        <FormPane >
                            <Typography component="h1" variant="h5">
                                {t('resetPassword.title')}
                            </Typography>
                            <form onSubmit={handleSubmit(onConfirmationSubmit)} className={classes.form}>
                                <CustomTextField
                                    inputRef={
                                        register({
                                            required: t("resetPassword.email.error.required"),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: t("resetPassword.email.error.pattern")
                                            }
                                        })
                                    }
                                    error={hasError("email")}
                                    helperText={hasError("email") && errors["email"].message}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label={t('resetPassword.email.label')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />

                                <CustomTextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label={t('resetPassword.password.label')}
                                    type="password"
                                    id="password"
                                    inputRef={
                                        register({
                                            required: t("resetPassword.password.error.required")
                                        })
                                    }
                                    error={hasError("password")}
                                    helperText={hasError("password") && errors["password"].message}

                                />
                                <CustomTextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="confirmationCode"
                                    label={t('resetPassword.confirmationCode.label')}
                                    type="password"
                                    id="confirmationCode"
                                    inputRef={
                                        register({
                                            required: t("resetPassword.confirmationCode.error.required")
                                        })
                                    }
                                    error={hasError("confirmationCode")}
                                    helperText={hasError("confirmationCode") && errors["confirmationCode"].message}

                                />
                                <LoaderButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                >
                                    {t('resetPassword.confirm.buttonText')}
                                </LoaderButton>


                            </form>
                        </FormPane>
                    </Grid>
                </Paper>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        )
    }

    function renderSuccessMessage() {
        return (
            <Container maxWidth="lg">
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Grid container>
                        <ImagePane image={'person_screen_interaction.png'} />
                        <FormPane >
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5">
                                    {t('resetPassword.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {t('resetPassword.codeConfirmation')}
                                </Typography>

                                <LoaderButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    onClick={() => history.push('/login')}
                                >
                                    {t('resetPassword.loginButtonText')}
                                </LoaderButton>

                            </Grid>
                        </FormPane>
                    </Grid>
                </Paper>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        )
    }


    return (
        <Fragment>
            {!codeSent
                ? renderRequestCodeForm()
                : !confirmed
                    ? renderConfirmationForm()
                    : renderSuccessMessage()}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {loginError}
                </Alert>
            </Snackbar>
        </Fragment>

    );
}

