import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Amplify
import { Auth } from 'aws-amplify';

// Components
import ItemView from '../UI/Views/ItemView';
import LoaderButton from '../UI/Buttons/LoaderButton';
import CustomTextField from '../UI/TextField/CustomTextField';

// Hooks
import { useUser } from '../libs/contextLib';


import {
    Divider,
    Grid,
    TextField,
    Typography,
    Snackbar
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    divider: {
        margin: '10px 0px 10px 0px',
        width: '100%',
    }
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangePassword() {
    const { t } = useTranslation();
    const classes = useStyles();

    // Change
    const { changePassword } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState("");

    // Push
    const history = useHistory();

    // Snackbar
    const [open, setOpen] = React.useState(false);

    // Validation
    const { register, handleSubmit, errors, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onSubmit = async (user) => {
        setIsLoading(true);

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await changePassword(currentUser, user.oldPassword, user.password);
            history.push("/settings");

        } catch (err) {

            setLoginError(err.message);
            setOpen(true);
            setIsLoading(false);
        }
    };

    return (
        <ItemView width={'sm'} loading={false} buttonText={t("settings.backButton.text")}>
            <Typography component="h1" variant="h5">
                {t('changePassword.title')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTextField
                            variant="outlined"
                            required
                            fullWidth
                            name="oldPassword"
                            label={t('changePassword.oldPassword.label')}
                            type="password"
                            id="oldPassword"
                            autoComplete="current-password"
                            inputRef={
                                register({
                                    required: t("changePassword.oldPassword.error.required"),
                                })
                            }
                            error={!!errors.oldPassword}
                            helperText={errors.oldPassword && errors["oldPassword"].message}
                        />
                    </Grid>
                    <Divider variant="fullWidth" className={classes.divider} />


                    <Grid item xs={12}>
                        <CustomTextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label={t('changePassword.password.label')}
                            type="password"
                            id="password"
                            inputRef={
                                register({
                                    required: t("changePassword.password.error.required"),
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!@$%^&*-]{8,}$/i,
                                        message: t("changePassword.password.error.pattern")
                                    }
                                })
                            }
                            error={!!errors.password}
                            helperText={errors.password && errors["password"].message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            variant="outlined"
                            required
                            fullWidth
                            name="confirmPassword"
                            label={t('changePassword.confirmPassword.label')}
                            type="password"
                            id="confirmPassword"
                            inputRef={
                                register({
                                    required: t("changePassword.confirmPassword.error.required"),
                                    validate: value =>
                                        value === password.current || t("changePassword.confirmPassword.error.validate")
                                })
                            }
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword && errors["confirmPassword"].message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoaderButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isLoading}
                            isLoading={isLoading}
                        >
                            {t('changePassword.submit.buttonText')}
                        </LoaderButton>
                    </Grid>

                </Grid>
            </form>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {loginError}
                </Alert>
            </Snackbar>

        </ItemView>
    )
}