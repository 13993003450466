import React, { Fragment } from "react";
import { Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";

// Material ui
import { FormControl, Select, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

export default function CustomSelect(props) {
  const classes = useStyles();

  return (
    <Fragment>
      {/* <InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel> */}
      <Controller
        id={props.id}
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue}
        rules={props.rules}
        as={
          <Select className={classes.input} label={props.label} id={props.id}>
            {props.children}
          </Select>
        }
      />
    </Fragment>
  );
}
