import React, { Fragment, useCallback, useState } from "react";
import analytics from "../../analytics";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useUser } from "../../libs/contextLib";
import { TitleComponent } from "../Title";

import { ADD_TOOL } from "../../graphql/mutations";
import { LIST_TOOLS, ADD_TOOL_PAGE } from "../../graphql/queries";
import CustomSelect from "../../UI/Select/CustomSelect";

// Components
import DatepickerDate from "../../UI/Datepicker/DatepickerDate";
import FileDrop from "../../UI/FileDrop/FileDrop";
import FileDropButton from "../../UI/FileDrop/FileDropButton";
import LoaderButton from "../../UI/Buttons/LoaderButton";
import Loader from "../../UI/Loader/Loader";
import BackButton from "../../UI/Buttons/BackButton";
import CustomTextField from "../../UI/TextField/CustomTextField";
import QRDialog from "../QRDialog";
import Snackbar from "../Snackbar";

import Autocomplete from "@material-ui/lab/Autocomplete";

// Hooks
import { s3Upload } from "../../libs/awsLib";

import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

// Material ui icons
import DeleteIcon from "@material-ui/icons/Delete";

import { useMutation, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import Copyright from "../Copyright";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "#F9FAFB",
    boxShadow: "none",
    padding: "0px 0px 0px 0px",
  },
  input: {
    color: "#2A497F",
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
    },
    "& .MuiFilledInput-underline:before": {
      border: "none",
    },
    "& .MuiFilledInput-underline:after": {
      border: "none",
    },
    // "& .MuiTextField-root": {
    //   marginBottom: "0",
    // },
    "& .MuiInputBase-root": {
      color: "#2A497F",
    },
    "& .MuiFormControl-root": {
      marginBottom: "0",
      borderBottom: "none",
    },
  },
  accordionGrid: {
    padding: "0px 0px 16px 0px",
  },
  addButton: {
    marginBottom: "20px",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.white,
  },
  container: {
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fileButton: {
    height: "56px",
  },
  iconMargin: {
    margin: theme.spacing(1),
  },
  loader: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.light,
  },
  select: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  submit: {
    marginTop: "15px",
    color: "#FFFFFF",
  },
  fixedHeight: {
    height: 240,
  },
  textfield: {
    marginBottom: "15px",
  },
  typography: {},
}));

export default function AddTool(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();
  const [snackbar, setSnackbar] = useState(false);
  const [transactionText, setTransactionText] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  // Progress
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  // QR scan
  const [QRCode, setQRCode] = useState("");
  const [open, setOpen] = useState(false);

  const { data, loading: loadingData } = useQuery(ADD_TOOL_PAGE);

  const [addTool] = useMutation(ADD_TOOL, {
    refetchQueries: [{ query: LIST_TOOLS }],
    onCompleted: () => {
      reset();
      handleResetForm();
      setTransactionText(t("addTool.success"));
      setSeverity("success");
      setSnackbar(true);
      setFiles([]);
    },
    onError: (error) => {
      if (
        error.graphQLErrors.length > 0 &&
        "extensions" in error.graphQLErrors[0] &&
        "code" in error.graphQLErrors[0].extensions
      ) {
        setTransactionText(
          t(`errors.${error.graphQLErrors[0].extensions.code}`)
        );
      } else {
        setTransactionText(t("errors.UNKNOWN_ERROR"));
      }
      setSeverity("error");
      setSnackbar(true);
    },
  });

  const { register, handleSubmit, errors, reset, control } = useForm();

  const history = useHistory();

  const hasError = (inputName) => !!(errors && errors[inputName]);

  // Handle the reset of the form after submit
  function handleResetForm() {
    setQRCode("");
    setImage("");
  }

  function getFormattedCode(code) {
    if (code.includes("/codes/")) {
      return code.split("/codes/")[1];
    }

    return code;
  }

  const onSubmit = async (data) => {
    data.code = getFormattedCode(data.code);

    analytics.track("addTool", {
      code: data.code,
      model: data.model,
      manufacturer: data.manufacturer.name,
    });

    setLoading(true);
    setShowProgress(true);

    let imageName = image ? image.name : "";

    // Upload image if image added
    if (image) {
      imageName = await s3Upload(
        image,
        user.attributes["custom:accountId"],
        null
      );
    }

    data.image = imageName;

    if (files.length > 0) {
      // Upload files if added

      let documentList = [];

      for (let file in files) {
        let fileNameTemp = await s3Upload(
          files[file],
          user.attributes["custom:accountId"],
          setProgress
        );
        documentList.push({
          filename: fileNameTemp,
          name: files[file].customName,
          type: files[file].customType,
        });
      }

      data.documents = documentList;
    }

    await addTool({ variables: data });

    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleError = (err) => {
    console.error(err);
  };

  // Upload image
  const [image, setImage] = useState("");
  const handleOnDropImage = useCallback(
    (acceptedFiles) => {
      setImage(acceptedFiles[0]);
      // Do something with the files
    },
    [image]
  );

  // Upload files
  const [fileType, setFileType] = useState("");
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileNameError, setFileNameError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState([]);

  const handleOnDropFiles = useCallback((acceptedFiles, name, type) => {
    let tempFile = [...acceptedFiles];
    tempFile[0]["customName"] = name;
    tempFile[0]["customType"] = type;

    // Add name and type
    setFiles([...files, ...tempFile]);

    // Reset the form
    setFileName("");
    setFileType("");
  });

  // Reset image
  function handleResetImage() {
    setImage("");
  }

  // Delete file
  function handleDeleteFile(index) {
    let tempFiles = [...files];

    // Remove file
    tempFiles.splice(index, 1);

    setFiles([...tempFiles]);
  }

  const handleScan = (data) => {
    if (data) {
      setQRCode(getFormattedCode(data));
      handleClose();
    }
  };

  return (
    <Fragment>
      <TitleComponent title={t("addTool.title")} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BackButton
                buttonText={t("addTool.backButton.text")}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <Box mx={{ xs: 2, sm: 4, md: 10 }} my={{ xs: 1, sm: 2, md: 4 }}>
                  {loadingData ? (
                    <div className={classes.loader}>
                      <Loader />
                    </div>
                  ) : (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={classes.form}
                    >
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            disableElevation
                            className={classes.addButton}
                            onClick={handleClickOpen}
                            style={{ color: "#FFFFFF" }}
                            variant="contained"
                            color="primary"
                          >
                            {t("addTool.QRLabel")}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomTextField
                            inputRef={register({
                              required: t("addTool.code.error.required"),
                            })}
                            error={hasError("code")}
                            helperText={
                              hasError("code") && errors["code"].message
                            }
                            id="code"
                            label={t("addTool.code.label")}
                            name="code"
                            value={QRCode}
                            onChange={(event) =>
                              setQRCode(getFormattedCode(event.target.value))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomTextField
                            inputRef={register({
                              required: t("addTool.model.error.required"),
                            })}
                            error={hasError("model")}
                            helperText={
                              hasError("model") && errors["model"].message
                            }
                            name="model"
                            label={t("addTool.model.label")}
                            id="model"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {data && data.manufacturers && (
                            <Controller
                              render={(props) => (
                                <Autocomplete
                                id="manufacturer"
                                  value={props.value}
                                  /* only include valid input options for manufacturer input */
                                  options={data.manufacturers.map(
                                    (manufacturer) => ({
                                      id: manufacturer.id,
                                      name: manufacturer.name,
                                    })
                                  )}
                                  classes={{
                                    option: classes.option,
                                  }}
                                  onChange={(_, manufacturer) => {
                                    props.onChange(manufacturer);
                                  }}
                                  getOptionSelected={(option, value) =>
                                    value.name === option.name
                                  }
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...params}
                                      error={hasError("manufacturer")}
                                      helperText={
                                        hasError("manufacturer") &&
                                        errors["manufacturer"].message
                                      }
                                      label={t("addTool.manufacturer.label")}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              )}
                              defaultValue={null}
                              name="manufacturer"
                              id="manufacturer"
                              control={control}
                              rules={{
                                required: t(
                                  "addTool.manufacturer.error.required"
                                ),
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <CustomTextField
                            inputRef={register({})}
                            name="serialNumber"
                            label={t("addTool.serialNumber.label")}
                            id="serialNumber"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            render={(props) => (
                              <DatepickerDate
                                data={t("addTool.purchaseDate", {
                                  returnObjects: true,
                                })}
                                variant="filled"
                                error={!!errors.date}
                                fullWidth={true}
                                helperText={errors.date && errors.date.message}
                                id="purchaseDate"
                                name="purchaseDate"
                                onChange={(value) => props.onChange(value)}
                                rules={{ required: false }}
                                value={props.value}
                              />
                            )}
                            name="purchaseDate"
                            id="purchaseDate"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            control={control}
                            render={({ onChange, value, onBlur }) => (
                              <CustomTextField
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                label={t("addTool.purchasePrice.label")}
                              />
                            )}
                            name="purchasePrice"
                            id="purchasePrice"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomTextField
                            inputRef={register({})}
                            multiline
                            rows={4}
                            name="description"
                            label={t("addTool.other.label")}
                            id="description"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {data && data.users && (
                            <Controller
                              render={(props) => (
                                <Autocomplete
                                  value={props.value}
                                  /* only include valid input options for users input */
                                  options={data.users.map((user) => ({
                                    id: user.id,
                                    email: user.email,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                  }))}
                                  onChange={(_, user) => {
                                    props.onChange(user);
                                  }}
                                  getOptionSelected={(option, value) =>
                                    value.id === option.id
                                  }
                                  getOptionLabel={(option) =>
                                    `${option.firstName} ${option.lastName}`
                                  }
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.firstName} {option.lastName}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...params}
                                      // className={classes.input}
                                      error={hasError("assignee")}
                                      helperText={
                                        hasError("assignee") &&
                                        errors["assignee"].message
                                      }
                                      label={t("addTool.assignee.label")}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              )}
                              defaultValue={null}
                              name="assignee"
                              id="assignee"
                              control={control}
                              rules={{ required: false }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {data && data.locations && (
                            <Controller
                              render={(props) => (
                                <Autocomplete
                                  value={props.value}
                                  /* only include valid input options for users input */
                                  options={data.locations.map((location) => ({
                                    id: location.id,
                                    name: location.name,
                                  }))}
                                  classes={{
                                    option: classes.option,
                                  }}
                                  onChange={(_, user) => {
                                    props.onChange(user);
                                  }}
                                  getOptionSelected={(option, value) =>
                                    value.name === option.name
                                  }
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...params}
                                      error={hasError("location")}
                                      helperText={
                                        hasError("location") &&
                                        errors["location"].message
                                      }
                                      label={t("addTool.location.label")}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              )}
                              defaultValue={null}
                              name="location"
                              id="location"
                              control={control}
                              rules={{ required: false }}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Controller
                            control={control}
                            render={({ onChange, value, onBlur }) => (
                              <CustomTextField
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                error={hasError("serviceInterval")}
                                helperText={
                                  hasError("serviceInterval") &&
                                  errors["serviceInterval"].message
                                }
                                label={t("addTool.serviceInterval.label")}
                              />
                            )}
                            id="serviceInterval"
                            name="serviceInterval"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            className={classes.formControl}
                            error={!!errors.serviceIntervalType}
                          >
                            <Typography variant="overline">
                              {t("addTool.serviceIntervalType.label")}
                            </Typography>

                            <CustomSelect
                              name="serviceIntervalType"
                              id="serviceIntervalType"
                              label={t("addTool.serviceIntervalType.label")}
                              control={control}
                              defaultValue={null}
                            >
                              <MenuItem value={"DAY"}>
                                {t("addTool.serviceIntervalType.days")}
                              </MenuItem>
                              <MenuItem value={"WEEK"}>
                                {t("addTool.serviceIntervalType.weeks")}
                              </MenuItem>
                              <MenuItem value={"MONTH"}>
                                {t("addTool.serviceIntervalType.months")}
                              </MenuItem>
                              <MenuItem value={"QUARTER"}>
                                {t("addTool.serviceIntervalType.quarters")}
                              </MenuItem>
                              <MenuItem value={"YEAR"}>
                                {t("addTool.serviceIntervalType.years")}
                              </MenuItem>
                            </CustomSelect>
                            {errors.serviceIntervalType && (
                              <FormHelperText>
                                {t("addTool.serviceIntervalType.error")}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="overline">
                            {t("addTool.serviceDate.label")}
                          </Typography>
                          <Controller
                            render={(props) => (
                              <DatepickerDate
                                data={t("addTool.serviceDate", {
                                  returnObjects: true,
                                })}
                                variant="filled"
                                maxDate={new Date()}
                                error={!!errors.date}
                                fullWidth={true}
                                helperText={errors.date && errors.date.message}
                                id="serviceDate"
                                name="serviceDate"
                                onChange={(value) => props.onChange(value)}
                                rules={{ required: false }}
                                value={props.value}
                              />
                            )}
                            name="serviceDate"
                            id="serviceDate"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="overline">
                            {t("addTool.image.title")}
                          </Typography>
                          {image ? (
                            <Typography>
                              {image.name}
                              <IconButton
                                onClick={handleResetImage}
                                className={classes.iconMargin}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Typography>
                          ) : (
                            <FileDrop
                              multiple={true}
                              onDrop={handleOnDropImage}
                              text={t("addTool.image.description")}
                              subtext={t("addTool.image.description_subtext")}
                              accept="image/jpeg, image/png"
                            />
                          )}
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid container item>
                            <Typography id="files_headline" variant="overline">
                              {t("addTool.files.label")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm>
                          <Typography variant="overline">
                              {t("addTool.documents.documentType.label")}
                            </Typography>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              className={classes.formControl}
                              error={fileTypeError}
                            >
                              {/* <InputLabel htmlFor="outlined-age-native-simple">
                                {t("addTool.documents.documentType.label")}
                              </InputLabel> */}
                              <Select
                              className={classes.select}
                                // native
                                value={fileType}
                                onChange={(event) => {
                                  setFileType(event.target.value);
                                  setFileTypeError(false);
                                }}
                                // label={t(
                                //   "addTool.documents.documentType.label"
                                // )}
                              >
                                
                                <MenuItem value={"manual"}>
                                  {t("addTool.documentTypes.manual")}
                                </MenuItem>
                                <MenuItem value={"warranty"}>
                                  {t("addTool.documentTypes.warranty")}
                                </MenuItem>
                                <MenuItem value={"receipt"}>
                                  {t("addTool.documentTypes.receipt")}
                                </MenuItem>
                              </Select>
                              {fileTypeError && (
                                <FormHelperText>
                                  {t("addTool.documents.documentType.error")}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm>
                            {/* <TextField
                              helperText={
                                fileNameError &&
                                t("addTool.documents.documentName.error")
                              }
                              error={fileNameError}
                              value={fileName}
                              onChange={(event) => {
                                setFileName(event.target.value);
                                setFileNameError(false);
                              }}
                              fullWidth
                              id="outlined-basic"
                              label={t("addTool.documents.documentName.label")}
                              variant="outlined"
                            /> */}

                            <CustomTextField
                            // inputRef={register({
                            //   required: t("addTool.code.error.required"),
                            // })}
                            error={fileNameError}
                            helperText={
                                                              fileNameError &&
                                t("addTool.documents.documentName.error")
                            }
                            id="fileName"
                            label={t("addTool.documents.documentName.label")}
                            name="fileName"
                            value={fileName}
                            onChange={(event) => {
                              setFileName(event.target.value);
                              setFileNameError(false);
                            }}
                          />
                          </Grid>
                          <Grid item xs={12} sm>
                            {/* <Button fullWidth className={classes.fileButton} startIcon={<CloudUploadIcon />} variant="contained" color="primary" disableElevation>
                                                Choose a document
                                            </Button> */}
                            <FileDropButton
                              errorName={() => {
                                setFileNameError(true);
                              }}
                              errorType={() => {
                                setFileTypeError(true);
                              }}
                              customValidator={() => {
                                return fileName
                                  ? null
                                  : {
                                      code: "no-name",
                                      message: "no name provided",
                                    };
                              }}
                              onClick={() => console.log("click")}
                              fileName={fileName}
                              fileType={fileType}
                              multiple={false}
                              onDrop={(data) =>
                                handleOnDropFiles(data, fileName, fileType)
                              }
                              text={t("addTool.documents.buttonText")}
                              subtext={t(
                                "addTool.documents.description_subtext"
                              )}
                              accept="image/jpeg,image/png,application/pdf,image/x-eps"
                            />
                          </Grid>
                        </Grid>
                        {files && (
                          <List>
                            {files.map(
                              (file, index) => (
                                <ListItem key={`file${index}`}>
                                  <ListItemText
                                    primary={`${file.customName} (${t(
                                      `addTool.documentTypes.${file.customType}`
                                    )})`}
                                    secondary={file.path}
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={() => handleDeleteFile(index)}
                                      edge="end"
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )

                              // <Typography>{file.path}</Typography>
                            )}
                          </List>
                        )}
                        <Grid item xs={12}>
                          <LoaderButton
                          id="submit_tool_button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={loading}
                            isLoading={loading}
                          >
                            {loading
                              ? t("addTool.submit.buttonTextLoading")
                              : t("addTool.submit.buttonText")}
                          </LoaderButton>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Snackbar
            setOpen={setSnackbar}
            open={snackbar}
            text={transactionText}
            severity={severity}
          />
          <Box pt={4}>
            <Copyright />
          </Box>
          <QRDialog
            open={open}
            handleScan={handleScan}
            handleClose={handleClose}
            handleError={handleError}
          />
        </Container>
      </main>
    </Fragment>
  );
}
