import React from "react";

// Material ui
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles((theme) => ({
    back: {
        margin: theme.spacing(3, 0, 2),
    },
    leftArrow: {
        transform: "rotate(180deg)",
        color: theme.palette.primary.main
    },
}));

export default function BackButton({
    className = "",
    disabled = false,
    ...props
}) {
    const classes = useStyles();

    return (
        <Button
            className={classes.back}
            onClick={props.onClick}
            startIcon={<ArrowRightAltIcon className={classes.leftArrow} />}
        >
            {props.buttonText}
        </Button>
    );
}

