import { gql } from "@apollo/client";

export const LIST_LOCATIONS = gql`
  query locations {
    locations {
      id
      name
      address
      zipcode
      city
      placeId
      __typename
    }
  }
`;

export const LIST_REPAIRS = gql`
  query repairs {
    repairs {
      id
      description
      __typename
    }
  }
`;

export const LIST_REPORTS = gql`
  query reports {
    reports {
      id
      name
      createdDate
      reportType
      status
      reportSignedUrl
      __typename
    }
  }
`;

export const LIST_MANUFACTURERS = gql`
  query manufacturers {
    manufacturers {
      id
      name
      __typename
    }
  }
`;

export const DASHBOARD_PAGE = gql`
    query dashboard($userId: ID!, $status: String, $periodInDays: Int, $currentPeriodStart: DateTime, $currentPeriodEnd: DateTime $previousPeriodStart: DateTime, $previousPeriodEnd: DateTime) {
        toolCount(periodInDays: $periodInDays) {
            value
            absoluteChange
            relativeChange
            periodValue
        }
        checkoutCount(periodInDays: $periodInDays) {
            value
            absoluteChange
            relativeChange
            periodValue
        }
        userCount(periodInDays: $periodInDays) {
            value
            absoluteChange
            relativeChange
            periodValue
        }
        activities {
            id
            objectReferenceId
            operation
            objectType
            createdDate
            user {
                firstName
                lastName
                email
            }
        }
        userCheckouts: checkouts(userId: $userId, status: $status) {
            id
            tool {
                id
                code
                model
                manufacturer {
                    name
                }
                image
                status
            }
            checkoutDate
            __typename
        }
        currentCheckouts: checkouts(filters: {and: [{checkoutDateGt: $currentPeriodStart}, {checkoutDateLt: $currentPeriodEnd }]} ) {
            id
            checkoutDate
            __typename
        }
        previousCheckouts: checkouts(filters: {and: [{checkoutDateGt: $previousPeriodStart}, {checkoutDateLt: $previousPeriodEnd }]}) {
            id
            checkoutDate
            __typename
        }
        user(userId: $userId) {
            firstName
            lastName
        }
    }
`;

export const EDIT_TOOL_PAGE = gql`
  query editToolPage($toolId: ID!) {
    manufacturers {
      id
      name
      __typename
    }
    locations {
      id
      name
      address
      zipcode
      city
      placeId
      __typename
    }
    users {
      id
      email
      firstName
      lastName
      __typename
    }
    tool(toolId: $toolId) {
      id
      code
      accountId
      model
      manufacturer {
        id
        name
      }
      serialNumber
      description
      image
      imageSignedUrl
      location {
        id
        name
        address
        placeId
      }
      assignee {
        id
        firstName
        lastName
      }
      purchaseDate
      purchasePrice
      status
      checkouts {
        id
        user {
          id
          firstName
          lastName
          phone
          email
        }
        status
        checkoutDate
        returnDate
      }
      documents {
        name
        type
        description
        createdDate
        documentSignedUrl
      }
      serviceDate
      serviceInterval
      serviceIntervalType
      repairs {
        status
      }
      __typename
    }
  }
`;

export const ADD_TOOL_PAGE = gql`
  query addToolPage {
    manufacturers {
      id
      name
    }
    locations {
      id
      name
      address
      zipcode
      city
      placeId
    }
    users {
      id
      email
      firstName
      lastName
    }
  }
`;

export const LIST_TOOLS = gql`
  query tools($search: String) {
    tools(search: $search) {
      assignee {
        firstName
        lastName
      }
      id
      accountId
      code
      model
      manufacturer {
        id
        name
      }
      location {
        name
      }
      currentCheckoutUser {
        firstName
        lastName
      }
      image
      status
      createdDate
    }
  }
`;

export const GET_TOOL = gql`
  query getTool($toolId: ID!) {
    tool(toolId: $toolId) {
      id
      code
      accountId
      model
      manufacturer {
        id
        name
      }
      serialNumber
      description
      image
      imageSignedUrl
      location {
        id
        name
        address
        placeId
      }
      assignee {
        id
        firstName
        lastName
      }
      purchaseDate
      status
      checkouts {
        id
        user {
          id
          firstName
          lastName
          phone
          email
        }
        status
        checkoutDate
        returnDate
      }
      documents {
        name
        type
        description
        createdDate
        documentSignedUrl
      }
      repairs {
        status
      }
      __typename
    }
  }
`;

export const GET_TOOL_CHECKOUT_STATUS_BY_CODE = gql`
  query getToolCheckoutStatusByCode($code: String!) {
    toolByCode(code: $code) {
      status
      __typename
    }
  }
`;

export const EXPORT_DATA = gql`
  query export($exportObjects: String!) {
    export(exportObjects: $exportObjects) {
      exportSignedUrl
      __typename
    }
  }
`;

export const LIST_USERS = gql`
  query users($search: String) {
    users(search: $search) {
      id
      email
      firstName
      lastName
      userGroup
      activeCheckouts
      __typename
    }
  }
`;

export const GET_ACCOUNT = gql`
  query getAccount {
    account {
      accountType
      __typename
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: ID!) {
    user(userId: $userId) {
      id
      email
      firstName
      lastName
      phone
      userGroup
      __typename
    }
  }
`;

export const GET_REPORT = gql`
  query getReport($reportId: ID!) {
    report(reportId: $reportId) {
      id
      name
      createdDate
      reportType
      status
      scheduled
      frequency
      runDate
      __typename
    }
  }
`;

export const GET_USER_WITH_CHECKOUTS = gql`
  query getUser($userId: ID!) {
    user(userId: $userId) {
      id
      email
      firstName
      lastName
      phone
      userGroup
      activeCheckouts
      checkouts {
        id
        tool {
          id
          code
          model
        }
        checkoutDate
        status
      }
      __typename
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query getUser($userId: ID!) {
    user(userId: $userId) {
      email
      firstName
      lastName
      phone
      userGroup
      createdDate
      account {
        name
        address
        city
        zipcode
        paymentType
        subscriptionType
        paperBilling
        createdDate
      }
      __typename
    }
  }
`;

export const LIST_USER_CHECKOUTS = gql`
  query listUserCheckouts($userId: ID!, $status: String) {
    checkouts(userId: $userId, status: $status) {
      id
      tool {
        id
        code
        model
        manufacturer {
          name
        }
        image
        status
      }
      checkoutDate
      __typename
    }
  }
`;

export const GET_PORTAL_BILLING_SESSION = gql`
  query getPortalBillingSession($userId: ID!) {
    user(userId: $userId) {
      id
      billingPortalSession
      __typename
    }
  }
`;

export const LIST_CHECKOUTS = gql`
  query checkouts($search: String) {
    checkouts(search: $search) {
      id
      tool {
        id
        code
        manufacturer {
          name
        }
        model
        status
      }
      user {
        id
        email
        firstName
        lastName
      }
      status
      checkoutDate
      returnDate
      __typename
    }
  }
`;

export const GET_CHECKOUT = gql`
  query getCheckout($checkoutId: ID!) {
    checkout(checkoutId: $checkoutId) {
      id
      tool {
        id
        code
        model
        image
      }
      user {
        id
        email
        firstName
        lastName
        phone
      }
      status
      lat
      long
      marking
      address
      checkoutDate
      returnDate
      __typename
    }
  }
`;

export const GET_DELIVERY_PRICE_ESTIMATE = gql`
  query getPriceEstimate(
    $serviceType: String!
    $toAddress: String
    $toZipcode: String
    $toCity: String
    $toCountryCode: String
    $toPlaceId: String
    $fromAddress: String
    $fromZipcode: String
    $fromCity: String
    $fromCountryCode: String
    $fromPlaceId: String
  ) {
    priceEstimate(
      serviceType: $serviceType
      toAddress: {
        address: $toAddress
        zipcode: $toZipcode
        city: $toCity
        country: $toCountryCode
        placeId: $toPlaceId
      }
      fromAddress: {
        address: $fromAddress
        zipcode: $fromZipcode
        city: $fromCity
        country: $fromCountryCode
        placeId: $fromPlaceId
      }
    ) {
      price
      __typename
    }
  }
`;
