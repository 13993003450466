import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';

import {
  Drawer,
  List,
  IconButton,
  Icon,
  Hidden,
  SwipeableDrawer,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core/';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../libs/contextLib';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Cairo',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    // transition: theme.transitions.create('width', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    // }),
    border: 'none',
    paddingTop: '60px',
    backgroundColor: 'white',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    backgroundColor: 'white',
  },
  menuText: {
    fontFamily: 'Cairo',
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default function DrawerMenu(props) {
  const classes = useStyles();
  const { open, setOpen } = props;

  // Path
  const { pathname } = useLocation();

  // Selected
  const [selectedIndex, setSelectedIndex] = React.useState(pathname);

  const handleDrawerClose = (index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <Fragment>
      <Hidden smUp implementation='js'>
        <SwipeableDrawer
          elevation={0}
          variant='temporary'
          anchor='left'
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <List>
            <MainListItems closeDrawer={handleDrawerClose} />
          </List>
          <List>
            <SecondaryListItems closeDrawer={handleDrawerClose} />
          </List>
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          elevation={0}
          variant='permanent'
          anchor='left'
          classes={{
            paper: clsx(classes.drawerPaper, !true && classes.drawerPaperClose),
          }}
          open={true}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <List>
            <MainListItems
              closeDrawer={handleDrawerClose}
              selectedIndex={selectedIndex}
            />
          </List>
          <List>
            <SecondaryListItems
              closeDrawer={handleDrawerClose}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </List>
        </Drawer>
      </Hidden>
    </Fragment>
  );
}

export const MainListItems = (props) => {
  const { t } = useTranslation();
  const { userGroups, user } = useUser();
  const classes = useStyles();

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <div>
      <ListItem
        role='link'
        id='dashboard_menu'
        button
        onClick={() => props.closeDrawer('/')}
        component={Link}
        selected={props.selectedIndex === '/'}
        to='/'
      >
        <ListItemIcon>
          <Icon
            fontSize='small'
            className='fa fa-columns'
            style={{
              color: '#000000',
              display: 'inline-table',
              transform: 'scale(0.8)',
            }}
          />
        </ListItemIcon>
        <ListItemText
          className={classes.root}
          classes={{ primary: classes.menuText }}
          primary={t('drawer.dashboardLink')}
        />
      </ListItem>

      <ListItem
        role='link'
        id='tools_menu'
        button
        onClick={() => props.closeDrawer('/tools')}
        component={Link}
        selected={props.selectedIndex === '/tools'}
        to='/tools'
      >
        <ListItemIcon>
          <Icon
            fontSize='small'
            className='fa fa-tools'
            style={{
              color: '#000000',
              display: 'inline-table',
              transform: 'scale(0.8)',
            }}
          />
        </ListItemIcon>
        <ListItemText
          className={classes.root}
          classes={{ primary: classes.menuText }}
          primary={t('drawer.toolsLink')}
        />
      </ListItem>

      {userGroups?.includes('admin') && (
        <Fragment>
          <ListItem
            id='users_menu'
            role='link'
            onClick={() => props.closeDrawer('/users')}
            button
            component={Link}
            selected={props.selectedIndex === '/users'}
            to='/users'
          >
            <ListItemIcon>
              <Icon
                fontSize='small'
                className={t('drawer.icons.users', {
                  context: user.accountType,
                })}
                style={{
                  color: '#000000',
                  display: 'inline-table',
                  transform: 'scale(0.8)',
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.root}
              classes={{ primary: classes.menuText }}
              primary={t('drawer.usersLink', { context: user.accountType })}
            />
          </ListItem>

          <ListItem
            id='checkouts_menu'
            role='link'
            onClick={() => props.closeDrawer('/checkouts')}
            button
            component={Link}
            selected={props.selectedIndex === '/checkouts'}
            to='/checkouts'
          >
            <ListItemIcon>
              <Icon
                fontSize='small'
                className={t('drawer.icons.checkouts', {
                  context: user.accountType,
                })}
                style={{
                  color: '#000000',
                  display: 'inline-table',
                  transform: 'scale(0.8)',
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.root}
              classes={{ primary: classes.menuText }}
              primary={t('drawer.checkoutsLink', { context: user.accountType })}
            />
          </ListItem>

          {user.accountType !== 'rental' && (
            <ListItem
              id='reports_menu'
              role='link'
              onClick={() => props.closeDrawer('/reports')}
              button
              component={Link}
              selected={props.selectedIndex === '/reports'}
              to='/reports'
            >
              <ListItemIcon>
                <Icon
                  fontSize='small'
                  className='fa fa-file-export'
                  style={{
                    color: '#000000',
                    display: 'inline-table',
                    transform: 'scale(0.8)',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                className={classes.root}
                classes={{ primary: classes.menuText }}
                primary={t('drawer.reportsLink')}
              />
            </ListItem>
          )}

          {user.accountType !== 'rental' && (
            <ListItem
              id='locations_menu'
              role='link'
              onClick={() => props.closeDrawer('/locations')}
              button
              component={Link}
              selected={props.selectedIndex === '/locations'}
              to='/locations'
            >
              <ListItemIcon>
                <Icon
                  fontSize='small'
                  className='fa fa-map-marker-alt'
                  style={{
                    color: '#000000',
                    display: 'inline-table',
                    transform: 'scale(0.8)',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                className={classes.root}
                classes={{ primary: classes.menuText }}
                primary={t('drawer.locationsLink')}
              />
            </ListItem>
          )}
        </Fragment>
      )}
    </div>
  );
};

export const SecondaryListItems = (props) => {
  const { t } = useTranslation();
  const {
    logout,
    // user, userGroups
  } = useUser();
  const classes = useStyles();

  const history = useHistory();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {
        //TODO: Betalning fliken
        /* {userGroups && userGroups.includes("admin") && (
        <ListItem
          role="link"
          button
          onClick={() => handlePayment()}
          selected={props.selectedIndex === "/billing"}
        >
          <ListItemIcon>
            <Icon
              fontSize="small"
              className="far fa-credit-card"
              style={{
                color: "#000000",
                display: "inline-table",
                transform: "scale(0.8)",
              }}
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.menuText }}
            primary={t("drawer.billingLink")}
          />
        </ListItem>
      )} */
      }
      <ListItem
        id='logout_menu'
        role='link'
        button
        onClick={() => handleLogout()}
      >
        <ListItemIcon>
          <Icon
            fontSize='small'
            className='fa fa-sign-out-alt'
            style={{
              color: '#000000',
              display: 'inline-table',
              transform: 'scale(0.8)',
            }}
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.menuText }}
          primary={t('drawer.logoutLink')}
        />
      </ListItem>
    </div>
  );
};
