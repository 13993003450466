import React, { Fragment, useReducer, useState } from "react";

// Components
import LoaderButton from "../UI/Buttons/LoaderButton";

import {
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Hidden,
  Box,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import FormPane from "../UI/SignUp/FormPane";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useUser } from "../libs/contextLib";
import { useForm } from "react-hook-form";
import ImagePane from "../UI/SignUp/ImagePane";
import CustomTextField from "../UI/TextField/CustomTextField";
import { TitleComponent } from "./Title";

import Copyright from "./Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#FFFFFF",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "24px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { login } = useUser();
  const { register, handleSubmit, errors, setError } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const hasError = (inputName) => !!(errors && errors[inputName]);

  const onSubmit = async (user) => {
    try {
      setIsLoading(true);
      await login(user.email, user.password);
    } catch (err) {
      if (err.code === "PasswordPolicyException") {
        setError("password", {
          type: "manual",
          message: t("login.password.error.pattern"),
        });
      } else if (err.code === "NotAuthorizedException") {
        setLoginError(t("login.error.notAuthorized"));
      } else {
        setLoginError(err.message);
      }
      setOpen(true);
      setIsLoading(false);
    }
  };

  const initialState = {
    email: "",
    password: "",
  };

  useReducer(formReducer, initialState);

  function formReducer(prevState, action) {
    return { ...prevState, [action.type]: action.value };
  }

  return (
    <Fragment>
      <TitleComponent title={t("login.title")} />
      <Container maxWidth="lg">
        <CssBaseline />
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container>
              <Hidden only={["xs"]}>
                <ImagePane image={"person_screen_interaction.png"} />
              </Hidden>
              <FormPane>
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    {t("login.title")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    inputRef={register({
                      required: t("login.email.error.required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("login.email.error.pattern"),
                      },
                    })}
                    error={hasError("email")}
                    helperText={hasError("email") && errors["email"].message}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="email"
                    id="email"
                    label={t("login.email.label")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label={t("login.password.label")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    inputRef={register({
                      required: t("login.passwordFormRequiredError"),
                    })}
                    error={hasError("password")}
                    helperText={
                      hasError("password") && errors["password"].message
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoaderButton
                    id="login"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    {t("login.submit.buttonText")}
                  </LoaderButton>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Link to="/reset-password" variant="body2">
                      {t("login.resetpasswordLink")}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link id="signup" to="/signup" variant="body2">
                      {t("login.signupLink")}
                    </Link>
                  </Grid>
                </Grid>
              </FormPane>
            </Grid>
          </form>
        </Paper>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            <span>{loginError}</span>
          </Alert>
        </Snackbar>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Fragment>
  );
}

export default Login;
