import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import config from './config';

import './i18n';
import theme from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import { UserProvider } from './libs/contextLib';

import { SplitFactory } from '@splitsoftware/splitio-react';

// Splitio config object
const CONFIG = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLITIO_BROWSER_KEY,
    key: 'anonymous',
  },
};

const awsGraphqlFetch = async (uri, options) => {
  return Auth.currentSession()
    .then((session) => {
      options.headers.authorization = session.idToken.jwtToken
        ? `Bearer ${session.idToken.jwtToken}`
        : '';
      return fetch(uri, options);
    })
    .catch((error) => console.log('App session error', error));
};

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`,
  credentials: 'same-origin',
  fetch: awsGraphqlFetch,
});

const client = new ApolloClient({
  //name: 'bs-web-app',
  //version: process.env.npm_package_version,
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tools: {
            merge(existing = [], incoming) {
              return [...incoming];
            },
          },
          users: {
            merge(existing = [], incoming) {
              return [...incoming];
            },
          },
          checkouts: {
            merge(existing = [], incoming) {
              return [...incoming];
            },
          },
        },
      },
    },
  }),
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://66156acbe0d8421794f47d7ac6b6ab53@o526719.ingest.sentry.io/5642140',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    level: 'public',
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'user',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <Suspense fallback={<div></div>}>
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <SplitFactory config={CONFIG}>
        <Router>
          {/*<React.StrictMode>*/}
          <ApolloProvider client={client}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <UserProvider>
                <App />
              </UserProvider>
            </MuiThemeProvider>
          </ApolloProvider>
          {/* </React.StrictMode> */}
        </Router>
      </SplitFactory>
    </Sentry.ErrorBoundary>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
