import React from 'react';

import {
    Snackbar as MaterialSnackbar
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function Snackbar(props) {
    const handleSnackbar = () => {
        props.setOpen(false);
    }

    return (
        <MaterialSnackbar open={props.open} autoHideDuration={4000} onClose={handleSnackbar}>
            <Alert onClose={handleSnackbar} severity={props.severity}>
                {props.text}
            </Alert>
        </MaterialSnackbar>
    )
}