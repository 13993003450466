import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

// Datepicker
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import { Typography } from "@material-ui/core";

// Moment
import "moment-timezone";

const useStyles = makeStyles((theme) => ({
  datepicker: {
    backgroundColor: theme.palette.common.white,
    marginTop: "0",
    borderRadius: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:focus .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFilledInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFilledInput-underline:before": {
      border: "none",
    },
    "& .MuiFilledInput-underline:after": {
      border: "none",
    },
    "& .MuiFilledInput-root": {
      background: theme.palette.common.white,
      borderRadius: "10px",
    },
    "& .MuiAutocomplete-endAdornment": {
      color: "#2A497F",
    },
    "& .MuiFilledInput-adornedEnd:after": {
      color: "#2A497F",
    },
    "& .MuiTextField-root": {
      marginBottom: "0",
    },
    "& .MuiInputBase-root": {
      color: "#2A497F",
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: "0",
    },
  },
  title: {
    display: "block",
    marginTop: "0",
  },
}));

export default function DatepickerDate(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.title} variant="overline">
        {props.data.title}
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          maxDate={props.maxDate}
          inputVariant="filled"
          className={classes.datepicker}
          clearable
          placeholder={props.placeholder}
          margin="normal"
          error={props.error}
          id="date-picker-dialog"
          label={props.data.label}
          helperText={props.helperText}
          format="yyyy-MM-dd"
          value={props.value}
          onChange={props.onChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}
