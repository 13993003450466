import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import BaseCard from '../../UI/Cards/BaseCard';

import {
  ReformatDate,
  ReformatTime,
  ReformatDateTimePassed,
} from '../../libs/formattingLib';
import { Grid, Typography, Avatar, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginTop: '4px',
    marginRight: '0.4rem',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: '1.125rem',
  },
  description: {
    fontFamily: 'Cairo',
    fontSize: '0.75rem',
    fontWeight: 500,
    color: '#A5A5A5',
  },
  mainTitleContainer: {
    marginBottom: '16px',
  },
  activityEntry: {
    paddingTop: '12px',
  },
  activityCursor: {
    cursor: 'pointer',
  },
}));

export function ActivityFeed(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <BaseCard>
        <Grid item className={classes.mainTitleContainer} xs={12}>
          <Typography variant='h2'>{props.t('activities.title')}</Typography>
        </Grid>
        <Grid container item xs={12}>
          {props?.activities?.slice(0, 10).map((activity) => (
            <ActivityFeedEntry
              onClick={() => {
                if (
                  ['checkout', 'tool', 'user'].indexOf(
                    activity.objectType.toLowerCase()
                  ) >= 0
                ) {
                  props.history.push(
                    `${activity.objectType.toLowerCase()}s/${
                      activity.objectReferenceId
                    }`
                  );
                }
              }}
              key={activity.id}
              {...activity}
              t={props.t}
            />
          ))}
        </Grid>
      </BaseCard>
    </Grid>
  );
}

export function ActivityFeedEntry(props) {
  const classes = useStyles();

  return (
    <Fragment key={props.id}>
      <Grid
        className={clsx({
          [classes.activityEntry]: true,
          [classes.activityCursor]:
            ['checkout', 'tool', 'user'].indexOf(
              props.objectType.toLowerCase()
            ) >= 0, //always applies
        })}
        onClick={props.onClick}
        container
        key={props.id}
      >
        <Grid item lg={2}>
          <Avatar className={classes.small}>
            {props.user?.firstName?.slice(0, 1)}
          </Avatar>
        </Grid>
        <Grid item lg={10} style={{ marginBottom: '8px', width: '80%' }}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant='h6'>
                {props.t('activities.templateTitle', {
                  objectType: props.t(
                    `activities.objectType.${props.objectType}`
                  ),
                  operation: props.t(`activities.operation.${props.operation}`),
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.description}>
                {ReformatDateTimePassed(props.t, props.createdDate)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <Typography className={classes.description}>
                {props.t('activities.templateDescription', {
                  objectType: props.t(
                    `activities.objectType.${props.objectType}`
                  ),
                  operation: props.t(`activities.operation.${props.operation}`),
                })}
              </Typography>
              <Typography className={classes.description}>
                {props.user?.firstName || props.user?.email}{' '}
                {props.user?.lastName ?? ''}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.description}></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Fragment>
  );
}
