import React, { Fragment, useEffect, useState } from 'react';
import analytics from '../../analytics'
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useUser } from '../../libs/contextLib';


import { CREATE_REPORT } from '../../graphql/mutations'
import { LIST_REPORTS, LIST_USERS } from '../../graphql/queries'

// Components
import DatepickerDate from '../../UI/Datepicker/DatepickerDate';
import LoaderButton from '../../UI/Buttons/LoaderButton';
import BackButton from '../../UI/Buttons/BackButton'
import CustomTextField from '../../UI/TextField/CustomTextField'
import CustomSelect from '../../UI/Select/CustomSelect'
import Snackbar from '../Snackbar';
import { TitleComponent } from '../Title';
import Copyright from '../Copyright'

import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useHistory } from 'react-router-dom';

import {
    Box,
    Grid,
    Typography,
    Container,
    Paper,
    MenuItem,
    FormControl,
    FormHelperText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';

import { useMutation, useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
    accordion: {
        backgroundColor: '#F9FAFB',
        boxShadow: 'none',
        padding: '0px 0px 0px 0px',
    },
    accordionGrid: {
        padding: '0px 0px 16px 0px',
    },
    addButton: {
        marginBottom: '20px'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.white
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    grid: {
        marginBottom: '10px',
    },
    iconMargin: {
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.light
    },
    submit: {
        marginTop: '15px',
        color: "#FFFFFF"
    },
    fixedHeight: {
        height: 240,
    },
    textfield: {
        marginBottom: '15px'
    },
    typography: {
        display: 'block',
        marginBottom: '5px'
    },
}));

export default function AddReport(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useUser();
    const [expandScheduling, setExpandScheduling] = useState(false);
    const [scheduling, setScheduling] = useState("notScheduled");

    const [snackbar, setSnackbar] = useState(false);
    const [transactionText, setTransactionText] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    // Date
    const [date, setDate] = useState(new Date().toISOString());
    const [dateToday, setDateToday] = useState(new Date().toISOString());


    const { register, handleSubmit, errors, control, watch, trigger, reset } = useForm();

    const history = useHistory();

    const [createReport] = useMutation(CREATE_REPORT, {
        refetchQueries: [{ query: LIST_REPORTS }
        ],
        onCompleted: () => {
            setTransactionText(t("addReport.success"))
            setSeverity("success")
            setSnackbar(true);
            reset()
        },
        onError: (error) => {
            if (error.graphQLErrors.length > 0 && "extensions" in error.graphQLErrors[0] && "code" in error.graphQLErrors[0].extensions) {
                setTransactionText(t(`errors.${error.graphQLErrors[0].extensions.code}`))
            } else {
                setTransactionText(t("errors.UNKNOWN_ERROR"))
            }
            setSeverity("error")
            setSnackbar(true);
        }
    });


    const hasError = inputName => !!(errors && errors[inputName])

    // Handle the date change
    function datepickerChange(date, variable, format) {

        if (date === null) {
            setDate(date)
            return
        }

        setDate(date.toISOString())

    }

    const reportTypes = [
        { id: "TOOLS_SUMMARY", name: t(`addReport.reportType.toolsSummary`), type: "TOOLS_SUMMARY" },
        { id: "USERS_SUMMARY", name: t(`addReport.reportType${
            user.accountType ? `_${user.accountType}` : ""
          }.usersSummary`), type: "USERS_SUMMARY" },
        { id: "CHECKOUTS_SUMMARY", name: t(`addReport.reportType${
            user.accountType ? `_${user.accountType}` : ""
          }.checkoutsSummary`), type: "CHECKOUTS_SUMMARY" }
    ]

    const onSubmit = async (data) => {
        analytics.track('createReport', {
            name: data.name,
            reportType: data.reportType,
            periodInDays: data.periodInDays
        })

        setLoading(true)

        let reportObj = {
            name: data.name,
            destination: data.destination,
            recipients: data.recipients.map(user => user.id),
            reportType: data.reportType.type,
            periodInDays: data.reportPeriod,
            fileType: "PDF",
        }

        if (data.frequency) {
            reportObj['frequency'] = data.frequency
        }
        if (data.start_date) {
            reportObj['start_date'] = data.start_date
        }

        await createReport({
            variables: reportObj
        });

        setLoading(false)

    }

    const { data: users } = useQuery(LIST_USERS);

    useEffect(() => {
        register('recipients')
    }, [users])

    useEffect(() => {
        register('date')
    }, [scheduling])


    return (
        <Fragment>
            <TitleComponent title={t("addReport.title")} />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="md" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <BackButton
                                buttonText={t("addReport.backButton.text")}
                                onClick={() => history.goBack()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>

                                    <Grid container item>
                                        <CustomTextField
                                            inputRef={
                                                register({
                                                    required: t("addReport.name.error"),
                                                })
                                            }
                                            error={hasError("name")}
                                            helperText={hasError("name") && errors["name"].message}
                                            id="code"
                                            label={t('addReport.name.label')}
                                            name="name"
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    </Grid>
                                    <Grid container item className={classes.grid}>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller
                                                render={
                                                    props => (
                                                        <Autocomplete
                                                            value={props.value}
                                                            options={reportTypes}
                                                            classes={{
                                                                option: classes.option,
                                                            }}
                                                            onChange={(_, user) => {
                                                                props.onChange(user)
                                                            }}
                                                            getOptionSelected={(option, value) => value.id === option.id}
                                                            getOptionLabel={(option) => `${option.name}`}
                                                            renderOption={(option) => (
                                                                <React.Fragment>
                                                                    {option.name}
                                                                </React.Fragment>
                                                            )}
                                                            renderInput={(params) => (
                                                                <CustomTextField
                                                                    {...params}
                                                                    error={!!errors.reportType}
                                                                    helperText={errors.reportType && errors["reportType"].message}
                                                                    // error={hasError("reportType")}
                                                                    // helperText={hasError("reportType") && errors["reportType"].message}
                                                                    label={t('addReport.reportType.label')}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                defaultValue={null}
                                                name="reportType"
                                                id="reportType"
                                                control={control}
                                                rules={{ required: t("addReport.reportType.error") }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid container item className={classes.grid}>
                                        <Typography variant="overline">
                                            {t('addReport.destination.label')}
                                        </Typography>
                                        <FormControl fullWidth variant="outlined" className={classes.formControl} error={!!errors.destination}>
                                            <CustomSelect
                                                name="destination"
                                                id="destination"
                                                label={t('addReport.destination.label')}
                                                control={control}
                                                defaultValue={""}
                                                rules={{ required: t("addReport.destination.error") }}
                                            >
                                                {/*<MenuItem value={'SLACK'}>{t("addReport.destination.slack")}</MenuItem>*/}
                                                <MenuItem value={'EMAIL'}>{t("addReport.destination.email")}</MenuItem>
                                            </CustomSelect>
                                            {errors.destination && <FormHelperText>{t("addReport.destination.error")}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    {users &&
                                        <Grid container item className={classes.grid}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    render={
                                                        props => (
                                                            <Autocomplete
                                                                multiple
                                                                value={props.value}
                                                                options={users.users}
                                                                classes={{
                                                                    option: classes.option,
                                                                }}
                                                                onChange={(_, user) => {
                                                                    props.onChange(user)
                                                                }}
                                                                getOptionSelected={(option, value) => value.id === option.id}
                                                                getOptionLabel={(option) => (option.firstName && option.lastName) ? `${option.firstName} ${option.lastName}` : option.email}
                                                                // getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                                                renderOption={(option) => (
                                                                    <React.Fragment>
                                                                        {(option.firstName && option.lastName) ? `${option.firstName} ${option.lastName}` : option.email}
                                                                        {/* {option.firstName} {option.lastName} */}
                                                                    </React.Fragment>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <CustomTextField
                                                                        {...params}
                                                                        error={!!errors.recipients}
                                                                        helperText={errors.recipients && errors["recipients"].message}
                                                                        label={t('addReport.recipients.label')}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    defaultValue={[]}
                                                    name="recipients"
                                                    id="recipients"
                                                    control={control}
                                                    rules={{ required: t("addReport.recipients.error") }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }

                                    <Grid container item className={classes.grid}>
                                        <Typography variant="overline">
                                            {t('addReport.reportPeriod.label')}
                                        </Typography>
                                        <FormControl fullWidth variant="outlined" className={classes.formControl} error={!!errors.reportPeriod}>
                                            <CustomSelect
                                                name="reportPeriod"
                                                id="reportPeriod"
                                                label={t('addReport.reportPeriod.label')}
                                                control={control}
                                                defaultValue={""}
                                                rules={{ required: true }}
                                            >
                                                <MenuItem value={7}>{t("addReport.reportPeriod.7days")}</MenuItem>
                                                <MenuItem value={30}>{t("addReport.reportPeriod.30days")}</MenuItem>
                                                <MenuItem value={90}>{t("addReport.reportPeriod.90days")}</MenuItem>
                                                <MenuItem value={365}>{t("addReport.reportPeriod.365days")}</MenuItem>
                                            </CustomSelect>
                                            {errors.reportPeriod && <FormHelperText>{t("addReport.reportPeriod.error")}</FormHelperText>}
                                        </FormControl>
                                    </Grid>



                                    <Grid container item className={classes.grid}>
                                        <FormControl component="fieldset">
                                            <Typography variant="overline">
                                                {t('addReport.scheduled.label')}
                                            </Typography>
                                            <RadioGroup aria-label="scheduled" name="scheduled" value={scheduling} onChange={(event) => {
                                                setScheduling(event.target.value)
                                                setExpandScheduling(event.target.value === "scheduled")
                                            }}>
                                                <FormControlLabel value="scheduled" control={<Radio />} label={t('addReport.scheduled.options.scheduled')} />
                                                <FormControlLabel value="notScheduled" control={<Radio />} label={t('addReport.scheduled.options.notScheduled')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid className={classes.accordionGrid} item xs={12}>
                                        <Accordion
                                            className={classes.accordion}
                                            expanded={expandScheduling}
                                        >
                                            <AccordionSummary
                                                className={classes.accordion}
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography>{t("addReport.scheduled.accordion.title")}</Typography>
                                            </AccordionSummary >
                                            <AccordionDetails className={classes.accordion}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth variant="outlined" className={classes.formControl} error={!!errors.frequency}>
                                                            <CustomSelect
                                                                label={t('addReport.scheduled.frequency.label')}
                                                                name="frequency"
                                                                id="frequency"
                                                                control={control}
                                                                defaultValue={""}
                                                                rules={{ required: scheduling === "notScheduled" ? false : true }}
                                                            >
                                                                <MenuItem value={'DAILY'}>{t("addReport.scheduled.frequency.1day")}</MenuItem>
                                                                <MenuItem value={'WEEKLY'}>{t("addReport.scheduled.frequency.7days")}</MenuItem>
                                                                <MenuItem value={'MONTHLY'}>{t("addReport.scheduled.frequency.30days")}</MenuItem>
                                                                <MenuItem value={'QUARTERLY'}>{t("addReport.scheduled.frequency.90days")}</MenuItem>
                                                                <MenuItem value={'YEARLY'}>{t("addReport.scheduled.frequency.365days")}</MenuItem>

                                                            </CustomSelect>
                                                            {errors.frequency && <FormHelperText>{t("addReport.scheduled.frequency.error")}</FormHelperText>}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <Typography variant="overline">
                                                            {t("addReport.scheduled.startDate.label")}
                                                        </Typography>
                                                        <Controller
                                                            render={
                                                                props => (
                                                                    <DatepickerDate
                                                                        data={t("addReport.scheduled.startDate", { returnObjects: true })}
                                                                        error={!!errors.date}
                                                                        fullWidth={true}
                                                                        helperText={errors.date && errors.date.message}
                                                                        id="start_date"
                                                                        name="start_date"
                                                                        placeholder={dateToday}
                                                                        onChange={(e) => datepickerChange(e, 'startDate', 'YYYY-MM-DD')}
                                                                        rules={{ required: scheduling === "notScheduled" ? false : true }}
                                                                        value={date}
                                                                    />
                                                                )}
                                                            name="date"
                                                            control={control}
                                                            defaultValue={date}
                                                            rules={{ required: scheduling === "notScheduled" ? false : true }}
                                                            value={date}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12}>

                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>



                                    <LoaderButton
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={loading}
                                        isLoading={loading}
                                    >
                                        {loading ? t('addReport.submit.buttonTextLoading') : t('addReport.submit.buttonText')}
                                    </LoaderButton>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Snackbar setOpen={setSnackbar} open={snackbar} text={transactionText} severity={severity} />
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container >
            </main >
        </Fragment>
    );
}